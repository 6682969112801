import React from "react";
import { FormGroup, FormLabel, Form } from "react-bootstrap";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";
import validate from "../../../validations";

import "./DeliveryForm.scss";

export default function DeliveryForm(props) {
  return (
    <div className="delivery-form-main">
      <div style={{ display: "flex" }}>
        <FormGroup className="login-input-group">
          <FormLabel>Estimated Time*</FormLabel>
          <Input
            type="number"
            name="eTime"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.eTime}
            placeholder="Estimated Time (More than 1 Min)"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "eTime")}
        </FormGroup>

        <span
          style={{
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#000000",
            marginTop: "60px",
            marginLeft: "10px",
          }}
        >
          In Minutes
        </span>
      </div>
      <div>
        <FormGroup className="login-input-group">
          <FormLabel>Minimum Ordre Value*</FormLabel>
          <Input
            type="number"
            name="minOrder"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.minOrder}
            placeholder="Minimum Ordre Value"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "minOrder")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>Free Delivery Order Above*</FormLabel>
          <Input
            type="number"
            name="fDelivery"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.fDelivery}
            placeholder="Free Delivery Order Above"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "fDelivery")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>
            Delivery Charges in Case of Free Deliver to Courier*
          </FormLabel>
          <Input
            type="number"
            name="dCharges"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.dCharges}
            placeholder="Delivery Charges in Case of Free Deliver to Courier"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "dCharges")}
        </FormGroup>
      </div>

      <div
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Map Mode</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Circle"
            name="mapMode"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Circle"
          />
          <Form.Check
            inline
            label="Polygon"
            name="mapMode"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Polygon"
          />
        </div>
        {validate.displayError(props.touched, props.errors, "mapMode")}
      </div>
      <div style={{ display: "flex" }}>
        <FormGroup className="login-input-group">
          <FormLabel>Enter Circle Radius*</FormLabel>
          <Input
            type="number"
            name="radius"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.radius}
            placeholder="Enter Circle Radius"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "radius")}
        </FormGroup>

        <span
          style={{
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#000000",
            marginTop: "60px",
            marginLeft: "10px",
          }}
        >
          In Kms
        </span>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "80%" }}
      >
        <span
          style={{
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "21px",
            color: "var(--customer-theme-color)",
            marginTop: "8px",
            cursor: "pointer",
          }}
        >
          Add More+
        </span>
      </div>
    </div>
  );
}
