import { createAction } from "redux-actions";
import * as Storage from "../services/LocalStorage";

const SearchOptions = "SEARCH_OPTIONS";

// actions
export const SearchOptionsAction = createAction(SearchOptions);

//reducers
export const SearchOptionsReducer = (state, action) => {
  let key = "search_options";
  let data = action.payload;
  if (data) {
    Storage.set(
      key,
      JSON.stringify({
        ...state,
        ...data,
      })
    );
    return {
      ...state,
      ...data,
    };
  } else {
    Storage.set(key, JSON.stringify(state));
    return state;
  }
};

const initialState = {
  category: null,
  location: null,
  distance: 1,
  searchText: "",
  latitude: null,
  longitude: null,
  tags: '[]',
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SearchOptions:
      return SearchOptionsReducer(state, action);

    default:
      return state;
  }
};
export default searchReducer;
