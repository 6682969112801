import * as Yup from "yup";
const validate = {
  displayError: (touched, errors, variable) => {
    return (
      touched[variable] &&
      errors[variable] && <span className="error">{errors[variable]}</span>
    );
  },

  loginSchema: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(5, "Password must be at least of 5 characters")
      .required("Password is required"),
  }),

  //Cuisine schemas
  addCuisineSchema: Yup.object().shape({
    cuisineName: Yup.string()
      .required("Cuisine Name is Required")
      .matches(/^[a-zA-Z]+/, "First Character Must be letter"),

    cuisineImage: Yup.mixed()
      .required("Cuisine Image is Required")
      .test({
        message: "file size must be less than or equal to 1MB",
        test: (file) => {
          return file?.size <= 1024 * 1024 || typeof file === "string";
        },
      }),
  }),

  //bank list schemas
  bankListSchema: Yup.object().shape({
    bankName: Yup.string().required("Bank Name is Required"),
    // .matches(/^[a-zA-Z0-9]*$/, "Special characters not allowed"),
  }),

  //Vendor Schemas
  hostDeliveryProfileForm: Yup.object().shape({
    first_name: Yup.string().required("First Name is Required"),
    last_name: Yup.string().required("Last Name is Required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    profile_picture: Yup.mixed()
      .required("Delivery Image is Required")
      .test({
        message: "file size must be less than or equal to 1MB",
        test: (file) => file?.size <= 1024 * 1024 || typeof file === "string",
      }),
    dob: Yup.mixed().required("Date Of Birth is Required"),
    phone: Yup.string()
      .required("Contact Number is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    password: Yup.string()
      .min(5, "Password must be at least of 5 characters")
      .required("Password is required"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Confirm password must be same"
    ),
  }),

  hostContactForm: Yup.object().shape({
    contactName: Yup.string().required("Name is required"),
    contactEmail: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    alternateEmail: Yup.string().email("Email is invalid"),
    contactPhone: Yup.string()
      .required("Contact Number is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    alternatePhone: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    contactAddress: Yup.string()
      .min(10, "Address must be at least of 10 characters")
      .required("Address is required"),
  }),

  hostDeliveryForm: Yup.object().shape({
    eTime: Yup.number()
      .required("Estimated Time is required")
      .positive("Estimated Time should not be positive"),
    minOrder: Yup.number()
      .required("Minimum Order is required")
      .positive("Minimum order should not be negative"),
    fDelivery: Yup.number()
      .required("free delivery is required")
      .positive("Free delivery should not be negative"),
    dCharges: Yup.number()
      .required("Delivery charges are required for free delivery")
      .positive("Delivery charges should not be negative"),
    radius: Yup.number()
      .required("Radius is required for delivery")
      .positive("Radius should not be negative"),
  }),

  hostOrderForm: Yup.object().shape({
    mNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
  }),

  hostCommissionForm: Yup.object().shape({
    commission: Yup.number()
      .required("Delivery commission is required")
      .positive("Delivery commission should not negative")
      .test({
        message: "commission % must be in between 0 and 100",
        test: (value) => value >= 0 && value <= 100,
      }),
    pickupCom: Yup.number()
      .required("Pickup commission is required")
      .positive("Pickup commission should not negative")
      .test({
        message: "Pickup % must be in between 0 and 100",
        test: (value) => value >= 0 && value <= 100,
      }),
  }),

  hostInvoiceForm: Yup.object().shape({
    selectedPeriod: Yup.string().required("Invoice period is required"),
  }),

  hostTagsForm: Yup.object().shape({
    mTitle: Yup.string().required("Meta Title is required"),
    keywords: Yup.string().required("Meta Keyword is required"),
    description: Yup.string().required("Meta Description is required"),
  }),
  locationSchema: Yup.object().shape({
    locationCord: Yup.mixed().required("Location details is required"),
    state: Yup.string().required("State is Required"),
    city: Yup.string().required("City is Required"),
    pincode: Yup.string()
      .required("Pincode is Required")
      .matches(/^[a-zA-Z0-9]*$/, "Special characters not allowed"),
    country: Yup.string().required("Country is Required"),
  }),

  addressSchema: Yup.object().shape({
    locationCord: Yup.mixed().required("Location details is required"),
    houseNo: Yup.string().required("House Number is Required"),
    phoneNo: Yup.string()
      .matches(/^07\d{9}$/, "Should begin with 07 and Min of 11 digits")
      .required("Required")
      .min(7, "Your mobile no is too short."),
    area: Yup.string().required("Area is Required"),
    addressType: Yup.string().required("Address Type is Required"),
    state: Yup.string().required("State is Required"),
    city: Yup.string().required("City is Required"),
    pincode: Yup.string()
      .required("Pincode is Required")
      .matches(/^[a-zA-Z0-9]*$/, "Special characters not allowed"),
    fullAddress: Yup.string().required("Full address is Required"),
  }),

  hostBankForm: Yup.object().shape({
    bankName: Yup.string().required("Bank Name is required"),
    ownerName: Yup.string().required("Account Owner Name is required"),
    ownerAccount: Yup.string()
      .required("Account Number is required")
      .matches(/^[a-zA-Z0-9]*$/, "Special characters not allowed")
      .min(10, "Account Number Must be atleast 13 digits")
      .max(13, "Account Number Must be 13 digits"),

    ownerCAccount: Yup.string().oneOf(
      [Yup.ref("ownerAccount"), null],
      "Account Number doesn't match"
    ),
    ifscCode: Yup.string()
      .required("IFSC code is required")
      .matches(/^[a-zA-Z0-9]*$/, "Special characters not allowed")
      .min(11, "IFSC code Must be atleast 11 digits")
      .max(11, "IFSC code Must be 11 digits"),
    bankAddress: Yup.string().required("Bank Address is required"),
  }),

  hostCategoryForm: Yup.object().shape({
    categoryName: Yup.string().required("Category Name is required"),
    categoryDescription: Yup.string().required(
      "Category Description is required"
    ),
    categoryImage: Yup.mixed().test({
      message: "file size must be less than or equal to 1MB",
      test: (file) => file?.size <= 1024 * 1024 || typeof file === "string",
    }),
  }),

  hostSubCategoryForm: Yup.object().shape({
    subCategoryName: Yup.string().required("Sub Category Name is required"),
    selectedCategory: Yup.string().required("Category  is required"),
  }),

  hostSignupForm: Yup.object().shape({
    contactName: Yup.string().required("Name is required"),
    contactEmail: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    contactPhone: Yup.string()
      .required("Contact Number is Required")
      .test({
        message: "Must be start with 07 and minimum 11 digits",
        test: (value) => /^07[0-9]{9,}$/.test(value),
      }),
    contactAddress: Yup.string()
      .min(10, "Address must be at least of 10 characters")
      .required("Address is required"),
    password: Yup.string()
      .min(8, "Password must be at least of 8 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "at least one uppercase letter, one lowercase letter, one number and one special character"
      ),
  }),

  menuSchema: Yup.object().shape({
    menuName: Yup.string().required("Menu Name Required"),
    category: Yup.mixed().required("Category is Required"),
    basePrice: Yup.number()
      .positive("No negative numbers are allowed")
      .required("Base Price is required")
      .test({
        message: "Price must be in between 1 and 10000",
        test: (value) => value >= 1 && value <= 10000,
      }),
    offerPrice: Yup.number()
      .required("Offer Price is required")
      .positive("No negative numbers are allowed")
      .lessThan(
        Yup.ref("basePrice"),
        "Offer Price should be less than Base Price"
      )
      .test({
        message: "Price must be in between 1 and 10000",
        test: (value) => value >= 1 && value <= 10000,
      }),
    menuImage: Yup.mixed()
      .required("Menu Image is required")
      .test({
        message: "file size must be less than or equal to 1MB",
        test: (file) => file?.size <= 1024 * 1024 || typeof file === "string",
      }),
    itemType: Yup.string().required("Item Type is required"),
    productVat: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .test({
        message: "Vat % must be in between 0 and 100",
        test: (value) => value >= 0 && value <= 100,
      }),
    calories: Yup.number().positive("Calories should not be negative"),
  }),
  menuUpdatSchema: Yup.object().shape({
    menuName: Yup.string().required("Menu Name Required"),
    category: Yup.mixed().required("Category is Required"),
    basePrice: Yup.string()
      .required("Base Price is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .test({
        message: "Price must be in between 1 and 10000",
        test: (value) => value >= 1 && value <= 10000,
      }),
    menuImage: Yup.mixed()
      .required("Menu Image is required")
      .test({
        message: "file size must be less than or equal to 1MB",
        test: (file) => file?.size <= 1024 * 1024 || typeof file === "string",
      }),
    itemType: Yup.string().required("Item Type is required"),
    productVat: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .test({
        message: "Vat % must be in between 0 and 100",
        test: (value) => value >= 0 && value <= 100,
      }),
    calories: Yup.number().positive("Calories should not be negative"),
  }),
  offerPriceSchema: Yup.object().shape({
    basePrice: Yup.number().positive("Base prize should not be negative"),
    offerPrice: Yup.number()
      .required("Offer Price is required")
      .positive("Offer price should not be negative")
      .lessThan(
        Yup.ref("basePrice"),
        "Offer Price should be less than Base Price"
      ),
  }),
  hostInfoForm: Yup.object().shape({
    rNumber: Yup.string()
      .required("Registration number is required")
      .min(14, "registration Number Must be 14 digits")
      .max(14, "registration Number Must be 14 digits"),
    vatNumber: Yup.string()
      .required("VAT number is required")
      .min(11, "Vat Number Must be 11 digits")
      .max(11, "Vat Number Must be 11 digits"),
    logoImage: Yup.mixed().test({
      message: "file size must be less than or equal to 1MB",
      test: (file) => file?.size <= 1024 * 1024 || typeof file === "string",
    }),
    headerImage: Yup.mixed().test({
      message: "file size must be less than or equal to 1MB",
      test: (file) => {
        return file?.size <= 1024 * 1024 || typeof file === "string";
      },
    }),
  }),

  //Cutsomer schemas
  customerSignup: Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name must be atleast 3 Characters"),
    email: Yup.string()
      .required("Email  is required")
      .email("Enter valid Email Address"),
    phone: Yup.string()
      .required("Contact Number is Required")
      .test({
        message: "Must be start with 07 and minimum 11 digits",
        test: (value) => /^07[0-9]{9,}$/.test(value),
      }),

    password: Yup.string()
      .required("Password is required")
      .min(5, "Password must be atleast of 5 characters"),
  }),
  statusUpdateSchema: Yup.object().shape({
    selectedStatus: Yup.mixed().required("Select Status, it is required"),
  }),
  statusUpdatebyRejection: Yup.object().shape({
    selectedStatus: Yup.mixed().required("Select Status, it is required"),
    rejectionReason: Yup.string().required("Reason for regection is required"),
  }),
  forgetPasswordSchema: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  }),
  forgetPasswordVerifySchema: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(5, "Password must be at least of 5 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    verificationOtp: Yup.string()
      .required("OTP is Required")
      .min(6, "Must be exactly 10 digits")
      .max(6, "Must be exactly 10 digits"),
  }),

  partyOrderSchema: Yup.object().shape({
    menus: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Item name is Required"),
        quantity: Yup.number()
          .required("Quantity is Required")
          .positive("quantity must be > 0")
          .test({
            message: "max quantity is 10",
            test: (num) => num > 0 && num <= 10,
          }),
      })
    ),
    orderDate: Yup.mixed().required("Date and Time is Required"),
    selectedAddress: Yup.mixed().required("Address is required"),
    name: Yup.string().required("Name Of Person is required"),
    email: Yup.string().email("Please Enter Valid Email"),
    mobileNumber: Yup.string()
      .matches(/^07\d{9}$/, "Should begin with 07 and Min of 11 digits")
      .required("Required")
      .min(7, "Your mobile no is too short."),
  }),

  partyPersonSchema: Yup.object().shape({
    menus: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Item name is Required"),
      })
    ),
    numOfPersons: Yup.number()
      .required("Number of Persons Required")
      .positive("Number of person must be > 0")
      .test({
        message: "Add persons",
        test: (num) => num > 0,
      }),
    orderDate: Yup.mixed().required("Date and Time is Required"),
    selectedAddress: Yup.mixed().required("Address is required"),
    name: Yup.string().required("Name Of Person is required"),
    email: Yup.string().email("Please Enter Valid Email"),
    mobileNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    delivery_instructions: Yup.string(),
  }),
  assignDeliverySchema: Yup.object().shape({
    selectedDeliveryAgent: Yup.mixed().required("*Delivery Agent is required"),
  }),
  customerProfileForm: Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    profileImage: Yup.mixed().test({
      message: "file size must be less than or equal to 1MB",
      test: (file) => {
        return file?.size <= 1024 * 1024 || typeof file === "string";
      },
    }),
  }),
  //quotation
  quotationSchema: Yup.object().shape({
    description: Yup.string().required("Description is Required"),
    pricings: Yup.array().of(
      Yup.object().shape({
        priceType: Yup.string().required("Price type is Required"),
        amount: Yup.number()
          .required("Amount is Required")
          .positive("Amount should not be positive")
          .test({
            message: "Amount must be greater than 0",
            test: (num) => +num > 0,
          }),
      })
    ),
  }),
  settleStatusSchema: Yup.object().shape({
    receipt: Yup.mixed().required("Settled Receipt is Required"),
    settled_date: Yup.mixed().required("Settled Date is Required"),
  }),
};

export default validate;
