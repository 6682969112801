import React from 'react'

import './Tags.scss'

import validate from "../../../validations";
import { FormGroup, FormLabel } from "react-bootstrap";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";

export default function Tags(props){
    return <div className='tags-main'>
        <div>
        <FormGroup className="login-input-group">
        <FormLabel>Meta Title*</FormLabel>
        <Input
          type="text"
          name="mTitle"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.mTitle}
          placeholder="Enter Meta Title"
          className="customer-name-input"
        />
        {validate.displayError(props.touched, props.errors, "mTitle")}
      </FormGroup>
      <FormGroup className="login-input-group">
        <FormLabel>Meta Keyword*</FormLabel>
        <Input
          as="textarea"
          name="keywords"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.keywords}
          placeholder="Enter Meta Keyword"
          className="customer-name-input"
          style={{height:'150px'}}
        />
        {validate.displayError(props.touched, props.errors, "keywords")}
      </FormGroup>
      <FormGroup className="login-input-group">
        <FormLabel>Meta Description*</FormLabel>
        <Input
          as="textarea"
          name="description"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.description}
          placeholder="Enter Meta Description"
          className="customer-name-input"
          style={{height:'150px'}}
        />
        {validate.displayError(props.touched, props.errors, "description")}
      </FormGroup>
        </div>
    </div>
}