import React from 'react'


export default function OutlinedButton(props){

    const buttonStyle = {
        borderColor: "rgba(67, 41, 163, 0.2)",
        background: "#fff",
        color: "#FF8D00",
      };

    return(
        <button
        {...props}
        className="btn-first full-width  fw-600"
        style={{...buttonStyle,...props.style}}
      >
       {props.title}
      </button>
    )
}


