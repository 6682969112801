import React from 'react'
import Loader from 'react-loaders'
import 'loaders.css/src/animations/pacman.scss'
import './PageLoader.scss'

export default function PageLoader(props){
    return (
        <Loader
          type='pacman'
          active
          {...props}
          className={`page-loader ${props?.className || ""}`}
        />
      );
}