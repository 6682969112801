import React from 'react';
import { useSelector } from 'react-redux';
import ApplicationsCustomerRoutes from '../components/applicationRoutes/CustomerRoutes';

export default function CustomerRoutes(props) {
  const {config} = useSelector((state) => state.config);
  return config.maintenance ? (
    <div className='maintenance'>
      <h1>Sorry for the inconvenience. We’ll be back soon!</h1>
    </div>
  ) : (
    <ApplicationsCustomerRoutes {...props} />
  );
}