import React from "react";
import "./HostForm.scss";

import validate from "../../../validations";
import { FormGroup, FormLabel, Form } from "react-bootstrap";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";
import UploadInput from "../../../assets/elements/formElements/uploadInput/UploadInput";
import CustomSelect from "../../../assets/elements/select/CustomSelect";
import TimeSilder from "../../../assets/elements/formElements/timeSlider/TimeSlider";
import ReactSelect from "react-select";

export default function HostInfo(props) {
  const options = [
    { label: "15 Minutes", value: 15 },
    { label: "30 Minutes", value: 30 },
    { label: "1 Hours", value: 60 },
    { label: "2 Hours", value: 120 },
  ];
  const showOptions = [
    { label: "1% Tax", value: 1 },
    { label: "2% Tax", value: 2 },
    { label: "3% Tax", value: 3 },
    { label: "4% Tax", value: 4 },
  ];
  return (
    <div className="host-info-main">
      <div style={{ display: "flex" }}>
        <FormGroup className="login-input-group">
          <FormLabel>Host Name*</FormLabel>
          <Input
            type="text"
            name="hostName"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.hostName}
            placeholder="Enter Host Name"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "hostName")}
        </FormGroup>
        <FormGroup className="upload-input-group">
          <FormLabel className="password-label">
            <span>Logo*</span>{" "}
          </FormLabel>
          <UploadInput
            name="logoImage"
            onBlur={props.handleBlur}
            imagePreview={true}
            accept="image/jpg,image/jpeg,image/png,image/svg"
            className={`upload-logo-input ${
              typeof props.values.logoImage === "string" &&
              props.selectedLogo &&
              "modify-logo-input"
            }`}
            onChange={(e) => {
              props.setFieldValue("logoImage", e);
              props.handleLogoChange(e, "logoImage");
            }}
          />
          <span
            style={{
              fontSize: 10,
              color: "#ff0000",
              margin: "20px 0px 0px",
              display: "block",
            }}
          >
            * only jpg, jpeg, svg, png formatted images supported
          </span>
          {validate.displayError(props.touched, props.errors, "logoImage")}
        </FormGroup>

        {typeof props.values.logoImage === "string" && props.selectedLogo && (
          <div
            style={{
              width: 100,
              height: 100,
              marginTop: "20px",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={props.fileBasicPath + props.selectedLogo}
              alt=""
            />
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormGroup className="login-input-group">
          <FormLabel className="password-label">
            <span>Contact Number*</span>{" "}
          </FormLabel>
          <Input
            type="tel"
            name="hostPhone"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.hostPhone}
            placeholder="Enter Mobile Number"
            className="login-input"
          />
          {validate.displayError(props.touched, props.errors, "hostPhone")}
        </FormGroup>

        <FormGroup className="upload-input-group">
          <FormLabel className="password-label">
            <span>Header Image*</span>{" "}
          </FormLabel>
          <UploadInput
            name="headerImage"
            onBlur={props.handleBlur}
            imagePreview={true}
            accept="image/jpg,image/jpeg,image/png,image/svg"
            className={`upload-header-input ${
              typeof props.values.headerImage === "string" &&
              props.selectedHeader &&
              "modify-header-input"
            }`}
            onChange={(e) => {
              props.setFieldValue("headerImage", e);
              props.handleHeaderImageChange(e, "headerImage");
            }}
          />
          <span
            style={{
              fontSize: 10,
              color: "#ff0000",
              margin: "20px 0px 0px",
              display: "block",
            }}
          >
            * only jpg, jpeg, svg, png formatted images supported
          </span>
          {validate.displayError(props.touched, props.errors, "headerImage")}
        </FormGroup>
        {typeof props.values.headerImage === "string" && props.selectedHeader && (
          <div
            style={{
              width: 300,
              height: 100,
              marginTop: "20px",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={props.fileBasicPath + props.selectedHeader}
              alt=""
            />
          </div>
        )}
      </div>
      <FormGroup className="login-input-group">
        <FormLabel className="password-label">
          <span>Cuisines</span>{" "}
        </FormLabel>
        <ReactSelect
          name="selectedCuisines"
          onChange={(selectedCuisines) => {
            props.setFieldValue("selectedCuisines", selectedCuisines);
          }}
          onBlur={props.handleBlur}
          value={props.values.selectedCuisines}
          placeholder="Select Cuisines"
          className="login-input"
          options={props.options}
          isMulti
        />
        {validate.displayError(props.touched, props.errors, "selectedCuisines")}
      </FormGroup>
      <div className="opening-and-closing">
        <FormLabel>Opening & Closing Time*</FormLabel>
        <div className="timing-display-block">
          <div>
            <div>
              <FormLabel style={{ fontWeight: 600 }}>Monday</FormLabel>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "mon", 0)}
                  value={props.showTime?.mon0 || props.defaultTime}
                  maxValue={
                    props.showTime.mon1 && props.showTime.mon1.start
                      ? props.showTime.mon1.start
                      : "23:59"
                  }
                  disabled={props.showTime.monHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Breakfast</span>
                </div>
              </div>

              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "mon", 1)}
                  value={props.showTime?.mon1 || props.defaultTime}
                  minValue={
                    props.showTime.mon0 && props.showTime.mon0.end
                      ? props.showTime.mon0.end
                      : "00:00"
                  }
                  maxValue={
                    props.showTime.mon2 && props.showTime.mon2.start
                      ? props.showTime.mon2.start
                      : "23:59"
                  }
                  disabled={props.showTime.monHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "mon", 2)}
                  value={props.showTime?.mon2 || props.defaultTime}
                  minValue={
                    props.showTime.mon1 && props.showTime.mon1.end
                      ? props.showTime.mon1.end
                      : "00:00"
                  }
                  maxValue="23:59"
                  disabled={props.showTime.monHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Dinner</span>
                </div>
              </div>
              <Form.Check
                inline
                label="Holiday"
                name="closeDay"
                type="checkbox"
                className="form-checkbox"
                style={{ margin: "0px 0px 10px 20px" }}
                onChange={(e) => props.handleHolidayChange(e, "mon")}
                checked={props.showTime.monHol}
              />
            </div>
          </div>
          <div>
            <div>
              <FormLabel style={{ fontWeight: 600 }}>Tuesday</FormLabel>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "tue", 0)}
                  maxValue={
                    props.showTime.tue1 && props.showTime.tue1.start
                      ? props.showTime.tue1.start
                      : "23:59"
                  }
                  value={props.showTime?.tue0 || props.defaultTime}
                  disabled={props.showTime.tueHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Breakfast</span>
                </div>
              </div>

              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "tue", 1)}
                  value={props.showTime?.tue1 || props.defaultTime}
                  minValue={
                    props.showTime.tue0 && props.showTime.tue0.end
                      ? props.showTime.tue0.end
                      : "00:00"
                  }
                  maxValue={
                    props.showTime.tue2 && props.showTime.tue2.start
                      ? props.showTime.tue2.start
                      : "23:59"
                  }
                  disabled={props.showTime.tueHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "tue", 2)}
                  value={props.showTime?.tue2 || props.defaultTime}
                  minValue={
                    props.showTime.tue1 && props.showTime.tue1.end
                      ? props.showTime.tue1.end
                      : "00:00"
                  }
                  disabled={props.showTime.tueHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Dinner</span>
                </div>
              </div>
              <Form.Check
                inline
                label="Holiday"
                name="closeDay"
                type="checkbox"
                className="form-checkbox"
                style={{ margin: "0px 0px 10px 20px" }}
                onChange={(e) => props.handleHolidayChange(e, "tue")}
                checked={props.showTime.tueHol}
              />
            </div>
          </div>
          <div>
            <div>
              <FormLabel style={{ fontWeight: 600 }}>Wednesday</FormLabel>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "wed", 0)}
                  maxValue={
                    props.showTime.wed1 && props.showTime.wed1.start
                      ? props.showTime.wed1.start
                      : "23:59"
                  }
                  value={props.showTime?.wed0 || props.defaultTime}
                  disabled={props.showTime.wedHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Breakfast</span>
                </div>
              </div>

              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "wed", 1)}
                  minValue={
                    props.showTime.wed0 && props.showTime.wed0.end
                      ? props.showTime.wed0.end
                      : "00:00"
                  }
                  maxValue={
                    props.showTime.wed2 && props.showTime.wed2.start
                      ? props.showTime.wed2.start
                      : "23:59"
                  }
                  value={props.showTime?.wed1 || props.defaultTime}
                  disabled={props.showTime.wedHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "wed", 2)}
                  minValue={
                    props.showTime.wed1 && props.showTime.wed1.end
                      ? props.showTime.wed1.end
                      : "00:00"
                  }
                  value={props.showTime?.wed2 || props.defaultTime}
                  disabled={props.showTime.wedHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Dinner</span>
                </div>
              </div>
              <Form.Check
                inline
                label="Holiday"
                name="closeDay"
                type="checkbox"
                className="form-checkbox"
                style={{ margin: "0px 0px 10px 20px" }}
                onChange={(e) => props.handleHolidayChange(e, "wed")}
                checked={props.showTime.wedHol}
              />
            </div>
          </div>
          <div>
            <div>
              <FormLabel style={{ fontWeight: 600 }}>Thursday</FormLabel>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "thu", 0)}
                  maxValue={
                    props.showTime.thu1 && props.showTime.thu1.start
                      ? props.showTime.thu1.start
                      : "23:59"
                  }
                  value={props.showTime?.thu0 || props.defaultTime}
                  disabled={props.showTime.thuHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Breakfast</span>
                </div>
              </div>

              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "thu", 1)}
                  minValue={
                    props.showTime.thu0 && props.showTime.thu0.end
                      ? props.showTime.thu0.end
                      : "00:00"
                  }
                  maxValue={
                    props.showTime.thu2 && props.showTime.thu2.start
                      ? props.showTime.thu2.start
                      : "23:59"
                  }
                  value={props.showTime?.thu1 || props.defaultTime}
                  disabled={props.showTime.thuHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "thu", 2)}
                  minValue={
                    props.showTime.thu1 && props.showTime.thu1.end
                      ? props.showTime.thu1.end
                      : "00:00"
                  }
                  value={props.showTime?.thu2 || props.defaultTime}
                  disabled={props.showTime.thuHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Dinner</span>
                </div>
              </div>
              <Form.Check
                inline
                label="Holiday"
                name="closeDay"
                type="checkbox"
                className="form-checkbox"
                style={{ margin: "0px 0px 10px 20px" }}
                onChange={(e) => props.handleHolidayChange(e, "thu")}
                checked={props.showTime.thuHol}
              />
            </div>
          </div>
          <div>
            <div>
              <FormLabel style={{ fontWeight: 600 }}>Friday</FormLabel>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "fri", 0)}
                  maxValue={
                    props.showTime.fri1 && props.showTime.fri1.start
                      ? props.showTime.fri1.start
                      : "23:59"
                  }
                  value={props.showTime?.fri0 || props.defaultTime}
                  disabled={props.showTime.friHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Breakfast</span>
                </div>
              </div>

              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "fri", 1)}
                  minValue={
                    props.showTime.fri0 && props.showTime.fri0.end
                      ? props.showTime.fri0.end
                      : "00:00"
                  }
                  maxValue={
                    props.showTime.fri2 && props.showTime.fri2.start
                      ? props.showTime.fri2.start
                      : "23:59"
                  }
                  value={props.showTime?.fri1 || props.defaultTime}
                  disabled={props.showTime.friHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "fri", 2)}
                  minValue={
                    props.showTime.fri1 && props.showTime.fri1.end
                      ? props.showTime.fri1.end
                      : "00:00"
                  }
                  value={props.showTime?.fri2 || props.defaultTime}
                  disabled={props.showTime.friHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Dinner</span>
                </div>
              </div>
              <Form.Check
                inline
                label="Holiday"
                name="closeDay"
                type="checkbox"
                className="form-checkbox"
                style={{ margin: "0px 0px 10px 20px" }}
                onChange={(e) => props.handleHolidayChange(e, "fri")}
                checked={props.showTime.friHol}
              />
            </div>
          </div>
          <div>
            <div>
              <FormLabel style={{ fontWeight: 600 }}>Saturday</FormLabel>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "sat", 0)}
                  maxValue={
                    props.showTime.sat1 && props.showTime.sat1.start
                      ? props.showTime.sat1.start
                      : "23:59"
                  }
                  value={props.showTime?.sat0 || props.defaultTime}
                  disabled={props.showTime.satHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Breakfast</span>
                </div>
              </div>

              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "sat", 1)}
                  minValue={
                    props.showTime.sat0 && props.showTime.sat0.end
                      ? props.showTime.sat0.end
                      : "00:00"
                  }
                  maxValue={
                    props.showTime.sat2 && props.showTime.sat2.start
                      ? props.showTime.sat2.start
                      : "23:59"
                  }
                  value={props.showTime?.sat1 || props.defaultTime}
                  disabled={props.showTime.satHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "sat", 2)}
                  minValue={
                    props.showTime.sat1 && props.showTime.sat1.end
                      ? props.showTime.sat1.end
                      : "00:00"
                  }
                  value={props.showTime?.sat2 || props.defaultTime}
                  disabled={props.showTime.satHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <Form.Check
                inline
                label="Holiday"
                name="closeDay"
                type="checkbox"
                className="form-checkbox"
                style={{ margin: "0px 0px 10px 20px" }}
                onChange={(e) => props.handleHolidayChange(e, "sat")}
                checked={props.showTime.satHol}
              />
            </div>
          </div>
          <div>
            <div>
              <FormLabel style={{ fontWeight: 600 }}>Sunday</FormLabel>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "sun", 0)}
                  maxValue={
                    props.showTime.sun1 && props.showTime.sun1.start
                      ? props.showTime.sun1.start
                      : "23:59"
                  }
                  value={props.showTime?.sun0 || props.defaultTime}
                  disabled={props.showTime.sunHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Breakfast</span>
                </div>
              </div>

              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "sun", 1)}
                  minValue={
                    props.showTime.sun0 && props.showTime.sun0.end
                      ? props.showTime.sun0.end
                      : "00:00"
                  }
                  maxValue={
                    props.showTime.sun2 && props.showTime.sun2.start
                      ? props.showTime.sun2.start
                      : "23:59"
                  }
                  value={props.showTime?.sun1 || props.defaultTime}
                  disabled={props.showTime.sunHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Lunch</span>
                </div>
              </div>
              <div>
                <TimeSilder
                  onChange={(time) => props.onTimingChange(time, "sun", 2)}
                  minValue={
                    props.showTime.sun1 && props.showTime.sun1.end
                      ? props.showTime.sun1.end
                      : "00:00"
                  }
                  value={props.showTime?.sun2 || props.defaultTime}
                  disabled={props.showTime.sunHol}
                  className="each-time-slider"
                />
                <div className="timeslider-indication">
                  <span className="time-value-text">Dinner</span>
                </div>
              </div>
              <Form.Check
                inline
                label="Holiday"
                name="closeDay"
                type="checkbox"
                className="form-checkbox"
                style={{ margin: "0px 0px 10px 20px" }}
                onChange={(e) => props.handleHolidayChange(e, "sun")}
                checked={props.showTime.sunHol}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <FormGroup className="login-input-group">
          <FormLabel>Auto Order Rejection Time*</FormLabel>
          <CustomSelect
            name="rejectionTime"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.rejectionTime}
            className="customer-name-input"
            options={options}
          />
          {validate.displayError(props.touched, props.errors, "rejectionTime")}
        </FormGroup>

        {/* <span
          style={{
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#000000",
            marginTop: "60px",
            marginLeft: "10px",
          }}
        >
          In Minutes
        </span> */}
      </div>
      <div
        style={{
          display: "flex",
          width: "30%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Verified Host?</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="verify"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="verify"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "30%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Is Popular?</p>
        <div className="radio-btn-group">
          <Form.Check inline label="Yes" name="popular" type="radio" />
          <Form.Check inline label="No" name="popular" type="radio" />
        </div>
      </div>
      <FormGroup className="login-input-group">
        <FormLabel>Show Tax*</FormLabel>
        <CustomSelect
          name="showTax"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.showTax}
          className="customer-name-input"
          options={showOptions}
        />
        {validate.displayError(props.touched, props.errors, "showTax")}
      </FormGroup>
      <div
        style={{
          display: "flex",
          width: "41%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Host Type</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Products"
            name="hType"
            type="checkbox"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Products"
          />
          <Form.Check
            inline
            label="Services"
            name="hType"
            type="checkbox"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Services"
          />
          <Form.Check
            inline
            label="Supplier"
            name="hType"
            type="checkbox"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Supplier"
          />
        </div>
      </div>
      <div>
        <FormGroup className="login-input-group">
          <FormLabel>Registration Number*</FormLabel>
          <Input
            type="text"
            name="rNumber"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.rNumber}
            placeholder="Enter Registration"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "rNumber")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>Vat Number*</FormLabel>
          <Input
            type="text"
            name="vatNumber"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.vatNumber}
            placeholder="Enter Vat Number"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "vatNumber")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>Labels*</FormLabel>
          <CustomSelect
            name="labels"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.lables}
            placeholder="Select Labels"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "labels")}
        </FormGroup>
      </div>
      <div
        style={{
          display: "flex",
          width: "41%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Visibility</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Web"
            name="visibility"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Web"
          />
          <Form.Check
            inline
            label="Mobile"
            name="visibility"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Mobile"
          />
          <Form.Check
            inline
            label="Both"
            name="visibility"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Both"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "34%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Do you wanna dispatch</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="dispatch"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="dispatch"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "34%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Pickup*</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="deliverPickup"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="deliveryPickup"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "34%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Delivery*</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="hostDelivery"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="hostDelivery"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "34%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Eat In*</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="eatin"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="eatin"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "34%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Drive In*</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="drivein"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="drivein"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "34%",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Book a table </p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="bookTable"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="bookTable"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
      </div>
      <div>
        <FormGroup className="login-input-group">
          <FormLabel>About Restaurant</FormLabel>
          <Input
            as="textarea"
            name="aboutStore"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.aboutStore}
            placeholder="About Store"
            className="customer-name-input"
            style={{ height: "100px" }}
          />
          {validate.displayError(props.touched, props.errors, "aboutStore")}
        </FormGroup>
      </div>
    </div>
  );
}
