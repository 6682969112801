import React from "react";
import "./BankInfo.scss";
import validate from "../../../validations";
import { FormGroup, FormLabel } from "react-bootstrap";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";

export default function BankForm(props) {
  return (
    <div className="bank-form-main">
      <div>
        <FormGroup className="login-input-group">
          <FormLabel>Bank Name*</FormLabel>
          <Input
            type="text"
            name="bankName"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.bankName}
            placeholder="Enter Bank Name"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "bankName")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>Account Owner Name*</FormLabel>
          <Input
            type="text"
            name="ownerName"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.ownerName}
            placeholder="Enter Name"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "ownerName")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>Account Number*</FormLabel>
          <Input
            type="text"
            name="ownerAccount"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.ownerAccount}
            placeholder="Enter Account Number"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "ownerAccount")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>Confirm Account Number*</FormLabel>
          <Input
            type="text"
            name="ownerCAccount"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.ownerCAccount}
            placeholder="Enter Confirm account Number"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "ownerCAccount")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>IFSC Code*</FormLabel>
          <Input
            type="text"
            name="ifscCode"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.ifscCode}
            placeholder="IFSC Code"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "ifscCode")}
        </FormGroup>
        <FormGroup className="login-input-group">
          <FormLabel>Bank Address*</FormLabel>
          <Input
            type="text"
            name="bankAddress"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.bankAddress}
            placeholder="Enter Address"
            className="customer-name-input"
          />
          {validate.displayError(props.touched, props.errors, "bankAddress")}
        </FormGroup>
      </div>
    </div>
  );
}
