import { lazy, Suspense } from "react";
import PageLoader from "../../../assets/elements/loaders/PageLoader";

const LazyFavourites = lazy(() => import("./Favourites.js"));

export default function FilterResults(props) {
  return (
    <Suspense
      fallback={
        <PageLoader
          style={{
            margin: "30vh 50vw",
            width: "60px",
            height: "60px",
            color: "#1a21bc",
          }}
        />
      }
    >
      <LazyFavourites {...props} />
    </Suspense>
  );
}
