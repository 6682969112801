import React, { useEffect, useState, useCallback, useRef } from "react";
import "./Orders.scss";
import { OrderCard } from "../../../assets/elements/cards";
import GreenDoubt from "../../../assets/img/greenDoubt.png";
import OrderCardProfile from "../../../assets/img/OrderCardProfile.png";
import CallRamesh from "../../../assets/img/callRamesh.png";
import LocationIcon from "../../../assets/img/locationIcon.png";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  FormLabel,
  FormGroup,
} from "react-bootstrap";
import PrintBill from "../../../assets/img/print-bill.png";
import BillPaymentMap from "../../../assets/img/map.png";
import Document from "../../../assets/img/document.png";
import axios from "axios";
import VendorServices from "../../../services/Vendorservices";
import PageLoader from "../../../assets/elements/loaders/PageLoader";
import { useSelector } from "react-redux";
import PrimaryButton from "../../../assets/elements/buttons/PrimaryButton";
import validate from "../../../validations";
import CommonServices from "../../../services/CommonServices";
import formatter from "../../../assets/numberFormatter/index";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import moment from "moment";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";
import DeliveryMap from "../../../assets/elements/map/DeliveryMap";
import AdminServices from "../../../services/AdminServices";
import Head from "../../../assets/elements/Head";
import AssignDeliveryMap from "./AssignDeliveryMap";

export default function Orders() {
  const [pageLoading, setPageLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const userId = useSelector((state) => state.auth.userId);
  const config = useSelector((state) => state.config.config);
  const [assignModal, setAssignModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [deliveries, setDeliveries] = useState([]);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [orderStats, setOrderStats] = useState({
    DELIVERED: 0,
    PENDING: 0,
    CONFIRMED: 0,
    TRANSIT: 0,
    COLLECTED: 0,
    REJECTED: 0,
  });
  const [allStats, setAllStats] = useState({
    ALL: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [orderChangeStatus, setOrderChangeStatus] = useState("");
  const [page, setPage] = useState(1);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [restaurantOrderTotal, setRestaurantOrderTotal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [mapModal, setMapModal] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [restoCoords, setRestoCoords] = useState({});
  const [deliveryCoords, setDeliveryCoords] = useState([]);
  const navigate = useNavigate();

  let debounceTimer = useRef(null);
  let orderInterval = useRef(null);

  useEffect(() => {
    getAllDeliveryProfiles();
    if (config.source === "vendor") {
      getRestaurantOrders(selectedStatus, userId, page, "reload", "", 10);
      setRestaurantId(userId);
      getOrderStatsByRestaurant(userId);
      orderInterval.current = setInterval(
        onOrderPartyInterval,
        10000,
        selectedStatus,
        userId,
        page,
        "reftresh",
        "",
        10
      );
    }
    if (config.source === "admin") {
      getRestaurants("reload", "", "");
      ordersAllStats();
      getOrders(1, "reload", 10);
      orderInterval.current = setInterval(
        onAllOrderPartyInterval,
        10000,
        1,
        "refresh",
        10
      );
    }
    return () => {
      clearInterval(orderInterval.current);
    };
    // eslint-disable-next-line
  }, []);

  //Functions

  const onOrderPartyInterval = (
    status,
    restaurantId,
    page,
    reload,
    search_string,
    limit
  ) => {
    getRestaurantOrders(
      status,
      restaurantId,
      page,
      reload,
      search_string,
      limit
    );
  };
  const onAllOrderPartyInterval = (page, reload, limit) => {
    getOrders(page, reload, limit);
  };

  const onAssignModal = (selected) => {
    if (selected) {
      setSelectedOrder(selected);
    } else {
      setSelectedOrder(selected);
    }
    setAssignModal(!assignModal);
  };

  const onStatusChange = (status) => {
    if (status !== selectedStatus) {
      setSelectedStatus(status);
      clearInterval(orderInterval.current);
      if (status === "ACTIVE") {
        getOrders(1, "reload", 10);
        orderInterval.current = setInterval(
          onAllOrderPartyInterval,
          10000,
          1,
          "refresh",
          10
        );
      } else {
        getRestaurantOrders(status, restaurantId, 1, "reload", "", 10);
        setPage(1);
        orderInterval.current = setInterval(
          onOrderPartyInterval,
          10000,
          status,
          restaurantId,
          1,
          "refresh",
          "",
          10
        );
      }
    }
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue({ inputValue });
    return inputValue;
  };

  const onRestaurantsOptions = useCallback(
    debounce((inputText, callback) => {
      getRestaurants("refresh", inputText, callback);
    }, 1000),
    []
  );

  const onRestaurantChange = (selected) => {
    setSelectedRestaurant(selected);
    let status = selectedStatus;
    if (status === "ACTIVE") {
      status = "PENDING";
      setSelectedStatus(status);
    }
    getRestaurantOrders(status, selected.value, 1, "reload", "", 10);
    setPage(1);
    setRestaurantId(selected.value);
    getOrderStatsByRestaurant(selected.value);
    clearInterval(orderInterval.current);
    orderInterval.current = setInterval(
      onOrderPartyInterval,
      10000,
      status,
      selected.value,
      1,
      "refresh",
      "",
      10
    );
  };

  const onStatusModal = (selected, status) => {
    if (selected) {
      setSelectedOrder(selected);
    } else {
      setSelectedOrder("");
    }
    setOrderChangeStatus(status);
    setStatusModal(!statusModal);
  };

  const onLoadRestaurantOrders = () => {
    clearInterval(orderInterval.current);
    if (selectedStatus === "ACTIVE") {
      getOrders(page + 1, "refresh", 10);
      orderInterval.current = setInterval(
        onAllOrderPartyInterval,
        10000,
        1,
        "refresh",
        (page + 1) * 10
      );
    } else {
      getRestaurantOrders(
        selectedStatus,
        restaurantId,
        page + 1,
        "refresh",
        "",
        10
      );

      orderInterval.current = setInterval(
        onOrderPartyInterval,
        10000,

        selectedStatus,
        restaurantId,
        1,
        "refresh",
        "",
        (page + 1) * 10
      );
    }
    setPage(page + 1);
  };

  const onSearchChange = (e) => {
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      getRestaurantOrders(
        selectedStatus,
        restaurantId,
        1,
        "refresh",
        e.target.value,
        10
      );
      setPage(1);
    }, 1000);

    setSearchText(e.target.value);
  };

  const onMapModal = (selected) => {
    console.log(selected);
    if (selected) {
      let coordinates = [];
      let latLng = {
        lat: +selected.address?.latitude,
        lng: +selected.address?.longitude,
      };
      coordinates.push(latLng);
      coordinates.push({
        lat: +selected.restaurant.latitude,
        lng: +selected.restaurant.longitude,
      });
      coordinates.push(...selected.delivery.location.coordinates);
      setRestoCoords({
        lat: (+selected.restaurant.latitude + latLng.lat) / 2,
        lng: (+selected.restaurant.longitude + latLng.lng) / 2,
      });
      setCoordinates(coordinates);
      setMapModal(!mapModal);
    } else {
      setMapModal(!mapModal);
      setCoordinates(coordinates);
    }
  };

  //Apis
  const getRestaurantOrders = (
    status,
    restaurantId,
    page,
    reload,
    term,
    limit
  ) => {
    reload === "reload" ? setPageLoading(true) : setInfiniteLoading(true);
    let orderStatus = [];
    if (status === "CONFIRMED") {
      orderStatus = ["CONFIRMED", "PREPARING", "PACKED"];
    } else {
      orderStatus.push(status);
    }
    const details = {
      resource: "order",
      status: JSON.stringify(orderStatus),
      restaurant: restaurantId,
      page,
      limit,
      search_string: term,
    };
    axios
      .all([VendorServices.vendorGetOrdersByStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let ordersDetails = resData.data ? resData.data : {};
            let orderTotal = ordersDetails.total ? ordersDetails.total : 0;
            setRestaurantOrderTotal(orderTotal);
            ordersDetails = ordersDetails.data ? ordersDetails.data : [];
            page === 1
              ? setOrders(ordersDetails)
              : setOrders([...orders, ...ordersDetails]);
            setInfiniteLoading(false);
            setPageLoading(false);
          } else {
            setPageLoading(false);
            setInfiniteLoading(false);
          }
        } else {
          setPageLoading(false);
          setInfiniteLoading(false);
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching orders");
      });
  };

  const getOrders = (page, reload, limit) => {
    reload === "reload" ? setPageLoading(true) : setInfiniteLoading(true);
    const details = {
      resource: "order",
      page,
      limit,
      status: JSON.stringify([
        "CONFIRMED",
        "PREPARING",
        "PACKING",
        "TRANSIT",
        "PENDING",
        "REJECTED",
      ]),
    };
    axios
      .all([AdminServices.adminGetOrdersByStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let orderDetails = resData.data ? resData.data : {};
            let orderTotal = orderDetails.total ? orderDetails.total : 0;
            setRestaurantOrderTotal(orderTotal);
            orderDetails = orderDetails.data ? orderDetails.data : [];
            page === 1
              ? setOrders(orderDetails)
              : setOrders([...orders, ...orderDetails]);

            setInfiniteLoading(false);
            setPageLoading(false);
          } else {
            setInfiniteLoading(false);
            setPageLoading(false);
          }
        } else {
          setInfiniteLoading(false);
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching orders");
      });
  };

  const getAllDeliveryProfiles = () => {
    const details = {
      status: "APPROVED",
      resource: "delivery",
      state: "ONLINE",
    };
    axios
      .all([CommonServices.getAllDeliveryProfiles(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let deliveries = resData.data ? resData.data : {};
            let deliveriesCords = [];
            deliveries = deliveries.data ? deliveries.data : [];
            deliveries = deliveries.map((eachRes, index) => {
              return {
                ...eachRes,
                label: eachRes?.first_name || eachRes.name,
                value: eachRes._id,
              };
            });

            deliveries.forEach((delivery, index) => {
              if (delivery.location && delivery.location.coordinates) {
                const [longitude, latitude] = delivery.location.coordinates;
                deliveriesCords.push({
                  name: deliveries[index].label,
                  latitude: parseFloat(latitude),
                  longitude: parseFloat(longitude),
                });
              }
            });

            setDeliveries(deliveries);
            setDeliveryCoords(deliveriesCords);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Restaurants");
      });
  };

  const assignDelivery = (values) => {
    setDeliveryLoading(true);
    const details = {
      order_id: selectedOrder._id,
      resource: "order",
      delivery: values.selectedDeliveryAgent.value,
    };
    axios
      .all([CommonServices.assignDelivery(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Delivery agent is assigned Successfully");
            setAssignModal("");
            getRestaurantOrders(
              selectedStatus,
              restaurantId,
              1,
              "reload",
              "",
              10
            );
            setPage(1);
            setDeliveryLoading(false);
          } else {
            setDeliveryLoading(false);
            toast.success(message);
          }
        } else {
          setDeliveryLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in Assigning Delivery Agent");
      });
  };

  const getOrderStatsByRestaurant = (restaurantId) => {
    const details = {
      resource: "order",
      restaurant: restaurantId,
    };
    axios
      .all([CommonServices.getOrderStatsByRestaurant(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let ordersStats = resData.data ? resData.data : {};
            ordersStats = ordersStats.data ? ordersStats.data : [];
            let stats = {
              DELIVERED: 0,
              PENDING: 0,
              CONFIRMED: 0,
              TRANSIT: 0,
              COLLECTED: 0,
              REJECTED: 0,
            };
            ordersStats.map((eachstat) => {
              if (
                eachstat._id === "CONFIRMED" ||
                eachstat._id === "PREPARING" ||
                eachstat._id === "PACKED"
              ) {
                stats["CONFIRMED"] = stats.CONFIRMED + eachstat.count;
              } else {
                stats[eachstat._id] = eachstat.count;
              }
            });
            setOrderStats({ ...stats });
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching orders");
      });
  };
  const ordersAllStats = (restaurantId) => {
    const details = {
      resource: "order",
    };
    axios
      .all([CommonServices.ordersAllStats(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let ordersStats = resData.data ? resData.data : {};
            ordersStats = ordersStats.data ? ordersStats.data : [];
            let stats = {
              ALL: 0,
            };
            ordersStats.map((eachstat) => {
              stats.ALL += eachstat.count;
            });
            setAllStats(stats);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching order Stats");
      });
  };

  const getRestaurants = (reload, term, callback) => {
    reload === "reload" && setPageLoading(true);
    let restaurants = [];
    const details = {
      status: "APPROVED",
      resource: "restaurant",
      search_string: term,
    };
    axios
      .all([CommonServices.getRestaurantsBySearch(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            restaurants = resData.data ? resData.data : {};
            restaurants = restaurants.data ? restaurants.data : [];
            restaurants = restaurants.map((eachRes, index) => {
              return {
                ...eachRes,
                label: eachRes.store_name,
                value: eachRes._id,
              };
            });
            if (callback) callback(restaurants);
            if (restaurants.length > 0 && reload === "reload") {
              // setSelectedRestaurant(restaurants[0]);
              // getRestaurantOrders(
              //   selectedStatus,
              //   restaurants[0].value,
              //   1,
              //   "refresh",
              //   "",
              //   10
              // );
              // orderInterval.current = setInterval(
              //   onOrderPartyInterval,
              //   10000,
              //   selectedStatus,
              //   restaurants[0].value,
              //   1,
              //   "refresh",
              //   "",
              //   10
              // );

              // setPage(1);
              // setRestaurantId(restaurants[0].value);
              // getOrderStatsByRestaurant(restaurants[0].value);
              setDefaultOptions(restaurants);
            }
            setPageLoading(false);
            setRestaurants(restaurants);
          } else {
            setPageLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Restaurants");
      });
  };

  const updateOrderStatus = () => {
    setDeliveryLoading(true);
    const details = {
      status: orderChangeStatus,
      resource: "order",
      order_id: selectedOrder._id,
    };
    axios
      .all([CommonServices.updateOrderStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            setDeliveryLoading(false);
            onStatusModal("", "");
            getOrderStatsByRestaurant(restaurantId);
            getRestaurantOrders(
              selectedStatus,
              restaurantId,
              1,
              "reload",
              "",
              10
            );
            setPage(1);
          } else {
            setDeliveryLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Updating Status");
      });
  };

  return (
    <div className="orders-main-div">
      <Head title="Orders" />
      <div className="order-card-flex">
        {config.source === "admin" && (
          <>
            <OrderCard
              orderCardtext="All"
              orderSubcardContent={allStats.ALL}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "ACTIVE" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("ACTIVE")}
            />
            <AsyncSelect
              className="orders-resto-react-select"
              placeholder="Select Restaurant"
              onChange={onRestaurantChange}
              value={selectedRestaurant}
              loadOptions={onRestaurantsOptions}
              defaultOptions={defaultOptions}
              onInputChange={handleInputChange}
              cacheOptions
            />
          </>
        )}
        {restaurantId && (
          <>
            {" "}
            <OrderCard
              orderCardtext="Pending"
              orderSubcardContent={orderStats.PENDING}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "PENDING" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("PENDING")}
            />
            <OrderCard
              orderCardtext="Confirmed"
              orderSubcardContent={orderStats.CONFIRMED}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "CONFIRMED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("CONFIRMED")}
            />
            <OrderCard
              orderCardtext="Collected"
              orderSubcardContent={orderStats.COLLECTED}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "COLLECTED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("COLLECTED")}
            />
            <OrderCard
              orderCardtext="Delivered"
              orderSubcardContent={orderStats.DELIVERED}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "DELIVERED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("DELIVERED")}
            />
            <OrderCard
              orderCardtext="Rejected"
              orderSubcardContent={orderStats.REJECTED}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "REJECTED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("REJECTED")}
            />{" "}
          </>
        )}
      </div>

      {pageLoading ? (
        <PageLoader />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Input
              placeholder="Search"
              onChange={(e) => onSearchChange(e)}
              value={searchText}
              style={{ width: "25%" }}
            />
          </div>
          {orders &&
            orders.length > 0 &&
            orders.map((eachOrder, index) => (
              <div className="south-indian-restaurant-div row">
                <div className="fast-food-main-div col-lg-3 col-md-6">
                  <span style={{ fontWeight: 600, fontSize: 14 }}>
                    Order on:
                    <div style={{ marginLeft: 5 }}>
                      {moment(eachOrder.createdAt).format(
                        "MMMM Do YYYY, HH:mm"
                      )}
                    </div>
                  </span>
                  <div className="line-break" />
                  <h3
                    className="fast-food-id"
                    style={{ textTransform: "uppercase" }}
                    onClick={() =>
                      navigate(`/order-invoice-details/${eachOrder._id}`)
                    }
                  >
                    ID: #{eachOrder.sequence_no}
                  </h3>
                  <div className="line-break" />
                  <div className="customer-order-time-line">
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        width: "50%",
                      }}
                    >
                      <div>
                        <div className="delivery-status-timing-div">
                          {moment(eachOrder.createdAt).format("HH:mm A")}
                        </div>
                        <div>
                          <div className="each-time-line">
                            <div className="order-status-icon black-dotted-tracker">
                              <p style={{ color: "#000" }}> 1</p>
                            </div>
                          </div>
                          <p
                            className={`order-status-text ${
                              eachOrder.status === "PENDING" ||
                              eachOrder.confirmed ||
                              eachOrder.delivered
                                ? "black-dotted-text"
                                : ""
                            }`}
                          >
                            Placed
                          </p>
                        </div>
                      </div>
                      <div className="each-dashes-line"></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        width: "50%",
                      }}
                    >
                      <div>
                        <div className="delivery-status-timing-div">
                          {eachOrder.confirmed &&
                            moment(eachOrder.confirmed).format("HH:mm A")}
                        </div>
                        <div className="each-time-line">
                          <div
                            className={`order-status-icon ${
                              eachOrder.confirmed || eachOrder.delivered
                                ? "black-dotted-tracker"
                                : ""
                            }`}
                          >
                            <p style={{ color: "#000" }}> 2</p>
                          </div>
                        </div>
                        <p
                          className={`order-status-text ${
                            eachOrder.confirmed || eachOrder.delivered
                              ? "black-dotted-text"
                              : ""
                          }`}
                        >
                          Confirmed
                        </p>
                      </div>
                      <div className="each-dashes-line"></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <div>
                        <div className="delivery-status-timing-div">
                          {eachOrder.delivered &&
                            moment(eachOrder.delivered).format("HH:mm A")}
                        </div>
                        <div className="each-time-line">
                          <div
                            className={`order-status-icon ${
                              eachOrder.delivered ? "black-dotted-tracker" : ""
                            }`}
                          >
                            <p style={{ color: "#000" }}> 3</p>
                          </div>
                        </div>
                        <p
                          className={`order-status-text ${
                            eachOrder.delivered ? "black-dotted-text" : ""
                          }`}
                        >
                          Delivered
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="paneer-kabab-main-div col-lg-3 col-md-6">
                  {eachOrder.orders_items &&
                    eachOrder.orders_items.length > 0 &&
                    eachOrder.orders_items.map((eachOrderItem) => (
                      <div className="paneer-kabab-div">
                        <div className="green-doubt-div">
                          <img src={GreenDoubt} alt="" />
                          <span>
                            {eachOrderItem.quantity} x{" "}
                            {eachOrderItem.item_id &&
                              eachOrderItem.item_id.item_name}
                          </span>
                        </div>
                        <p>
                          {formatter.poundCurrency(eachOrderItem.total_price)}
                        </p>
                      </div>
                    ))}
                </div>
                <div className="total-bill-external-div col-lg-3 col-md-6">
                  <div className="external-div-header">
                    <div className="total-bill-div">
                      <h1 className="fast-food-subheading color-black">
                        Total Bill
                      </h1>
                      <div className="paid-bill-div">
                        <span className="paid-bill-content">PAID</span>
                      </div>
                      <h2 className="fast-food-heading margin-top-class">
                        {formatter.poundCurrency(eachOrder.total_price)}{" "}
                        <i class="fa-solid fa-caret-down" />
                      </h2>
                    </div>
                  </div>
                  <div className="darkblue-color-div">
                    <div className="dark-skyblue-div">
                      <span className="dark-skyblue-div-content">
                        Order Ready (12.3 Min)
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="fast-food-subheading color-black">
                      Delivery Address
                    </span>
                    <h2 className="fast-food-subheading">
                      {(eachOrder.address && eachOrder.address?.full_address) ||
                        "-"}
                    </h2>
                    <div>
                      <img
                        alt=""
                        className="profile-icon-div"
                        style={{ marginRight: 10 }}
                        src={Document}
                      />
                      <img
                        alt=""
                        className="profile-icon-div"
                        src={BillPaymentMap}
                      />
                    </div>
                  </div>
                  <div
                    className="print-bill-main-div"
                    onClick={() =>
                      navigate(`/order-invoice-details/${eachOrder._id}`)
                    }
                  >
                    <img className="profile-icon-div" src={PrintBill} alt="" />
                    <span className="profile-icon-content">Print Bill</span>
                  </div>
                </div>
                <div className="fast-food-delivery-main-div col-lg-3 col-md-6">
                  <h2 className="fast-food-subheading">
                    Delivery Partner Details
                  </h2>
                  <span>
                    Order Status:
                    {eachOrder.delivery &&
                    eachOrder.assign_delivery &&
                    eachOrder.assign_delivery.status !== "PENDING" &&
                    eachOrder.assign_delivery.status !== "REJECTED" ? (
                      <span
                        style={{
                          color: "#fff",
                          marginLeft: 10,
                          padding: 4,
                          background: "var(--theme-primary-color)",
                          borderRadius: 4,
                        }}
                      >
                        {eachOrder.assign_delivery.status}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#fff",
                          marginLeft: 10,
                          padding: 4,
                          background: "var(--theme-primary-color)",
                          borderRadius: 4,
                        }}
                      >
                        {eachOrder.status}
                      </span>
                    )}
                  </span>
                  {eachOrder.delivery &&
                    eachOrder.assign_delivery &&
                    eachOrder.assign_delivery.status !== "PENDING" &&
                    eachOrder.assign_delivery.status !== "REJECTED" && (
                      <div className="profile-details-main-div">
                        <div className="delivery-partner-div">
                          <img
                            alt=""
                            src={
                              eachOrder.delivery &&
                              eachOrder.delivery.profile_picture
                                ? config.fileBasicPath +
                                  eachOrder.delivery.profile_picture
                                : OrderCardProfile
                            }
                            style={{
                              width: 80,
                              height: 80,
                              borderRadius: "50%",
                            }}
                          />
                        </div>

                        <div className="profile-padding-div">
                          <div>
                            <span className="fast-food-heading">
                              {eachOrder.delivery
                                ? eachOrder.delivery.first_name
                                : "Ramesh"}
                            </span>
                            {eachOrder.assign_delivery.status !==
                            "DELIVERED" ? (
                              <span className="fast-food-subheading">
                                {" "}
                                is on the way to pickup
                              </span>
                            ) : (
                              <span className="fast-food-subheading">
                                {" "}
                                Delivered Your Order!
                              </span>
                            )}
                          </div>
                          <div>
                            <div>
                              <img
                                alt=""
                                className="profile-icon-div"
                                src={CallRamesh}
                              />
                              <a
                                href={`tel:+91${eachOrder.delivery.phone}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="profile-icon-content">
                                  Call{" "}
                                  {eachOrder.delivery
                                    ? eachOrder.delivery.phone
                                    : "Ramesh"}
                                </span>
                              </a>
                            </div>
                            {eachOrder.assign_delivery.status !==
                              "DELIVERED" && (
                              <div onClick={() => onMapModal(eachOrder)}>
                                <img
                                  alt=""
                                  className="profile-icon-div"
                                  src={LocationIcon}
                                />
                                <span className="profile-icon-content">
                                  Track
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                  {/* {eachOrder.delivery && eachOrder.assign_delivery && (
                    <div>
                      <div className="progress-bar-content">
                        <p>Arriving in</p>
                        <p>10 Min</p>
                      </div>
                      <ProgressBar className="order-progress-bar" now={60} />
                    </div>
                  )} */}

                  <div className="deliver-action-btns">
                    {/*  {config.source === "admin" &&
                      eachOrder.delivery &&
                      (eachOrder.status === "CONFIRMED" ||
                        eachOrder.status === "REJECTED"  ) &&
                      eachOrder.restaurant.do_you_wanna_dispatch !== "Yes" && (
                        <PrimaryButton
                          title="Assign Delivery"
                          style={{
                            background: "var(--theme-primary-color)",
                            borderColor: "var(--theme-primary-color)",
                          }}
                          onClick={() => onAssignModal(eachOrder)}
                        />
                      )} */}
                    {config.source === "admin" &&
                      eachOrder?.status === "CONFIRMED" &&
                      eachOrder?.restaurant?.do_you_wanna_dispatch !==
                        "Yes" && (
                        <PrimaryButton
                          title="Assign Delivery"
                          style={{
                            background: "var(--theme-primary-color)",
                            borderColor: "var(--theme-primary-color)",
                          }}
                          onClick={() => onAssignModal(eachOrder)}
                        />
                      )}

                    {(config.source === "vendor" &&
                      !eachOrder.delivery &&
                      (eachOrder?.status === "CONFIRMED" || eachOrder?.status === "PACKED" )) &&
                      ( eachOrder?.restaurant.do_you_wanna_dispatch ===
                          "Yes" && (
                          <PrimaryButton
                            title="Assign Delivery"
                            style={{
                              background: "var(--theme-primary-color)",
                              borderColor: "var(--theme-primary-color)",
                            }}
                            onClick={() => onAssignModal(eachOrder)}
                          />
                        ))}
                    {config.source === "vendor" &&
                      eachOrder?.delivery?.status &&
                      eachOrder?.status === "REJECTED" && (
                        <PrimaryButton
                          title="Assign Delivery"
                          style={{
                            background: "var(--theme-primary-color)",
                            borderColor: "var(--theme-primary-color)",
                          }}
                          onClick={() => onAssignModal(eachOrder)}
                        />
                      )}
                  </div>
                  <div className="delivery-host-actions-btns">
                    {config.source === "vendor" &&
                      selectedStatus === "PENDING" && (
                        <>
                          <PrimaryButton
                            title="Confirm"
                            onClick={() =>
                              onStatusModal(eachOrder, "CONFIRMED")
                            }
                            style={{ background: "var(--theme-primary-color)" }}
                          />
                          <PrimaryButton
                            title="Reject"
                            style={{
                              background: "#e23636",
                              borderColor: "#e23636",
                            }}
                            onClick={() => onStatusModal(eachOrder, "REJECTED")}
                          />
                        </>
                      )}
                    {config.source === "vendor" &&
                      eachOrder?.status === "CONFIRMED" &&
                      !eachOrder?.delivery && (
                        <PrimaryButton
                          title="Packed"
                          style={{
                            background: "#e23636",
                            borderColor: "#e23636",
                          }}
                          onClick={() => onStatusModal(eachOrder, "PACKED")}
                        />
                      )}
                  </div>
                </div>
              </div>
            ))}
          <div style={{ textAlign: "center", margin: "20px 0px 30px" }}>
            {orders.length === restaurantOrderTotal ? (
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: "var(--theme-primary-color)",
                }}
              >
                {orders.length === 0
                  ? "No Restaurant Orders Found"
                  : "No More Restaurant Orders Found"}
              </p>
            ) : (
              <PrimaryButton
                title="Load More"
                onClick={onLoadRestaurantOrders}
                loading={infiniteLoading}
                style={{ width: 150, background: "var(--theme-primary-color)" }}
              />
            )}
          </div>
        </div>
      )}
      <Modal show={assignModal} size="xl" onHide={() => onAssignModal("")}>
        <ModalHeader closeButton>
          <ModalTitle>Assign Delivery</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            key="delievry-assign"
            initialValues={{ selectedDeliveryAgent: "" }}
            onSubmit={(values) => {
              assignDelivery(values);
            }}
            validationSchema={validate.assignDeliverySchema}
          >
            {({
              values,
              handleSubmit,
              handleBlur,
              setFieldValue,
              errors,
              touched,
            }) => (
              <>
                <FormGroup className="login-input-group">
                  <FormLabel>Select Delivery Agent*</FormLabel>
                  <ReactSelect
                    name="category"
                    onChange={(selectedDeliveryAgent) => {
                      setFieldValue(
                        "selectedDeliveryAgent",
                        selectedDeliveryAgent
                      );
                    }}
                    onBlur={handleBlur}
                    value={values.selectedDeliveryAgent}
                    placeholder="Select Delivery Agent"
                    options={deliveries}
                  />
                  {validate.displayError(
                    touched,
                    errors,
                    "selectedDeliveryAgent"
                  )}
                </FormGroup>
                <div className="bottom-btns">
                  <PrimaryButton
                    title="Submit"
                    style={{
                      borderColor: "var(--theme-primary-color)",
                      background: "var(--theme-primary-color)",
                      borderRadius: "5px",
                      padding: "15px 50px",
                      margin: "20px 5px",
                    }}
                    loading={deliveryLoading}
                    onClick={handleSubmit}
                  />
                  <PrimaryButton
                    title="Cancel"
                    style={{
                      borderColor: "#e5e5e5",
                      background: "#e5e5e5",
                      borderRadius: "5px",
                      padding: "15px 50px",
                      color: "#000",
                      margin: "20px 5px",
                    }}
                    onClick={() => onAssignModal("")}
                  />
                </div>
                <AssignDeliveryMap
                  display={true}
                  coordinates={deliveryCoords}
                  zoom={6}
                />
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal show={statusModal} onHide={() => onStatusModal("", "")}>
        <ModalHeader closeButton>
          <ModalTitle>
            {orderChangeStatus === "CONFIRMED"
              ? "Confirm"
              : orderChangeStatus === "PACKED"
              ? "Packed"
              : "Reject"}
            Order
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            Do you want{" "}
            {orderChangeStatus === "CONFIRMED"
              ? "Confirm"
              : orderChangeStatus === "PACKED"
              ? "Packed"
              : "Reject"}{" "}
            this Order?
          </p>
          <div className="bottom-btns">
            <PrimaryButton
              title="Submit"
              style={{
                borderColor: "var(--theme-primary-color)",
                background: "var(--theme-primary-color)",
                borderRadius: "5px",
                padding: "15px 50px",
                margin: "20px 5px",
              }}
              loading={deliveryLoading}
              onClick={updateOrderStatus}
            />
            <PrimaryButton
              title="Cancel"
              style={{
                borderColor: "#e5e5e5",
                background: "#e5e5e5",
                borderRadius: "5px",
                padding: "15px 50px",
                color: "#000",
                margin: "20px 5px",
              }}
              onClick={() => onStatusModal("", "")}
            />
          </div>
        </ModalBody>
      </Modal>
      <Modal show={mapModal} onHide={() => onMapModal("")}>
        <ModalBody>
          <DeliveryMap
            display={true}
            zoom={14}
            coordinates={coordinates}
            restoCoords={restoCoords}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
