import React from "react";
import "./ProductCard.scss";

export default function ProductCard(props) {
  return (
    <div {...props} className={`productcard-external-div ${props?.className || ""}`} >
      <div className="productcard-image-div">
        <img src={props.image} alt="icon" />
      </div>
      <div className="productcard-bottom-div">
        <h1 className="productcard-heading">{props.title}</h1>
        <p className="productcard-para">{props.description}</p>
        <div className="bottom-lower-div">
          <div>
          <img src={props.icon} alt="icon" />
          <span className="productcard-para">{props.map}</span>
          </div>
          <button className="productcard-button">Order now</button>
        </div>
      </div>
    </div>
  );
}
