import React from "react";
import "./Commission.scss";

import validate from "../../../validations";
import { FormGroup, FormLabel, Form } from "react-bootstrap";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";

export default function Commission(props) {
  return (
    <div className="commission-main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Commission Type</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Per Order"
            name="emailOrder"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="PER_ORDER"
            checked={props.values.emailOrder === "PER_ORDER"}
          />
          <Form.Check
            inline
            label="Monthly Subscription"
            name="emailOrder"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="MONTHLY"
            checked={props.values.emailOrder === "MONTHLY"}
          />
        </div>
        {validate.displayError(props.touched, props.errors, "emailOrder")}
      </div>

      <FormGroup className="login-input-group">
        <FormLabel>Delivery Commision*</FormLabel>
        <Input
          type="number"
          name="commission"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.commission}
          placeholder="Delivery Commission"
          className="customer-name-input"
        />
        {validate.displayError(props.touched, props.errors, "commission")}
      </FormGroup>
      <FormGroup className="login-input-group">
        <FormLabel>Pickup Commision*</FormLabel>
        <Input
          type="number"
          name="pickupCom"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.pickupCom}
          placeholder="Pickup Commission"
          className="customer-name-input"
        />
        {validate.displayError(props.touched, props.errors, "pickupCom")}
      </FormGroup>
    </div>
  );
}
