import React from "react";
import "./OrderCard.scss";

export default function OrderCard(props) {
  return (
    <div {...props} className={`order-card-main-div ${props?.className || ""}`}>
      <div className="order-card-inner-div">
        <div className="order-card-text">
          <p>{props.orderCardtext}</p>
        </div>
        <div className="order-card-sub-div">
          <p>{props.orderSubcardContent}</p>
        </div>
      </div>
    </div>
  );
}
