import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Form,
  FormLabel,
  FormGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./customerProfile.scss";
import CustomerProfileImage from "../../../assets/img/1000x1000.jpg";
import {
  ButtonWithIcon,
  PrimaryButton,
} from "../../../assets/elements/buttons";
import Edit from "../../../assets/img/svg/edit.svg";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import CustomerServices from "../../../services/CustomerServices";
import CommonServices from "../../../services/CommonServices";
import PageLoader from "../../../assets/elements/loaders/PageLoader";
import { Formik } from "formik";
import Input from "../../../assets/elements/formElements/Input";
import validate from "../../../validations";
import { AuthenticateAction } from "../../../redux/Auth";
import * as Storage from "../../../services/LocalStorage";
import Head from "../../../assets/elements/Head";

export default function CustomerProfile() {
  const navigate = useNavigate();
  const [profileLoading, setProfileLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const customerId = useSelector((state) => state.auth.userId);
  const [profile, setProfile] = useState({});
  const [profileModal, setProfileModal] = useState(false);
  const [profileImage, setProfileImage] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const { fileBasicPath, sessionKey } = useSelector(
    (state) => state.config.config
  );
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let profileRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    customerProfile();
  }, []);

  //functions
  const handleProfileModal = () => {
    setProfileModal(!profileModal);
    setImagePreview("");
    setProfileImage("");
  };

  const onImageRef = () => {
    profileRef.current?.click();
  };

  const handleUpdate = (setFieldValue) => {
    if (profileRef.current?.files) {
      let file = profileRef.current.files[0];
      file["field"] = "profileImage";
      setFieldValue("profileImage", file);
      setProfileImage(file);
    }
    profileRef.current?.files &&
      setImagePreview(URL.createObjectURL(profileRef.current.files[0]));
  };

  const customerProfile = () => {
    setPageLoading(true);
    const details = {
      customer_id: customerId,
      resource: "profile",
    };
    axios
      .all([CustomerServices.customerGetProfile(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let profileData = resData.data ? resData.data : {};
            profileData = profileData.data ? profileData.data : {};
            let data = {
              ...auth,
              profileImage: profileData.profile_image,
              name: profileData.name,
            };

            Storage.set(sessionKey, JSON.stringify(data));
            dispatch(AuthenticateAction(data));

            setProfile(profileData);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in Fetching Customer profile");
      });
  };
  const customerUpdateProfile = (values, file) => {
    const details = {
      customer_id: customerId,
      resource: "profile",
      name: values.fname, 
      profile_image: file.profileImage
        ? file.profileImage
        : values.profileImage,
    };
    axios
      .all([CustomerServices.customerUpdateProfile(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            customerProfile();
            handleProfileModal("");
            setProfileLoading(false);
          } else {
            setProfileLoading(false);
          }
        } else {
          setProfileLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in Fetching Customer profile");
      });
  };

  const uploadFile = (values) => {
    setProfileLoading(true);
    let selectedImages = [];
    console.log(profileImage);
    profileImage && selectedImages.push(profileImage);
    console.log(selectedImages);
    axios
      .all([CommonServices.fileUpload(selectedImages)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.status) {
            let fileData = resData.data;
            let files = {};
            fileData.map((eachFile) => {
              files[eachFile.fileLabel] = eachFile.filename;
              return true;
            });
            customerUpdateProfile(values, files);
          } else {
          }
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in file upload");
      });
  };

  return (
    <div className="customer-profile-main">
      <Head title="Profile" />
      <div class="main-sec"></div>
      <Breadcrumb className="breadcrumb-external-div">
        <Breadcrumb.Item
          className="breadcrumd-reference"
          onClick={() => navigate("/")}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
      </Breadcrumb>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="customer-profile-main-div">
          <div className="customer-profile-flex">
            <div className="customer-profile-image-div">
              <img
                src={
                  profile.profile_image
                    ? fileBasicPath + profile.profile_image
                    : CustomerProfileImage
                }
                alt="pic not loaded"
              />
            </div>
            <div>
              <h1 className="Profile-name-div">{profile.name}</h1>
              <div>
                <span style={{ marginRight: 10 }}>
                  <i class="fa-solid fa-envelope"></i>
                </span>
                <span>{profile.email}</span>
              </div>
              <div>
                <span style={{ marginRight: 10 }}>
                  <i class="fa-solid fa-phone"></i>
                </span>
                <span>{profile.phone}</span>
              </div>
            </div>
          </div>
          <div>
            <div>
              {" "}
              <ButtonWithIcon
                title="Edit Profile"
                iconEnd={true}
                icon={Edit}
                style={{
                  borderRadius: "5px",
                  padding: "10px 15px",
                  background: "var(--customer-theme-color)",
                }}
                className="action-btn"
                onClick={handleProfileModal}
              />
            </div>
            {/* <div className="profile-text-cards">
            <div>
              <p>0</p>
              <p>Reviews</p>
            </div>
            <div className="profile-text-middle">
              <p>0</p>
              <p>Photos</p>
            </div>
            <div>
              <p>0</p>
              <p>Followers</p>
            </div>
          </div> */}
          </div>
        </div>
      )}
      <Modal show={profileModal} onHide={handleProfileModal}>
        <ModalHeader closeButton>
          <ModalTitle>Edit Customer Profile</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              fname: profile?.name || "",
              phoneNumber: profile?.phone || "",
              email: profile?.email || "",
              profileImage: profile?.profile_image || "",
            }}
            validationSchema={validate.customerProfileForm}
            onSubmit={(values) => uploadFile(values)}
          >
            {({
              values,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <>
                <div className="profile-edit-div">
                  <div className="customer-profile-image-div">
                    <img
                      src={
                        imagePreview
                          ? imagePreview
                          : profile.profile_image
                          ? fileBasicPath + profile.profile_image
                          : CustomerProfileImage
                      }
                      alt="pic not loaded"
                    />
                    <div className="profile-pic-update" onClick={onImageRef}>
                      <i class="fa-solid fa-camera"></i>
                    </div>
                    <Form.Control
                      name="profileImage"
                      type="file"
                      ref={profileRef}
                      style={{ display: "none" }}
                      onChange={() => handleUpdate(setFieldValue)}
                      onBlur={handleBlur}
                      accept="image/jpg,image/jpeg,image/png,image/svg"
                    />
                  </div>
                  <div
                    style={{
                      fontSize: 10,
                      color: "#ff0000",
                      margin: "10px 0px 20px",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    * only jpg, jpeg, svg, png formatted images supported
                    <div style={{ marginTop: 5 }}>
                      {validate.displayError(touched, errors, "profileImage")}
                    </div>
                  </div>
                  <FormGroup className="login-input-group">
                    <FormLabel>Customer Name*</FormLabel>
                    <Input
                      type="text"
                      name="fname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fname}
                      placeholder="Enter Customer Name"
                      className="customer-name-input"
                    />
                  </FormGroup>
                  <FormGroup className="login-input-group">
                    <FormLabel>Phone Number*</FormLabel>
                    <Input
                      type="tel"
                      name="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      placeholder="Enter Phone Number"
                      className="customer-name-input"
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup className="login-input-group">
                    <FormLabel>Customer Email*</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Enter Email Id"
                      className="customer-name-input"
                      disabled={true}
                    />
                  </FormGroup>
                </div>
                <div className="bottom-btns">
                  <PrimaryButton
                    title="Submit"
                    type="submit"
                    style={{
                      borderColor: "var(--customer-theme-color)",
                      background: "var(--customer-theme-color)",
                      borderRadius: "5px",
                      padding: "15px 50px",
                      margin: "20px 5px",
                    }}
                    loading={profileLoading}
                    onClick={handleSubmit}
                  />
                  <PrimaryButton
                    title="Cancel"
                    style={{
                      borderColor: "#e5e5e5",
                      background: "#e5e5e5",
                      borderRadius: "5px",
                      padding: "15px 50px",
                      color: "#000",
                      margin: "20px 5px",
                    }}
                    onClick={handleProfileModal}
                  />
                </div>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
}
