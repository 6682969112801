import React, { useEffect, useState, useCallback } from "react";
import Head from "../../../assets/elements/Head";
import { useSelector } from "react-redux";
import CommonServices from "../../../services/CommonServices";
import axios from "axios";
import "./DeliveryAgentOrders.scss";
import PrimaryButton from "../../../assets/elements/buttons/PrimaryButton";
import PageLoader from "../../../assets/elements/loaders/PageLoader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PrintBill from "../../../assets/img/print-bill.png";
import BillPaymentMap from "../../../assets/img/map.png";
import GreenDoubt from "../../../assets/img/greenDoubt.png";
import OrderCardProfile from "../../../assets/img/OrderCardProfile.png";
import CallRamesh from "../../../assets/img/callRamesh.png";
import LocationIcon from "../../../assets/img/locationIcon.png";
import formatter from "../../../assets/numberFormatter/index";
import { ProgressBar } from "react-bootstrap";
import { debounce, get } from "lodash";
import AsyncSelect from "react-select/async";

import { OrderCard } from "../../../assets/elements/cards";

export default function DeliveryAgentOrders() {
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedDeliveryAgent, setSelectedDeliveryAgent] = useState("");
  const config = useSelector((state) => state.config.config);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [restaurantOrderTotal, setRestaurantOrderTotal] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [deliveryAgents, setDeliveryAgents] = useState([]);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [ordersStats, setOrdersStats] = useState({});

  const onStatusModal = () => {};
  const onAssignModal = () => {};
  const onMapModal = () => {};

  const onDeliveryAgentChange = (selectedAgent) => {
    setSelectedDeliveryAgent(selectedAgent);
    getAgentOrders(page, "", 10, selectedAgent, selectedStatus);
  };

  const orderTimeline = [
    { orderStatus: "Placed", time: "2:00 PM" },
    { orderStatus: "Accepted", time: "2:03 PM" },
  ];

  useEffect(() => {
    config.source === "admin" && getAllDeliveryProfiles("", "reload");
    config.source === "vendor" && getRestaurantsDeliveryAgents("", "reload");
    // eslint-disable-next-line
  }, []);

  const getAllDeliveryProfiles = (term, reload, callback) => {
    const details = {
      status: "APPROVED",
      resource: "delivery",
      search_string: term,
    };
    axios
      .all([CommonServices.getAllDeliveryProfiles(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let deliveries = resData.data ? resData.data : {};
            deliveries = deliveries.data ? deliveries.data : [];
            deliveries = deliveries.map((eachRes, index) => {
              return {
                ...eachRes,
                label: eachRes.name,
                value: eachRes._id,
              };
            });
            if (callback) callback(deliveries);
            if (deliveries.length > 0 && reload === "reload") {
              setSelectedDeliveryAgent(deliveries[0]);
              getAgentOrders(page, "reload", 10, deliveries[0], selectedStatus);
              getAgentOrdersStats(deliveries[0]);
            }
            setDeliveryAgents(deliveries);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Delivery agents");
      });
  };
  const getAgentOrdersStats = (selectedAgent) => {
    const details = {
      resource: "order",
      delivery_id: selectedAgent._id,
    };
    axios
      .all([CommonServices.getAgentOrdersStats(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let statsData = resData.data ? resData.data : {};
            statsData = statsData.data ? statsData.data : [];
            let stats = {};
            statsData.map((eachStats) => {
              stats[eachStats._id] = eachStats.count;
            });
            setOrdersStats(stats);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Delivery agents");
      });
  };
  const getRestaurantsDeliveryAgents = (term, reload, callback) => {
    const details = {
      status: "APPROVED",
      resource: "delivery",
      search_string: term,
    };
    axios
      .all([CommonServices.getRestaurantsDeliveryAgents(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let deliveries = resData.data ? resData.data : {};
            deliveries = deliveries.data ? deliveries.data : [];
            deliveries = deliveries.map((eachRes, index) => {
              return {
                ...eachRes,
                label: eachRes.name,
                value: eachRes._id,
              };
            });
            if (callback) callback(deliveries);
            if (deliveries.length > 0 && reload === "reload") {
              setSelectedDeliveryAgent(deliveries[0]);
              getAgentOrders(page, "reload", 10, deliveries[0], selectedStatus);
              getAgentOrdersStats(deliveries[0]);
            }
            setDeliveryAgents(deliveries);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Delivery agents");
      });
  };

  const getAgentOrders = (page, reload, limit, selectedAgent, status) => {
    reload === "reload" ? setPageLoading(true) : setInfiniteLoading(true);
    const details = {
      resource: "order",
      page,
      limit,
      delivery_id: selectedAgent._id,
      status,
    };
    axios
      .all([CommonServices.getAgentOrders(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let orderDetails = resData.data ? resData.data : {};
            let orderTotal = orderDetails.total ? orderDetails.total : 0;
            setRestaurantOrderTotal(orderTotal);
            orderDetails = orderDetails.data ? orderDetails.data : [];
            page === 1
              ? setOrders(orderDetails)
              : setOrders([...orders, ...orderDetails]);

            setInfiniteLoading(false);
            setPageLoading(false);
          } else {
            setInfiniteLoading(false);
            setPageLoading(false);
          }
        } else {
          setInfiniteLoading(false);
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching orders");
      });
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue({ inputValue });
    return inputValue;
  };

  const onRestaurantsOptions = useCallback(
    debounce((inputText, callback) => {
      config.source === "admin" &&
        getAllDeliveryProfiles(inputText, "refresh", callback);
      config.source === "vendor" &&
        getRestaurantsDeliveryAgents(inputText, "refresh", callback);
    }, 1000),
    []
  );

  const onStatusChange = (status) => {
    setSelectedStatus(status);
    getAgentOrders(page, "", 10, selectedDeliveryAgent, status);
  };

  return (
    <div className="delivery-agent-orders-main">
      <Head title="Assigned Orders" />
      <div className="customer-header">
        <p className="customer-header-text">Delivery Agent Orders</p>
      </div>
      <div class="search-customer-main">
        <AsyncSelect
          className="orders-resto-react-select"
          placeholder="Select Restaurant"
          onChange={onDeliveryAgentChange}
          value={selectedDeliveryAgent}
          loadOptions={onRestaurantsOptions}
          defaultOptions={deliveryAgents}
          onInputChange={handleInputChange}
          cacheOptions
        />
        {selectedDeliveryAgent && (
          <>
            <OrderCard
              orderCardtext="Pending"
              orderSubcardContent={ordersStats?.PENDING || 0}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "PENDING" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("PENDING")}
            />
            <OrderCard
              orderCardtext="Accepted"
              orderSubcardContent={ordersStats?.ACCEPTED || 0}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "ACCEPTED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("ACCEPTED")}
            />
            <OrderCard
              orderCardtext="Completed"
              orderSubcardContent={ordersStats?.COMPLETED || 0}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "COMPLETED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("COMPLETED")}
            />
            <OrderCard
              orderCardtext="Rejected"
              orderSubcardContent={ordersStats?.REJECTED || 0}
              style={{
                cursor: "pointer",
                ...(selectedStatus === "REJECTED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("REJECTED")}
            />
          </>
        )}
      </div>

      {pageLoading ? (
        <PageLoader />
      ) : (
        <div>
          {orders &&
            orders.length > 0 &&
            orders.map((eachOrder, index) => (
              <div className="south-indian-restaurant-div row">
                <div className="fast-food-main-div col-lg-3 col-md-6">
                  <span style={{ fontWeight: 600, fontSize: 14 }}>
                    Order on:
                    <span style={{ marginLeft: 5 }}>
                      {moment(eachOrder?.order?.createdAt).format(
                        "MMMM Do YYYY, HH:mm"
                      )}
                    </span>
                  </span>
                  <div className="line-break" />
                  <h3
                    className="fast-food-id"
                    style={{ textTransform: "uppercase" }}
                    onClick={() =>
                      navigate(
                        `/order-invoice-details/${eachOrder?.order?._id}`
                      )
                    }
                  >
                    ID: #{eachOrder?.order?.sequence_no}
                  </h3>
                  <div className="line-break" />
                  <div className="order-time-line">
                    {orderTimeline.map((eachTimeline, index) => (
                      <div style={{ display: "flex" }}>
                        <div className="each-time-line">
                          <p>{eachTimeline.orderStatus}</p>
                          <span className="order-status-icon ">
                            <i class="fa-solid fa-circle-check" />
                          </span>
                          <p>{eachTimeline.time}</p>
                        </div>
                        {index < orderTimeline.length - 1 && (
                          <div className="timeline-line " />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="paneer-kabab-main-div col-lg-3 col-md-6">
                  {eachOrder.order &&
                    eachOrder.order.menus &&
                    eachOrder.order.menus.length > 0 &&
                    eachOrder.order.menus.map((eachOrderItem) => (
                      <div className="paneer-kabab-div">
                        <div className="green-doubt-div">
                          <img src={GreenDoubt} alt="" />
                          <span>
                            {eachOrderItem.quantity} x{" "}
                            {eachOrderItem.id && eachOrderItem.id.item_name}
                          </span>
                        </div>
                        <p>
                          {formatter.poundCurrency(
                            eachOrderItem.id.offer_price *
                              eachOrderItem.quantity
                          )}
                        </p>
                      </div>
                    ))}
                </div>
                <div className="total-bill-external-div col-lg-3 col-md-6">
                  <div className="external-div-header">
                    <div className="total-bill-div">
                      <h1 className="fast-food-subheading color-black">
                        Total Bill
                      </h1>
                      <div className="paid-bill-div">
                        <span className="paid-bill-content">PAID</span>
                      </div>
                      <h2 className="fast-food-heading margin-top-class">
                        {formatter.poundCurrency(eachOrder?.order.total_price)}{" "}
                        <i class="fa-solid fa-caret-down" />
                      </h2>
                    </div>
                  </div>
                  <div className="darkblue-color-div">
                    <div className="dark-skyblue-div">
                      <span className="dark-skyblue-div-content">
                        Order Ready (12.3 Min)
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="fast-food-subheading color-black">
                      Delivery Address
                    </span>
                    <h2 className="fast-food-subheading">
                      {(eachOrder?.order.address &&
                        eachOrder?.order.address?.full_address) ||
                        "-"}
                    </h2>
                    <div>
                      <img
                        alt=""
                        className="profile-icon-div"
                        style={{ marginRight: 10 }}
                        src={Document}
                      />
                      <img
                        alt=""
                        className="profile-icon-div"
                        src={BillPaymentMap}
                      />
                    </div>
                  </div>
                  <div className="print-bill-main-div">
                    <img className="profile-icon-div" src={PrintBill} alt="" />
                    <span className="profile-icon-content">Print Bill</span>
                  </div>
                </div>
                <div className="fast-food-delivery-main-div col-lg-3 col-md-6">
                  <h2 className="fast-food-subheading">
                    Delivery Partner Details
                  </h2>
                  <span>
                    Order Status:
                    <span
                      style={{
                        color: "#fff",
                        marginLeft: 10,
                        padding: 4,
                        background: "var(--theme-primary-color)",
                        borderRadius: 4,
                      }}
                    >
                      {eachOrder?.order.status}
                    </span>
                  </span>
                  {eachOrder?.order.delivery && (
                    <div className="profile-details-main-div">
                      <div className="delivery-partner-div">
                        <img
                          alt=""
                          src={
                            eachOrder?.order.delivery &&
                            eachOrder?.order.delivery.profile_picture
                              ? config.fileBasicPath +
                                eachOrder?.order.delivery.profile_picture
                              : OrderCardProfile
                          }
                          style={{ width: 80, height: 80, borderRadius: "50%" }}
                        />
                      </div>
                      <div className="profile-padding-div">
                        <div>
                          <span className="fast-food-heading">
                            {eachOrder?.order.delivery
                              ? eachOrder?.order.delivery.name
                              : "Ramesh"}
                          </span>
                          <span className="fast-food-subheading">
                            {" "}
                            is on the way to pickup
                          </span>
                        </div>
                        <div>
                          <div>
                            <img
                              alt=""
                              className="profile-icon-div"
                              src={CallRamesh}
                            />
                            <a
                              href={`tel:+91${eachOrder?.order.delivery.phone}`}
                              target="_blank"
                            >
                              <span className="profile-icon-content">
                                Call{" "}
                                {eachOrder?.order.delivery
                                  ? eachOrder?.order.delivery.name
                                  : "Ramesh"}
                              </span>
                            </a>
                          </div>
                          <div onClick={() => onMapModal(eachOrder)}>
                            <img
                              alt=""
                              className="profile-icon-div"
                              src={LocationIcon}
                            />
                            <span className="profile-icon-content">Track</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {eachOrder?.order.delivery && (
                    <div>
                      <div className="progress-bar-content">
                        <p>Arriving in</p>
                        <p>10 Min</p>
                      </div>
                      <ProgressBar className="order-progress-bar" now={60} />
                    </div>
                  )}
                  <div className="deliver-action-btns">
                    {config.source === "admin" &&
                      !eachOrder.delivery &&
                      eachOrder?.order.status === "CONFIRMED" &&
                      eachOrder?.order.restaurant.do_you_wanna_dispatch !==
                        "Yes" && (
                        <PrimaryButton
                          title="Assign Delivery"
                          style={{
                            background: "var(--theme-primary-color)",
                            borderColor: "var(--theme-primary-color)",
                          }}
                          onClick={() => onAssignModal(eachOrder)}
                        />
                      )}
                    {config.source === "vendor" &&
                      !eachOrder.delivery &&
                      eachOrder?.order.status === "CONFIRMED" &&
                      eachOrder?.order.restaurant.do_you_wanna_dispatch ===
                        "Yes" && (
                        <PrimaryButton
                          title="Assign Delivery"
                          style={{
                            background: "var(--theme-primary-color)",
                            borderColor: "var(--theme-primary-color)",
                          }}
                          onClick={() => onAssignModal(eachOrder.order)}
                        />
                      )}
                  </div>
                  <div className="delivery-host-actions-btns">
                    {config.source === "vendor" &&
                      eachOrder?.order.status === "PENDING" && (
                        <>
                          <PrimaryButton
                            title="Confirm"
                            onClick={() =>
                              onStatusModal(eachOrder, "CONFIRMED")
                            }
                            style={{ background: "var(--theme-primary-color)" }}
                          />
                          <PrimaryButton
                            title="Reject"
                            style={{
                              background: "#e23636",
                              borderColor: "#e23636",
                            }}
                            onClick={() =>
                              onStatusModal(eachOrder.order, "REJECTED")
                            }
                          />
                        </>
                      )}
                    {config.source === "vendor" &&
                      eachOrder.status === "CONFIRMED" &&
                      !eachOrder.delivery && (
                        <PrimaryButton
                          title="Packed"
                          style={{
                            background: "#e23636",
                            borderColor: "#e23636",
                          }}
                          onClick={() =>
                            onStatusModal(eachOrder.order, "PACKED")
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            ))}
          <div style={{ textAlign: "center", margin: "20px 0px 30px" }}>
            {orders.length === restaurantOrderTotal ? (
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: "var(--theme-primary-color)",
                }}
              >
                {orders.length === 0
                  ? "No Assigned Orders Found"
                  : "No More Assigned Orders Found"}
              </p>
            ) : (
              <PrimaryButton
                title="Load More"
                // onClick={onLoadRestaurantOrders}
                loading={infiniteLoading}
                style={{ width: 150, background: "var(--theme-primary-color)" }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
