import React, { useState } from "react";
import { Form, FormGroup, FormLabel } from "react-bootstrap";
import validate from "../../../validations";
import Input from "../../../assets/elements/formElements/Input";
import { Formik } from "formik";
import { PrimaryButton } from "../../../assets/elements/buttons";
import "./ForgetPassword.scss";
import axios from "axios";
import { toast } from "react-toastify";
import CustomerServices from "../../../services/CustomerServices";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VendorServices from "../../../services/Vendorservices";
import Head from "../../../assets/elements/Head";
import showicon from "../../../assets/img/eye-slash.svg";
import hideicon from "../../../assets/img/eye-open.svg";

export default function ForgetPassword() {
  const [updatePassword, setUpdatePassword] = useState(false);
  const [forgetLoading, setForgetLoading] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
  const navigate = useNavigate();

  const config = useSelector((state) => state.config.config);

  const customerForgetPassword = (values) => {
    setForgetLoading(true);
    const details = {
      email: values.email,
    };
    axios
      .all([CustomerServices.customerForgetPassword(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Otp sent to email Successfully");
            setUpdatePassword(true);
            setForgetLoading(false);
          } else {
            toast.error(message);
            setForgetLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Forget password");
      });
  };
  const customerVerifyForgetPassword = (values) => {
    setForgetLoading(true);
    const details = {
      email: values.email,
      otp: values.verificationOtp,
      password: values.password,
    };
    axios
      .all([CustomerServices.customerVerifyForgetPassword(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Forget Password Verify Successfully");
            setForgetLoading(false);
            navigate("/");
          } else {
            toast.error(message);
            setForgetLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in verification Forget password");
      });
  };

  const vendorForgetPassword = (values) => {
    setForgetLoading(true);
    const details = {
      email: values.email,
    };
    axios
      .all([VendorServices.vendorForgetPassword(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Otp sent to email Successfully");
            setUpdatePassword(true);
            setForgetLoading(false);
          } else {
            toast.error(message);
            setForgetLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Forget password");
      });
  };
  const vendorVerifyForgetPassword = (values) => {
    setForgetLoading(true);
    const details = {
      email: values.email,
      otp: values.verificationOtp,
      password: values.password,
      confirm_password: values.confirm_password
    }; 
    axios
      .all([VendorServices.vendorVerifyForgetPassword(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Forget Password Verify Successfully");
            setForgetLoading(false);
            navigate("/login");
          } else {
            toast.error(message);
            setForgetLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in verification Forget password");
      });
  };

  return (
    <div className="forget-password-main">
      <Head title="Forget Password" />
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirm_password: "",
          verificationOtp: "",
        }}
        validationSchema={
          updatePassword
            ? validate.forgetPasswordVerifySchema
            : validate.forgetPasswordSchema
        }
        onSubmit={(values) => {
          if (config.source === "customer") {
            updatePassword
              ? customerVerifyForgetPassword(values)
              : customerForgetPassword(values);
          }
          if (config.source === "vendor") {
            updatePassword
              ? vendorVerifyForgetPassword(values)
              : vendorForgetPassword(values);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Form className="forget-password-form-main" onSubmit={handleSubmit}>
              <div className="forgetpassword-header-div">
                <h3 className="verification-header">Forget Password</h3>
              </div>
              <div className="forgetpassword-inner-div">
                <FormGroup className="login-input-group">
                  <FormLabel className="password-label">
                    <span>Email*</span>{" "}
                  </FormLabel>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Enter Email Address"
                    className="login-input"
                  />
                  {validate.displayError(touched, errors, "email")}
                </FormGroup>

                {updatePassword && (
                  <>
                    <FormGroup
                      className="login-input-group"
                      style={{ margin: "10px 0px" }}
                    >
                      <FormLabel className="password-label">
                        <span>Password*</span>{" "}
                      </FormLabel>
                      <div className="password-input-container">
                        <Input
                          type={passwordHidden ? "password" : "text"}
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Enter Password "
                          className="login-input"
                        />
                        <img
                          className="password-icon"
                          onClick={() => setPasswordHidden(!passwordHidden)}
                          src={passwordHidden ? showicon : hideicon}
                          alt="show"
                        />
                      </div>

                      {validate.displayError(touched, errors, "password")}
                    </FormGroup>
                    <FormGroup
                      className="login-input-group"
                      style={{ margin: "10px 0px" }}
                    >
                      <FormLabel className="password-label">
                        <span>Confirm Password*</span>{" "}
                      </FormLabel>
                      <div className="password-input-container">
                        <Input
                          type={confirmPasswordHidden ? "password" : "text"}
                          name="confirm_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirm_password}
                          placeholder="Enter Password "
                          className="login-input"
                        />
                        <img
                          className="password-icon"
                          onClick={() => setConfirmPasswordHidden(!confirmPasswordHidden)}
                          src={confirmPasswordHidden ? showicon : hideicon}
                          alt="show"
                        />
                      </div>

                      {validate.displayError(
                        touched,
                        errors,
                        "confirm_password"
                      )}
                    </FormGroup>
                    <FormLabel className="password-label">
                      <span>Enter OTP*</span>{" "}
                    </FormLabel>
                    <div className="multi-input-box-main">
                      <OtpInput
                        value={values.verificationOtp}
                        onChange={(otp) => {
                          setFieldValue("verificationOtp", otp);
                        }}
                        onBlur={handleBlur}
                        name="verificationOtp"
                        numInputs={6}
                        isInputNum={true}
                        className="input-box"
                        type="number"
                      />
                    </div>
                    {validate.displayError(touched, errors, "verificationOtp")}
                  </>
                )}
              </div>
              <div className="bottom-btns">
                <PrimaryButton
                  title="Submit"
                  style={{
                    borderColor: "#FF5C00",
                    background: "#FF5C00",
                    borderRadius: "5px",
                    padding: "15px 50px",
                    margin: "10px 5px 0",
                  }}
                  loading={forgetLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
