import React from "react";
import "./Payments.scss";
import validate from "../../../validations";
import { Form, FormGroup, FormLabel } from "react-bootstrap";
import CustomSelect from "../../../assets/elements/select/CustomSelect";

const showOptions = [
  { label: "Weekly", value: "WEEKLY" },
  { label: "Half Monthly", value: "HALF_MONTHLY" },
  { label: " Monthly", value: "MONTHLY" },
];

export default function Payments(props) {
  return (
    <div className="payments-main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Cash on Delivery*</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="cod"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="cod"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
        {validate.displayError(props.touched, props.errors, "pickupCom")}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Online*</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="online"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="online"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
        {validate.displayError(props.touched, props.errors, "pickupCom")}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Both*</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="both"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="both"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
        {validate.displayError(props.touched, props.errors, "pickupCom")}
      </div>
      <FormGroup className="login-input-group">
        <FormLabel>Settlement Frequency*</FormLabel>
        <CustomSelect
          name="settlement_frequency"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.settlement_frequency}
          className="customer-name-input"
          options={showOptions}
        />
        {validate.displayError(props.touched, props.errors, "settlement_frequency")}
      </FormGroup>
    </div>
  );
}
