import React, { useRef, useState } from "react";
import "./UploadInput.scss";
import { Form } from "react-bootstrap";

export default function UploadInput(props) {
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState();
  const inputRef = useRef(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = () => {
    inputRef.current?.files && setFile(inputRef.current.files[0]);
    setPreview(URL.createObjectURL(inputRef.current.files[0]));
    props.onChange && props.onChange(inputRef.current.files[0]);
  };
  return (
    <div className={`upload-input-main ${props?.className || ""}`}>
      <Form.Control
        {...props}
        type="file"
        ref={inputRef}
        onChange={handleDisplayFileDetails}
        style={{ ...props.style, display: "none" }}
      />

      <div
        className="custom-upload-input"
        onClick={handleUpload}
      >
        <span className="file-name-text">
          {" "}
          {file ? file.name : "No File Chosen"}
        </span>
        <span className="upload-btn">Upload</span>
      </div>
      {props.imagePreview && file && (
        <div className="image-preview" >
          {file && <img src={preview}  style={{width:'100%',height:'100%',objectFit:'cover'}} alt='preview'/>}
        </div>
      )}
    </div>
  );
}
