export const set = (key, value) => localStorage.setItem(key, value);
export const get = (key) => localStorage.getItem(key);
export const remove = (key) => localStorage.removeItem(key);
export const clear = () => localStorage.clear();

export const checkUserSession = (authData) => {
  if (authData.token) {
    let data = {
      token: authData.token,
      phone: authData.phone,
      emailId: authData.emailId,
      name: authData.name,
      role: authData.role,
      userId:authData.userId,
      profileImage: authData.profileImage,
    };
    return { status: true, data };
  } else {
    return { status: false, data: "" };
  }
};


