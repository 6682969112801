import React from "react";
import "./RestaurantCard.scss";
import PrimaryButton from "../../buttons/PrimaryButton";

export default function RestaurantCard(props) {
  return (
    <div
    {...props}  className={`restaurant-card ${props.className ? props.className : ""}`}
     
    >
      <img src={props.image} alt="restaurant-card" />
      <div className="overlay-card">
        <div>
          <p className="restaurant-name">{props.name}</p>
          <p className="restaurant-category">{props.category}</p>
        </div>
        <PrimaryButton
          style={{ background: "#2FA94E", borderColor: "#2FA94E" }}
          title={props.price}
          className="price-btn"
        />
      </div>
      <div className="button-div">
        <PrimaryButton title="Order Now" className="order-now-btn" />
      </div>
    </div>
  );
}
