const formatters = {
  indianCurrency: (amount) => {
    return (
      "₹ " +
      new Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 9,
      }).format(amount)
    );
  },
  poundCurrency: (amount) => {
    return (
      "£ " +
      new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 9,
      }).format(amount)
    );
  },
};

export default formatters;
