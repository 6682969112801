import React from "react";
import DataLoader from "../loaders/DataLoaders";

export default function PrimaryButton(props) {
  const buttonStyle = {
    color: "#fff",
    background: "#ffd163",
    borderColor: "#ffd163",
  };

  return (
    <button
      {...props}
      className={`btn-second ${props.className ? props.className : ""}`}
      style={{
        ...buttonStyle,
        ...props.style,
        ...(props.loading && { background: "#e5e5e5", borderColor: "#e5e5e5"}),
      }}
    >
      {props.loading ? <DataLoader /> : props.title}
    </button>
  );
}
