const apiService = {
  //Common End Points
  file_fileUpload: "file/file_upload",
  getVendorDetailsById: "restaurant/get_restaurant_by_id",
  updateCategoryPosition: "restaurant/update_position_in_category",
  updateMenuPosition: "restaurant/update_position_in_menu",
  getMenu: "restaurant/get_menu",
  getRestaurants: "restaurant/get_restaurants_by_status",
  getPartyOrdersByStatus: "customer/get_party_order_on_status",
  addDeliveryProfile: "delivery/add_delivery",
  getAllDeliveryProfiles: "delivery/get_deliveries_on_status",
  deleteDeliveryProfile: "delivery/update_delivery_status",
  updateDeliveryProfile: "delivery/update_delivery",
  addRestaurantDeliveryAgent: "delivery/add_restaurant_delivery",
  getRestaurantsDeliveryAgents: "delivery/get_restaurant_delivery_agents",
  updateQuotationStatus: "restaurant/update_partyOrder_quotation_status",
  getPartyOrderStats: "customer/get_party_order_stats",
  getPartyQuotationStats: "restaurant/get_partyOrder_quotation_stats",
  assignDelivery: "restaurant/assign_delivery",
  getOrderStatsByRestaurant:
    "customer/get_order_stats_by_status_and_restaurant",
  getRestaurantsBySearch: "restaurant/get_restaurants",
  updateOrderStatus: "restaurant/update_order_status",
  ordersAllStats: "customer/get_order_stats_by_status",
  getTagsBySearch: "admin/search_itemTag",
  getHostCategoriesandMenus:
    "restaurant/get_menus_and_items_by_restaurant_by_host",
  getAgentOrders: "restaurant/get_deliveries_by_deliveryId_and_status",
  getAgentOrdersStats: "restaurant/get_order_stats_of_delivery_person",
  getUnsettledStats: "admin/get_settled_and_unsettled_sale_amount",
  getOrdersBySettleStatus: "admin/get_list_of_orders_by_settled_status",
  settleOrders: "admin/update_orders_settled_status",
  getDelievryAgentDetails: "delivery/get_delivery_id",
  getVehicleDetails: "delivery/get_vehicle_details_by_id",
  addSettlementRequest: "restaurant/add_settledOrder",
  getRaisedSettlementRequests: "restaurant/get_settledOrders_by_status",
  //Admin End points
  adminLogin: "admin_login",
  getTotalOrdersCount: "admin/get_total_orders_and_orders_count_by_status",
  getTotalCustomersCount:
    "admin/get_total_customers_and_customers_count_by_status",
  getTotalSales: "admin/get_total_sale_count",
  getRestaurantStats:
    "admin/get_total_restaurants_and_restaurants_count_by_status",
  getDeliveryAgentsStats:
    "admin/get_total_delivery_persons_and_delivery_persons_count_by_status",
  getCustomers: "customer/get_customer_profile_by_status",
  getRestaurantSales: "admin/get_restaurants_sale_count",

  addCuisine: "restaurant/add_cuisine",
  updateCuisine: "restaurant/update_cuisine",
  deleteCuisine: "restaurant/update_cuisine_status",
  getCuisines: "restaurant/get_cuisines_by_status",
  hostStatusUpdate: "restaurant/update_restaurant_status",
  customerStatusUpdate: "customer/update_customer_status",

  vendorGetOrdersByStatus: "customer/get_orders_by_status_and_restaurant",
  adminGetOrdersByStatus: "customer/get_orders_by_status",
  getAllNotifications: "admin/get_all_notifications",
  updateNotifications: "admin/update_notification",

  getTags: "admin/get_itemTags_by_status",
  addTag: "admin/add_itemTag",
  updateTag: "admin/update_itemTag",
  deleteTag: "admin/update_itemTag_status",

  getBankList: "admin/get_banks_by_status",
  addBankList: "admin/add_bank",
  updateBankList: "admin/update_bank",
  deleteBankList: "admin/update_bank_status",

  addSocialLink: "admin/add_social_link",
  getSocialLinks: "admin/get_social_links_by_status",
  updateSocialLink: "admin/update_social_link",
  deleteSocialLink: "admin/update_social_link_status",

  settleRaisedOrders: "admin/update_raised_orders",
  getDriverSpecificOrder: "restaurant/get_delivery_specific_orders",

  //Vendor End Points//
  getSaleCountByRestaurant: "admin/get_sale_count_by_restaurant",
  vendorSignUp: "restaurant/restaurant_sign_up",
  vendorSignIn: "restaurant/restaurant_sign_in",
  getVendorDetails: "restaurant/get_restaurant_profile",
  updateVendorDetails: "restaurant/update_restaurant_details",
  vendorEmailVerify: "restaurant/email_verify",
  vendorResendOtp: "restaurant/resend_email",
  vendorForgetPassword: "restaurant/forget_password",
  vendorVerifyForgetPassword: "restaurant/verify_forget_password",

  addCategory: "restaurant/add_category",
  getCategoriesByStatus: "restaurant/get_categories_by_status",
  updateCategory: "restaurant/update_category",
  deleteCategory: "restaurant/update_category_status",

  addSubCategory: "restaurant/add_subcategory",
  updateSubCategory: "restaurant/update_subcategory",
  getSubCategoriesByStatus: "restaurant/get_subcategories_by_status",
  deleteSubCategory: "restaurant/update_subcategory_status",

  addMenu: "restaurant/add_menu",
  updateMenu: "restaurant/update_menu",
  deleteMenu: "restaurant/update_menu_status",
  updateOfferPrice: "restaurant/update_item_discount",

  addQuotation: "restaurant/add_partyOrder_quotation",
  getQuotationsOnStatus:
    "restaurant/get_partyOrder_Quotations_by_restaurant_and_status",
  deliveredPartyOrderQuotation: "restaurant/update_partyOrder_quotation_status",

  addRestaurantBankInfo: "restaurant/add_restaurant_bank_info",
  getAllBankDetails: "restaurant/get_restaurant_bank_infos_by_status",
  deleteBankDetails: "restaurant/update_restaurant_bank_info_status",
  updateBankDetails: "restaurant/update_restaurant_bank_info",
  getAllRestaurantNotifications:
    "restaurant/get_restaurant_specific_notifications",

  //Customer End Points
  customerSignup: "customer/add_customer",
  customerLogin: "customer/customer_signin",
  customerEmailVerify: "customer/email_verify",
  customerResendOtp: "customer/resend_email",
  customerForgetPassword: "customer/forget_password",
  customerVerifyForgetPassword: "customer/verify_forget_password",
  customerGetProfile: "customer/get_customer_profile",
  customerUpdateProfile: "customer/update_customer_profile",

  customerGetRestaurants: "restaurant/get_restaurants_list",
  customerGetCuisines: "restaurant/get_cuisines_by_status",

  customerCreateOrderPaymentIntent: "customer/create_payment_intent",
  customerAddOrder: "customer/add_order",
  customerGetOrder: "customer/get_order",
  customerUpdateOrder: "customer/update_order",
  customerGetOrdersByStatus: "customer/get_orders_by_status_and_customer",

  customerAddAddress: "customer/add_address",
  customerGetAddresses: "customer/get_addresses_by_status",
  customerDeleteAddress: "customer/update_address_status",
  customerUpdateAddress: "customer/update_address",

  addCart: "customer/add_cart",
  getCart: "customer/get_cart",
  deleteCart: "customer/delete_cart",
  decreaseCartCount: "customer/decrease_cart",
  clearCart: "customer/clear_cart",
  updateCartAddress: "customer/update_cart_address",
  updateCartDetails: "cart/update_cart-details",
  reOrderItems: "customer/add_cart_items",

  addPartyOrder: "customer/place_party_order",
  updatePartyOrderStatus: "customer/update_party_order_status",
  updatePartyOrder: "customer/update_placed_party_order",
  getPartyOrder: "customer/get_placed_party_order",
  getNotifications: "customer/get_customer_specific_notifications",

  getFavourites: "customer/get_favourites_by_status",
  addFavourite: "customer/add_favourite",
  updateFavouriteStatus: "customer/update_favourite_status",
  customerAddRatings: "customer/add_rating",
  customerUpdateRatings: "customer/update_rating",
  getRatingsByRestaurant: "customer/get_ratings_by_restaurant",
  customerDeliveryAddRating: "customer/add_delivery_rating",
  customerDeliveryUpdateRating: "customer/update_delivery_rating",
  getCategoriesandMenus: "restaurant/get_menus_and_items_by_restaurant",

  getSocials: "admin/get_active_social_links",
};

export default apiService;
