import React from "react";
import { AdminInput } from "../assets/elements/formElements";
import ContactForm from "./common/details/contactForm/ContactForm";
import { Formik } from "formik";
import validate from "../validations";
import { PrimaryButton } from "../assets/elements/buttons";
import { HostInfo } from "./common/details";
import UploadInput from "../assets/elements/formElements/uploadInput/UploadInput";
import DeliveryForm from "./common/details/DeliveryForm";
import OrderForm from "./common/details/OrderForm";
import Commission from "./common/details/Commission";
import Payments from "./common/details/Payments";
import Tags from "./common/details/Tags";
import Promotions from "./common/details/Promotions";
import BankForm from "./common/details/BankInfo";

export default function DummyComponent() {
  return (
    <div>
      {/* Contact Form Starts */}
      <div className="contact-from">
        <h1>Contact Form</h1>
        <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <ContactForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
              <PrimaryButton
                title="Submit"
                style={{
                  borderColor: "#FF5C00",
                  background: "#FF5C00",
                  borderRadius: "5px",
                  padding: "15px 50px",
                  margin: "30px",
                }}
                type="submit"
                onClick={handleSubmit}
              />
            </>
          )}
        </Formik>
      </div>
      {/* Contact Form End */}

      <AdminInput />
      <div style={{ margin: "30px 0" }}>
        <UploadInput />
      </div>

<h1>Host Info Form</h1>
      <div className="host-info">
        <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <HostInfo
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      </div>
      <h1>Delivery form</h1>
      <div>
      <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <DeliveryForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      </div>
      <h1>Order Info</h1>
      <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <OrderForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
        <h1>Commission</h1>
      <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <Commission
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <Payments
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <Tags
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <Promotions
             
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
        <h1>Bank Info</h1>
        <Formik
          initialValues={{
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            alternatePhone: "",
            alternateEmail: "",
          }}
          validationSchema={validate.hostContactForm}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <BankForm
             
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
    </div>
  );
}
