import storeProvider from "../store/StoreProvider";
import * as fetchServices from "./FetchServices";
import apiService from "./ApiServices";

const AdminServices = {
  adminLogin: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.adminLogin;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },

  getCustomers: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomers;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  addCuisine: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCuisine + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  updateCuisine: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateCuisine +
      "?resource=" +
      details.resource +
      "&cuisine_id=" +
      details.cuisine_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  deleteCuisine: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateCuisine +
      "?resource=" +
      details.resource +
      "&cuisine_id=" +
      details.cuisine_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  hostStatusUpdate: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.hostStatusUpdate +
      "?resource=" +
      details.resource +
      "&restaurant_id=" +
      details.restaurant_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerStatusUpdate: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerStatusUpdate +
      "?resource=" +
      details.resource +
      "&customer_id=" +
      details.customer_id;
    let type = "PUT";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  adminGetOrdersByStatus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.adminGetOrdersByStatus;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getTotalOrdersCount: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getTotalOrdersCount;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getTotalCustomersCount: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getTotalCustomersCount;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getTotalSales: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getTotalSales;
    let type = "GET";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getRestaurantStats: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getRestaurantStats;
    let type = "GET";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getDeliveryAgentsStats: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getDeliveryAgentsStats;
    let type = "GET";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getDriverSpecificOrder: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getDriverSpecificOrder;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getAllNotifications: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getAllNotifications;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  updateNotifications: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateNotifications +
      "?resource=" +
      details.resource +
      "&notification_id=" +
      details.notification_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  addTag: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addTag + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  updateTag: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateTag +
      "?resource=" +
      details.resource +
      "&itemTag_id=" +
      details.itemTag_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  deleteTag: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deleteTag +
      "?resource=" +
      details.resource +
      "&itemTag_id=" +
      details.itemTag_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getTags: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getTags;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  getRestaurantSales: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getRestaurantSales;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  getBankList: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getBankList;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  addBankList: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addBankList;
    let type = "POST";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  updateBankList: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateBankList +
      "?resource=" +
      details.resource +
      "&bank_id=" +
      details.bank_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  deleteBankList: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deleteBankList +
      "?resource=" +
      details.resource +
      "&status=" +
      details.status +
      "&bank_id=" +
      details.bank_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  addSocialLink: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addSocialLink + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  getSocialLinks: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getSocialLinks;
    let payload = {
      ...details,
    };
    let type = "GET";

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  updateSocialLink: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateSocialLink +
      "?resource=" +
      details.resource +
      "&socialLink_id=" +
      details.socialLink_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  deleteSocialLink: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deleteSocialLink +
      "?resource=" +
      details.resource +
      "&socialLink_id=" +
      details.socialLink_id +
      "&status=" +
      details.status;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
};
export default AdminServices;
