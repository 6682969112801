import React from "react";
import "./StatsCard.scss";

export default function StatsCard(props) {
  const count = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 7,
  }).format(props.count);
  return (
    <div  {...props} className={`stats-card-main ${props?.className || ""} `} >
      <div className="stats-count">
        <p className="count">
          {props.type === "Sales" && <span>&#8377;</span>} {count}
        </p>
        <span className="stats-card-icon">
          <img src={props.icon} alt='icon'/>
        </span>
      </div>
      <p className="stat-type">{props.type}</p>
      <p className="stat-text">{props.statText}</p>
    </div>
  );
}
