import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "./ContactForm.scss";
import validate from "../../../../validations";
import { Form, FormGroup, FormLabel } from "react-bootstrap";
import Input from "../../../../assets/elements/formElements/Input";
import { PrimaryButton } from "../../../../assets/elements/buttons";
import showicon from "../../../../assets/img/eye-slash.svg";
import hideicon from "../../../../assets/img/eye-open.svg";


export default function ContactForm(props) {
  const navigate = useNavigate();
  const [passwordHidden, setPasswordHidden] = useState(true);
  return (
    <div className="contact-form-main">
      <Form onSubmit={props.handleSubmit}>
        <div>
          <FormGroup>
            <FormLabel>Contact Name*</FormLabel>
            <Input
              type="text"
              name="contactName"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.contactName}
              placeholder="Enter Contact Name"
              className="customer-name-input"
            />
            {validate.displayError(props.touched, props.errors, "contactName")}
          </FormGroup>

          <div className="form-control-flex-div">
            <FormGroup className="login-input-group">
              <FormLabel className="password-label">
                <span>Contact Phone*</span>{" "}
              </FormLabel>
              <Input
                type="tel"
                name="contactPhone"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.contactPhone}
                placeholder="Enter Mobile Number"
                className="login-input"
                disabled={props.isUpdate}
              />
              <p
                className="m-0"
                style={{ color: "grey", fontSize: "12px", fontStyle: "italic" }}
              >
                Your Mobile Number will (need to) be verified
              </p>
              {validate.displayError(
                props.touched,
                props.errors,
                "contactPhone"
              )}
            </FormGroup>
            <FormGroup className="login-input-group">
              <FormLabel className="password-label">
                <span>Alternate Phone</span>{" "}
              </FormLabel>
              <Input
                type="tel"
                name="alternatePhone"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.alternatePhone}
                placeholder="Enter Alternate Mobile "
                className="login-input"
              />
              {validate.displayError(
                props.touched,
                props.errors,
                "alternatePhone"
              )}
            </FormGroup>
            <FormGroup className="login-input-group">
              <FormLabel className="password-label">
                <span>Contact Email*</span>{" "}
              </FormLabel>
              <Input
                type="email"
                name="contactEmail"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.contactEmail}
                placeholder="Enter Email Address"
                className="login-input"
                disabled={props.isUpdate}
              />
              {validate.displayError(
                props.touched,
                props.errors,
                "contactEmail"
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel className="password-label">
                <span>Contact Address*</span>{" "}
              </FormLabel>
              <Input
                as="textarea"
                name="contactAddress"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.contactAddress}
                placeholder="Enter Full Address"
                className="login-input"
                style={{ height: "150px" }}
              />
              {validate.displayError(
                props.touched,
                props.errors,
                "contactAddress"
              )}
            </FormGroup>
            {props.isPasswordForm ? (
              <FormGroup className="login-input-group">
                <FormLabel className="password-label">
                  <span>Password*</span>{" "}
                </FormLabel>
                <div className="password-input-container">
                  <Input
                    type={passwordHidden ? "password" : "text"}
                    name="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.password}
                    placeholder="Enter Password "
                    className="login-input"
                  />
                  <img
                    className="password-icon"
                    onClick={() => setPasswordHidden(!passwordHidden)}
                    src={passwordHidden ? showicon : hideicon}
                    alt="show"
                  />
                </div>

                {validate.displayError(props.touched, props.errors, "password")}
              </FormGroup>
            ) : (
              <>
                <FormGroup className="login-input-group">
                  <FormLabel className="password-label">
                    <span>Alternate Email</span>{" "}
                  </FormLabel>
                  <Input
                    type="email"
                    name="alternateEmail"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.alternateEmail}
                    placeholder="Enter Alternate Email "
                    className="login-input"
                  />
                  {validate.displayError(
                    props.touched,
                    props.errors,
                    "alternateEmail"
                  )}
                </FormGroup>
              </>
            )}

            <div className="bottom-btns">
              <PrimaryButton
                title="Submit"
                loading={props.isSignupLoading}
                style={{
                  borderColor: "var(--theme-primary-color)",
                  background: "var(--theme-primary-color)",
                  borderRadius: "5px",
                  padding: "15px 50px",
                  margin: "10px 5px 0",
                }}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
              />
            </div>
            <div className="form-group text-center">
              <p className="text-light-black mb-0 mt-2">
               Already have an account?{" "}
                <a
                onClick={() => navigate("/")}
                  style={{
                    
                    color: "var(--customer-theme-color)",
                    cursor: "pointer",
                  }}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
