import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { GoogleMapsAPI } from "./apiKey";
import "./Map.scss";

Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

function DeliveryMap(props) {
  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const [mapPosition, setMapPosition] = useState({
    lat: 20.5937,
    lng: 78.9629,
  });
  const [waypoints, setWaypoints] = useState([]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleMapsAPI,
  });
  useEffect(() => {
    if (props.restoCoords) {
      setMapPosition(props.restoCoords);
    }
    if (props.coordinates && props.coordinates.length > 0) {
      calculateWaypoints(props.restoCoords, props.coordinates[0]);
    }
  }, [props.coordinates, props.restoCoords]);

  const calculateWaypoints = (origin, destination) => {
    const directionsService = new window.google.maps.DirectionsService();
    const request = {
      origin,
      destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setWaypoints(
          result.routes[0].overview_path.map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
          }))
        );
      }
    });
  };

  return isLoaded ? (
    <div className="map-container">
      <div
        constainer
        direction={"column"}
        style={{ display: "flex", height: "100%", width: "100%" }}
        spacing={2}
      >
        <GoogleMap
          mapContainerStyle={{
            height: props.height ? props.height : "450px",
            width: "100%",
          }}
          options={options}
          zoom={props?.zoom || 5}
          center={{
            lat: mapPosition.lat,
            lng: mapPosition.lng,
          }}
        >
          {waypoints.length > 0 && (
            <Polyline path={waypoints} options={{ strokeColor: "#FF0000" }} />
          )}
          {props.coordinates &&
            props.coordinates.length > 0 &&
            props.coordinates.map((eachMarker) => (
              <Marker draggable={!props.display} position={eachMarker} />
            ))}
        </GoogleMap>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default DeliveryMap;
