import storeProvider from "../store/StoreProvider";
import * as fetchServices from "./FetchServices";
import apiService from "./ApiServices";
import axios from "axios";

const CommonServices = {
  //Upload document API
  fileUpload: function (file) {
    const api = storeProvider.getApi();
    let API = api + apiService.file_fileUpload;
    var bodyFormData = new FormData();
    let fieldNames = [];
    file &&
      Object.keys(file).map((key) => {
        fieldNames.push(file[key].field);
        bodyFormData.append("files", file[key]);
        return true;
      });
    if (fieldNames && fieldNames.length > 0) {
      bodyFormData.append("fileLabels", JSON.stringify(fieldNames));
    }
    return axios({
      method: "POST",
      url: API,
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      data: bodyFormData,
    })
      .then(function (response) {
        var resData = response.data;
        if (!resData.success) {
          const message = resData.msg;
          const errorRes = { status: false, msg: message };
          return errorRes;
        }
        const apiResponse = resData.data;
        return { status: true, data: apiResponse };
      })
      .catch(function (res) {
        console.log(res);
        console.log("An error occurred in File Upload");
      });
  },
  getVendorDetailsById: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getVendorDetailsById;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  getCuisines: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCuisines;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  updateCategoryPosition: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.updateCategoryPosition;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  updateMenuPosition: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.updateMenuPosition;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  getMenu: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getMenu;
    let type = "GET";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getRestaurants: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getRestaurants;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getPartyOrdersByStatus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getPartyOrdersByStatus;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  addDeliveryProfile: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.addDeliveryProfile + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  addRestaurantDeliveryAgent: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.addRestaurantDeliveryAgent +
      "?resource=" +
      details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getAllDeliveryProfiles: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getAllDeliveryProfiles;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getRestaurantsDeliveryAgents: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getRestaurantsDeliveryAgents;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  deleteDeliveryProfile: function (details) {
    const api = storeProvider.getApi();
    let type = "PUT";
    let API =
      api +
      apiService.deleteDeliveryProfile +
      "?resource=" +
      details.resource +
      "&status=" +
      details.status +
      "&delivery_id=" +
      details.delivery_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  updateDeliveryProfile: function (details) {
    const api = storeProvider.getApi();
    let type = "PUT";
    let API =
      api + apiService.updateDeliveryProfile + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  updateQuotationStatus: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateQuotationStatus +
      "?resource=" +
      details.resource +
      "&partyOrderQuotation_id=" +
      details.partyOrderQuotation_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getPartyOrderStats: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getPartyOrderStats;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getPartyQuotationStats: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getPartyQuotationStats;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  assignDelivery: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.assignDelivery +
      "?resource=" +
      details.resource +
      "&order_id=" +
      details.order_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getOrderStatsByRestaurant: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getOrderStatsByRestaurant;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getRestaurantsBySearch: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getRestaurantsBySearch;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  updateOrderStatus: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateOrderStatus +
      "?order_id=" +
      details.order_id +
      "&resource=" +
      details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  ordersAllStats: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.ordersAllStats;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getTagsBySearch: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getTagsBySearch;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  getHostCategoriesandMenus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getHostCategoriesandMenus;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getAgentOrders: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getAgentOrders;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getAgentOrdersStats: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getAgentOrdersStats;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getUnsettledStats: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getUnsettledStats;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getOrdersBySettleStatus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getOrdersBySettleStatus;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  settleOrders: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.settleOrders +
      "?resource=" +
      details.resource +
      "&order_id=" +
      details.order_id;

    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getDelievryAgentDetails: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getDelievryAgentDetails;

    let payload = {
      ...details,
    };
    let type = "GET";
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getVehicleDetails: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getVehicleDetails;

    let payload = {
      ...details,
    };
    let type = "GET";
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getRaisedSettlementRequests: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getRaisedSettlementRequests;

    let payload = {
      ...details,
    };
    let type = "GET";
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  settleRaisedOrders: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.settleRaisedOrders +
      "?resource=" +
      details.resource +
      "&settledOrder_id=" +
      details.settledOrder_id;

    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
};
export default CommonServices;
