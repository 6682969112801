import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import * as Storage from "../../services/LocalStorage";
import { AuthenticateAction } from "../../redux/Auth";

import Dashboard from "../admin/dashboard";
import AdminBase from "../../layout/base/adminBase/AdminBase";
import Login from "../admin/login";
import Customers from "../admin/customers";
import Orders from "../admin/orders/Orders";
import Hosts from "../admin/hosts";
import Menu from "../admin/menu";
import Delivery from "../admin/delivery/Delivery";
import DriverSpecificOrders from "../admin/driverSpecificOrders";

import DummyComponent from "../DummyComponent";
import Profile from "../common/profile";
import Cuisine from "../admin/cuisine";
import BankList from "../admin/bankList";
import PartyOrders from "../admin/partyOrders/PartyOrders";
import OrderInvoice from "../common/orderInvoice";
import Tags from "../admin/tags";
import SocialLinks from "../admin/socialLinks";
import AssignedOrders from "../admin/deliveryAgentOrders/DeliveryAgentOrders";
import Finance from "../admin/finance/Finance";
import DeliveryProfile from "../common/deliveryProfile";

export default function ApplicationsAdminRoutes(props) {
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.config);
  const emailId = useSelector((state) => state.auth.emailId);
  const { pathname } = useLocation();
  const nonHeaderPages = ["/", "/login", "/dummy"];

  console.log(useSelector((state) => state.loginModal.loginToggle));

  useEffect(() => {
    checkUserSession();
    // eslint-disable-next-line
  }, []);

  const checkUserSession = () => {
    let session = Storage.get(config.sessionKey);
    let authData = session ? JSON.parse(session) : {};
    let response = Storage.checkUserSession(authData);
    if (response.status) {
      let authData = response.data;

      dispatch(AuthenticateAction(authData));
    } else {
    }
  };
  return !emailId && nonHeaderPages.includes(pathname) ? (
    <Routes>
      <Route path="/login" element={<Login />} />;
      <Route path="/dummy" element={<DummyComponent />} />
      {/* redirect */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  ) : emailId ? (
    <AdminBase>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />;
        <Route
          path="/order-invoice-details/:orderId"
          element={<OrderInvoice />}
        />
        ;
        <Route path="/customers" element={<Customers />} />;
        <Route path="/orders" element={<Orders />} />;
        <Route path="/hosts" element={<Hosts />} />;
        <Route path="/host-info/:hostId" element={<Profile />} />;
        <Route path="/menu/:restaurantId" element={<Menu />} />;
        <Route path="/delivery-agent/:deliveryId" element={<DeliveryProfile />} />;
        <Route path="/party-orders" element={<PartyOrders />} />;
        <Route path="/delivery" element={<Delivery />} />;
        <Route path="/driver-orders/:deliveryId" element={<DriverSpecificOrders/>} />;
        <Route path="/cuisine" element={<Cuisine />} />;
        <Route path="/bank-list" element={<BankList />} />;
        <Route path="/tags" element={<Tags />} />;
        <Route path="/assigned_orders" element={<AssignedOrders />} />;
        <Route path="/social-links" element={<SocialLinks />} />;
        <Route
          path="/finance/:restaurantId/:status/:fromDate/:toDate"
          element={<Finance />}
        />
        <Route
          path="/finance"
          element={<Finance />}
        />
        ;
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </AdminBase>
  ) : (
    <Routes>
      <Route path="/login" element={<Login />} />;
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
