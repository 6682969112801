import { createAction } from "redux-actions";

const LoginModalClose = "PRODUCT_MODAL_CLOSE";
const LoginModalOpen = "PRODUCT_MODAL_OPEN";
const URLRedirect = "URL_REDIRECT";

// actions
export const LoginModalCloseAction = createAction(LoginModalClose);
export const LoginModalOpenAction = createAction(LoginModalOpen);
export const URLRedirectAction = createAction(URLRedirect);

// reducers
export const LoginModalCloseReducer = (state, action) => {
  return { ...state, loginToggle: false };
};

export const LoginModalOpenReducer = (state, action) => {
  return { ...state, loginToggle: true };
};

export const URLRedirectReducer = (state, action) => {
  return { ...state, urlLink: action.payload };
};

const initialState = {
  loginToggle: false,
  urlLink: "",
};

const loginReducers = (state = initialState, action) => {
  switch (action.type) {
    case LoginModalOpen:
      return LoginModalOpenReducer(state, action);

    case LoginModalClose:
      return LoginModalCloseReducer(state, action);

    case URLRedirect:
      return URLRedirectReducer(state, action);

    default:
      return state;
  }
};
export default loginReducers;
