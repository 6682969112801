import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import counterpart from 'counterpart';

import CustomerRoutes from "./routes/CustomerRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import VendorRoutes from "./routes/VendorRoutes";
import { ConfigAction } from "./redux/Config";


import en from "./languages/en";
import ar from "./languages/ar";

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ar', ar);

class Main extends Component {
  constructor() {
    super();
    this.state = { appconfig: null, readConfigureDone: false };
  }

  componentWillMount() {
    this.getConfig();
    counterpart.setLocale('en')
  }

  getConfig = () => {
    var thisView = this;
    axios
      .get("config/appConfig.json")
      .then(function (res) {
        var appconfig = res.data;
        thisView.props.updatingConfig(appconfig);
        thisView.setState({ appconfig, readConfigureDone: true });
      })
      .catch(function (res) {
        console.log("An error occurred config");
      });
  };

  getRoute = (route) => {
    if (route === "admin") return <AdminRoutes />;
    else if (route === "vendor") return <VendorRoutes />;
    else return <CustomerRoutes />;
  };

  render() {
    return this.state.readConfigureDone && this.state.appconfig ? (
      <div>
        <div>{this.getRoute(this.state.appconfig.source)}</div>
      </div>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.config.config,
});

const mapDispatchToProps = (dispatch) => ({
  updatingConfig(data) {
    dispatch(ConfigAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
