import React from "react";
import "./OrderForm.scss";

import validate from "../../../validations";
import { FormGroup, FormLabel, Form } from "react-bootstrap";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";

export default function OrderForm(props) {
  return (
    <div className="order-info-main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Email Order?</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="emailOrder"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="emailOrder"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
        {validate.displayError(props.touched, props.errors, "emailOrder")}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <p className="radio-btns-header">Sms Option</p>
        <div className="radio-btn-group">
          <Form.Check
            inline
            label="Yes"
            name="sms"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="Yes"
          />
          <Form.Check
            inline
            label="No"
            name="sms"
            type="radio"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value="No"
          />
        </div>
        {validate.displayError(props.touched, props.errors, "sms")}
      </div>
      <FormGroup className="login-input-group">
        <FormLabel>Mobile Number*</FormLabel>
        <Input
          type="tel"
          name="mNumber"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.mNumber}
          placeholder="Enter Mobile Number"
          className="customer-name-input"
        />
        {validate.displayError(props.touched, props.errors, "mNumber")}
      </FormGroup>
    </div>
  );
}
