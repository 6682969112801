import React from "react";
import "./TimeSlider.scss";

import TimeRangeSlider from "react-time-range-slider";

export default function TimeSilder(props) {
  return (
    <div className={`time-slider-main ${props?.className || ""}`}>
      <div className="time-display-block">
        <span className="time-value-text">
          {props.value.start} - {props.value.end}
        </span>
      </div>
      <TimeRangeSlider
       maxValue={"23:59"}
       minValue={"00:00"}
       disabled={false}
       format={24}
       name={"time_range"}
       step={15}
        {...props}
      
      />
    </div>
  );
}
