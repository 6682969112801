import React from "react";
import { FormSelect } from "react-bootstrap";
import './CustomSelect.scss'

export default function CustomSelect(props) {
  return (
    <FormSelect {...props} className={`form-control-select ${props?.className|| ""}`}>
      <option>{props.placeholder ? props.placeholder : "Select"}</option>
      {props.options &&
        props.options.length > 0 &&
        props.options.map((eachOption) => (
          <option value={eachOption.value}>{eachOption.label}</option>
        ))}
    </FormSelect>
  );
}
