import { Helmet } from "react-helmet";

const Head = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Food Web - {props.title}</title>
    </Helmet>
  );
};
export default Head;
