import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  FormLabel,
  FormGroup,
} from "react-bootstrap";
import { Formik } from "formik";
import "./PartyOrders.scss";

import { OrderCard } from "../../../assets/elements/cards";
import PageLoader from "../../../assets/elements/loaders/PageLoader";
import { PrimaryButton } from "../../../assets/elements/buttons";
import CommonServices from "../../../services/CommonServices";
import validate from "../../../validations";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";
import VendorServices from "../../../services/Vendorservices";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import moment from "moment";
import formatters from "../../../assets/numberFormatter";
import AsyncSelect from "react-select/async";
import { debounce, each, set } from "lodash";
import Head from "../../../assets/elements/Head";

export default function PartyOrders() {
  const [pageLoading, setPageLoading] = useState(false);
  const [partyOrders, setPartyOrders] = useState([]);
  const [quotationModal, setQuotationModal] = useState(false);
  const [quotationLoading, setQuotationLoading] = useState(false);
  const [selectedPartyOrder, setSelectedPartyOrder] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("ACTIVE");
  const [partyOrderStats, setPartyOrderStats] = useState({
    DELIVERED: 0,
    PENDING: 0,
    ACCEPTED: 0,
  });
  const [openStats, setOpenStats] = useState({ ACTIVE: 0 });
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [partyOrderTotal, setPartyOrderTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [pricings, setPricings] = useState([
    {
      id: 1,
      priceType: "",
      amount: "",
    },
  ]);

  const orderTimeline = [
    { orderStatus: "Placed", time: "2:00 PM" },
    { orderStatus: "Accepted", time: "2:03 PM" },
  ];
  const userId = useSelector((state) => state.auth.userId);
  const config = useSelector((state) => state.config.config);

  let partyOrderInterval = useRef(null);
  useEffect(() => {
    getRestaurantPartyOrders(selectedStatus, "reload");

    if (config.source === "admin") {
      getRestaurants("reload", "");
      getPartyOrderStats("");
    }
    if (config.source === "vendor") {
      getPartyOrderStats(userId);
      setRestaurantId(userId);
    }
    getOpenPartyOrderStats();
    // partyOrderInterval.current = setInterval(
    //   getPartyOrderOnIntervals,
    //   10000,
    //   selectedStatus,
    //   1
    // );
    // return () => {
    //   clearInterval(partyOrderInterval.current);
    // };
  }, []);

  //functions
  const onQuotationModal = (selectedPartyOrder) => {
    if (selectedPartyOrder) {
      setSelectedPartyOrder(selectedPartyOrder);
    } else {
      setSelectedPartyOrder("");
    }
    setPricings([
      {
        id: 1,
        priceType: "",
        amount: "",
      },
    ]);
    setQuotationModal(!quotationModal);
  };

  const onStatusChange = (status) => {
    if (!restaurantId || status === "ACTIVE") {
      getRestaurantPartyOrders(status, "reload", 1);
    } else {
      getQuotationsOnStatus(status, "reload", restaurantId, 1);
    }
    setPage(1);
    setSelectedStatus(status);
    // clearInterval(partyOrderInterval.current);
    // partyOrderInterval.current = setInterval(
    //   getPartyOrderOnIntervals,
    //   10000,
    //   status,
    //   restaurantId,
    //   1
    // );
  };

  // const getPartyOrderOnIntervals = (status, restaurantId) => {
  //   if (!restaurantId || status === "ACTIVE") {
  //     getRestaurantPartyOrders(status, "refresh");
  //   } else {
  //     getQuotationsOnStatus(status, "refresh", restaurantId);
  //   }
  // };

  const onRestaurantChange = (selected) => {
    setSelectedRestaurant(selected);
    setRestaurantId(selected.value);
    getPartyOrderStats(selected.value);
    getQuotationsOnStatus(selectedStatus, "refresh", selected.value, 1);
    setPage(1);
    // clearInterval(partyOrderInterval.current);
    // partyOrderInterval.current = setInterval(
    //   getPartyOrderOnIntervals,
    //   10000,
    //   selectedStatus,
    //   selected.value,
    //   1
    // );
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue(inputValue);
    return inputValue;
  };

  const onRestaurantsOptions = useCallback(
    debounce((inputText, callback) => {
      getRestaurants("refresh", inputText, callback);
    }, 1000),
    []
  );

  const onStatusModal = (selected) => {
    console.log(selected);
    if (selected) setSelectedPartyOrder(selected);
    else setSelectedPartyOrder("");
    setUpdateStatusModal(!updateStatusModal);
  };

  const onLoadMore = () => {
    if (!restaurantId || selectedStatus === "ACTIVE") {
      getRestaurantPartyOrders(selectedStatus, "refresh", page + 1);
    } else {
      getQuotationsOnStatus(selectedStatus, "refresh", restaurantId, page + 1);
    }
    setPage(page + 1);
  };

  const onPriceTypeChange = (e, setFieldValue, name, id) => {
    let updatedPricings = pricings.map((eachPricing) => {
      if (eachPricing.id === id) {
        return {
          ...eachPricing,
          [name]: e.target.value,
        };
      } else {
        return eachPricing;
      }
    });
    setPricings(updatedPricings);
    setFieldValue("pricings", updatedPricings);
  };

  const onAddPriceType = (setFieldValue) => {
    let updatedPricings = pricings;
    let temp = {
      priceType: "",
      amount: "",
      id: Math.random() * 100,
    };
    updatedPricings.push(temp);
    setPricings(updatedPricings);
    setFieldValue("pricings", updatedPricings);
  };

  const onRemovePriceType = (eachPricing, setFieldValue) => {
    let updatedPricings = pricings.filter(
      (pricing) => pricing.id !== eachPricing.id
    );
    setPricings(updatedPricings);
    setFieldValue("pricings", updatedPricings);
  };

  //Apis
  const getRestaurantPartyOrders = (status, reload, page) => {
    reload === "reload" ? setPageLoading(true) : setInfiniteLoading(true);
    const details = {
      resource: "party_order",
      status: status,
      page,
      limit: 10,
    };
    axios
      .all([CommonServices.getPartyOrdersByStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let ordersData = resData.data ? resData.data : {};
            let partyOrderTotal = ordersData.total ? ordersData.total : 0;
            setPartyOrderTotal(partyOrderTotal);
            ordersData = ordersData.data ? ordersData.data : [];
            ordersData = ordersData.map((eachOrder) => {
              let matched = false;
              let quotationStatus = "";
              eachOrder.quotations &&
                eachOrder.quotations.map((eachQuo) => {
                  if (eachQuo.restaurant === userId) {
                    matched = true;
                    quotationStatus = eachQuo.status;
                  }
                });
              return {
                ...eachOrder,
                matched,
                quotationStatus,
              };
            });
            reload === "reload"
              ? setPartyOrders(ordersData)
              : setPartyOrders([...partyOrders, ordersData]);
            setInfiniteLoading(false);
            setPageLoading(false);
          } else {
            setPageLoading(false);
            setInfiniteLoading(true);
          }
        } else {
          setPageLoading(false);
          setInfiniteLoading(true);
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching orders");
      });
  };

  const getQuotationsOnStatus = (status, reload, restaurantId, page) => {
    reload === "reload" ? setPageLoading(true) : setInfiniteLoading(true);
    const details = {
      resource: "party_order_quotation",
      status: status,
      restaurant_id: restaurantId,
      page,
      limit: 10,
    };
    axios
      .all([VendorServices.getQuotationsOnStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let ordersData = resData.data ? resData.data : {};
            let partyOrderTotal = ordersData.total ? ordersData.total : 0;
            setPartyOrderTotal(partyOrderTotal);
            ordersData = ordersData.data ? ordersData.data : [];
            ordersData = ordersData.map((eachOrder) => {
              let matched = false;
              eachOrder.quotations &&
                eachOrder.quotations.map((eachQuo) => {
                  if (eachQuo.restaurant === userId) {
                    matched = true;
                  }
                });
              return {
                ...eachOrder,
                ...eachOrder.partyOrder,
                quotationId: eachOrder._id,
                matched,
                quotationStatus: eachOrder.status,
              };
            });
            reload === "reload"
              ? setPartyOrders(ordersData)
              : setPartyOrders([...partyOrders, ordersData]);
            setInfiniteLoading(false);
            setPageLoading(false);
          } else {
            setInfiniteLoading(false);
            setPageLoading(false);
          }
        } else {
          setInfiniteLoading(false);
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching orders");
      });
  };

  const addQuotation = (values) => {
    setQuotationLoading(true);
    let amount = 0;
    let pricings =
      values.pricings && values.pricings.length > 0
        ? values.pricings.map((eachPrice) => {
            amount += +eachPrice.amount;
            return {
              amount: eachPrice.amount,
              type: eachPrice.priceType,
            };
          })
        : [];
    const details = {
      resource: "party_order_quotation",
      partyOrder: selectedPartyOrder._id,
      restaurant: userId,
      amount,
      description: values.description,
      details: JSON.stringify(pricings),
    };
    axios
      .all([VendorServices.addQuotation(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Quotation added Successfully");
            setQuotationLoading(false);
            onQuotationModal("");
            getRestaurantPartyOrders(selectedStatus, "refresh", 1);
            getPartyOrderStats(restaurantId);
            getOpenPartyOrderStats();
          } else {
            setQuotationLoading(false);
            toast.error(message);
          }
        } else {
          setQuotationLoading(false);
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching orders");
      });
  };

  const getPartyOrderStats = (restaurantId) => {
    const qDetails = {
      resource: "party_order_quotation",
      restaurant_id: restaurantId,
    };
    axios
      .all([CommonServices.getPartyQuotationStats(qDetails)])
      .then(function (res) {
        if (res[0]) {
          let orderResData = res[0];
          if (orderResData.success) {
            let orderStats = orderResData.data ? orderResData.data : {};
            orderStats = orderStats.data ? orderStats.data : [];
            let stats = { DELIVERED: 0, PENDING: 0, ACCEPTED: 0, ACTIVE: 0 };
            orderStats.map((eachstat) => {
              stats[eachstat._id] = eachstat.count;
            });

            setPartyOrderStats({ ...stats });
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching party order stats");
      });
  };

  const getOpenPartyOrderStats = () => {
    const qDetails = {
      resource: "party_order",
    };
    axios
      .all([CommonServices.getPartyOrderStats(qDetails)])
      .then(function (res) {
        if (res[0] || res[1]) {
          let orderResData = res[0];
          if (orderResData.success) {
            let orderStats = orderResData.data ? orderResData.data : {};
            orderStats = orderStats.data ? orderStats.data : [];
            let stats = { DELIVERED: 0, PENDING: 0, ACCEPTED: 0, ACTIVE: 0 };
            orderStats.map((eachstat) => {
              stats[eachstat._id] = eachstat.count;
            });

            setOpenStats({ ...stats });
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching party order stats");
      });
  };

  const getRestaurants = (reload, term, callback) => {
    const details = {
      status: "APPROVED",
      resource: "restaurant",
      search_string: term,
    };
    axios
      .all([CommonServices.getRestaurantsBySearch(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let restaurants = resData.data ? resData.data : {};
            restaurants = restaurants.data ? restaurants.data : [];
            restaurants = restaurants.map((eachRes) => {
              return {
                ...eachRes,
                label: eachRes.store_name,
                value: eachRes._id,
              };
            });
            if (callback) {
              callback(restaurants);
            }
            if (restaurants.length > 0 && reload === "reload") {
              setDefaultOptions(restaurants);
            }
            setRestaurants(restaurants);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Restaurants");
      });
  };

  const deliveredPartyOrderQuotation = (values) => {
    setQuotationLoading(true);
    const details = {
      resource: "party_order_quotation",
      partyOrderQuotation_id: selectedPartyOrder.quotationId,
      status: "DELIVERED",
    };
    axios
      .all([VendorServices.deliveredPartyOrderQuotation(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success(
              "party order status changed to delivered Successfully"
            );
            getQuotationsOnStatus(selectedStatus, "reload", restaurantId, 1);
            getPartyOrderStats(restaurantId);
            onStatusModal("");
            setQuotationLoading(false);
          } else {
            setQuotationLoading(false);
            toast.error(message);
          }
        } else {
          setQuotationLoading(false);
        }
      })
      .catch(function (res) {
        if (res)
          console.log("Error occured in changing party order delivered status");
      });
  };

  return (
    <div className="orders-main-div">
      <Head title="Party Orders" />
      <div className="order-card-flex">
        <OrderCard
          orderCardtext="Open"
          orderSubcardContent={openStats.ACTIVE}
          style={{
            marginRight: "40px",
            cursor: "pointer",
            ...(selectedStatus === "ACTIVE" && {
              borderColor: "var(--theme-primary-color)",
              color: "var(--theme-primary-color)",
            }),
          }}
          onClick={() => onStatusChange("ACTIVE")}
        />

        {config.source === "admin" && (
          <AsyncSelect
            className="resto-react-select"
            placeholder="Select Restaurant"
            onChange={onRestaurantChange}
            defaultOptions={defaultOptions}
            value={selectedRestaurant}
            loadOptions={onRestaurantsOptions}
            onInputChange={handleInputChange}
          />
        )}

        {restaurantId && (
          <>
            <OrderCard
              orderCardtext="Pending"
              orderSubcardContent={partyOrderStats.PENDING}
              style={{
                marginRight: "40px",
                cursor: "pointer",
                ...(selectedStatus === "PENDING" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("PENDING")}
            />
            <OrderCard
              orderCardtext="Accepted"
              orderSubcardContent={partyOrderStats.ACCEPTED}
              style={{
                marginRight: "40px",
                cursor: "pointer",
                ...(selectedStatus === "ACCEPTED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("ACCEPTED")}
            />
            <OrderCard
              orderCardtext="Delivered"
              orderSubcardContent={partyOrderStats.DELIVERED}
              style={{
                marginRight: "40px",
                cursor: "pointer",
                ...(selectedStatus === "DELIVERED" && {
                  borderColor: "var(--theme-primary-color)",
                  color: "var(--theme-primary-color)",
                }),
              }}
              onClick={() => onStatusChange("DELIVERED")}
            />
          </>
        )}
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div>
          {partyOrders &&
            partyOrders.length > 0 &&
            partyOrders.map((eachOrder, index) => (
              <div className="south-indian-restaurant-div row">
                <div className="fast-food-main-div col-lg-3 col-md-6">
                  <h5
                    className="fast-food-id"
                    style={{ textTransform: "uppercase" }}
                  >
                    ID: #{eachOrder.sequence_no}
                  </h5>
                  <div className="line-break" />
                  <h5>Order Type: {eachOrder.type}</h5>
                  <div className="line-break" />
                  <h6>Delivery Date and Time</h6>
                  <h6 style={{ color: "var(--theme-primary-color)" }}>
                    {" "}
                    {moment(eachOrder.date).format("MMMM Do YYYY, HH:mm ")}
                  </h6>
                </div>
                <div className="paneer-kabab-main-div col-lg-3 col-md-6">
                  <h4 style={{ textAlign: "center", color: "#000" }}>
                    Menu Details
                  </h4>
                  <SimpleBar style={{ height: 220 }}>
                    {eachOrder.menu &&
                      eachOrder.menu.length > 0 &&
                      eachOrder.menu.map((eachOrderItem, index) => (
                        <div className="paneer-kabab-div">
                          <div className="green-doubt-div">
                            <span>
                              {index + 1}. {eachOrderItem.name}{" "}
                              {eachOrder.type === "ORDER"
                                ? ` X ${eachOrderItem.quantity}`
                                : ""}
                            </span>
                          </div>
                        </div>
                      ))}
                    {eachOrder.type === "PERSON" && (
                      <span>
                        Number of Persons:
                        <span style={{ fontWeight: 600, marginLeft: 10 }}>
                          {eachOrder.number_of_persons}
                        </span>
                      </span>
                    )}
                  </SimpleBar>
                </div>
                <div className="total-bill-external-div col-lg-3 col-md-6">
                  <h4 style={{ textAlign: "center" }}>Address Details</h4>
                  <div className="address-details">
                    <span className="fast-food-subheading color-black">
                      Delivery Address
                    </span>
                    <p>{eachOrder.name}</p>
                    <p>{eachOrder.mobile_no}</p>
                    <h2 className="fast-food-subheading">
                      {(eachOrder.address && eachOrder.address?.full_address) ||
                        "-"}
                    </h2>
                  </div>
                </div>
                <div className="fast-food-delivery-main-div col-lg-3 col-md-6">
                  <h4 style={{ textAlign: "center", color: "#000" }}>
                    Actions
                  </h4>
                  {!eachOrder.matched && selectedStatus === "ACTIVE" ? (
                    config.source === "vendor" && (
                      <PrimaryButton
                        title="Quotation"
                        style={{
                          marginTop: 40,
                        }}
                        onClick={() => onQuotationModal(eachOrder)}
                      />
                    )
                  ) : (
                    <>
                      <span>
                        Quotation Status:
                        <span
                          style={{
                            color: "#fff",
                            marginLeft: 10,
                            padding: 4,
                            background: "var(--theme-primary-color)",
                            borderRadius: 4,
                          }}
                        >
                          {eachOrder.quotationStatus}
                        </span>
                      </span>
                      <div className="quotation-details">
                        <span>Quotation Details:</span>
                        <p>
                          Quotation Amount:
                          <span
                            style={{
                              color: "var(--theme-primary-color)",
                              marginLeft: 10,
                            }}
                          >
                            {formatters.indianCurrency(eachOrder.amount)}
                          </span>
                        </p>
                        <p>
                          Description:
                          <span
                            style={{
                              color: "var(--theme-primary-color)",
                              marginLeft: 10,
                            }}
                          >
                            {eachOrder.description}
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                  <div className="order-time-line" style={{ marginTop: 20 }}>
                    <div style={{ display: "flex" }}>
                      <div className="each-time-line">
                        <h6>Placed</h6>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <span className="order-status-icon ">
                            <i class="fa-solid fa-circle-check" />
                          </span>
                          <p>
                            {moment(eachOrder?.createdAt).format(
                              "HH:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      {index < orderTimeline.length - 1 && (
                        <div className="timeline-line " />
                      )}
                    </div>
                  </div>
                  <div className="order-time-line" style={{ marginTop: 20 }}>
                    <div style={{ display: "flex" }}>
                      <div className="each-time-line">
                        <h6>Accepted</h6>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <span className="order-status-icon ">
                            <i class="fa-solid fa-circle-check" />
                          </span>
                          <p>
                            {moment(eachOrder?.address?.createdAt).format(
                              "HH:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      {index < orderTimeline.length - 1 && (
                        <div className="timeline-line " />
                      )}
                    </div>
                  </div>
                  {selectedStatus === "ACCEPTED" &&
                    config.source === "vendor" && (
                      <PrimaryButton
                        title="Delivered"
                        onClick={() => onStatusModal(eachOrder)}
                        style={{ background: "var(--theme-primary-color)" }}
                      />
                    )}
                </div>
              </div>
            ))}
          <div style={{ textAlign: "center", margin: "20px 0px 30px" }}>
            {partyOrders.length === partyOrderTotal ? (
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: "var(--theme-primary-color)",
                }}
              >
                {partyOrders.length === partyOrderTotal
                  ? "No Party Orders Found"
                  : "No More Party Orders Found"}
              </p>
            ) : (
              <PrimaryButton
                title="Load More"
                loading={infiniteLoading}
                style={{ width: 150, background: "var(--theme-primary-color)" }}
                onClick={onLoadMore}
              />
            )}
          </div>
        </div>
      )}
      <Modal show={quotationModal} onHide={() => onQuotationModal("")}>
        <ModalHeader closeButton>
          <ModalTitle>Add Quotation</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            key="quotation-form"
            initialValues={{
              description: "",
              amount: "",
              pricings: pricings,
            }}
            onSubmit={(values) => addQuotation(values)}
            validationSchema={validate.quotationSchema}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <>
                {console.log(values)}
                {values.pricings.map((eachPricing, index) => (
                  <FormGroup
                    className="login-input-group"
                    style={{ display: "flex", marginTop: 20 }}
                  >
                    <div
                      style={{
                        width:
                          values.selectedPartyOrderType === "PERSON"
                            ? "95%"
                            : "60%",
                      }}
                    >
                      {index === 0 && <FormLabel>Pricing Type</FormLabel>}
                      <Input
                        type="text"
                        name={`pricings.${index}.priceType`}
                        onChange={(e) =>
                          onPriceTypeChange(
                            e,
                            setFieldValue,
                            "priceType",
                            eachPricing.id
                          )
                        }
                        value={values.pricings[index].priceType}
                        onBlur={handleBlur}
                        placeholder="Pricing Type"
                        className="customer-name-input"
                      />
                      {errors.pricings && errors.pricings[index] && (
                        <span className="error">
                          {errors.pricings[index].priceType}
                        </span>
                      )}
                    </div>

                    <div style={{ width: "25%", marginLeft: "25px" }}>
                      {index === 0 && <FormLabel>Amount</FormLabel>}
                      <Input
                        type="number"
                        name={`pricings.${index}.amount`}
                        onChange={(e) =>
                          onPriceTypeChange(
                            e,
                            setFieldValue,
                            "amount",
                            eachPricing.id
                          )
                        }
                        value={values.pricings[index].amount}
                        onBlur={handleBlur}
                        placeholder="Amount"
                        className="customer-name-input"
                      />
                      {errors.pricings && errors.pricings[index] && (
                        <span className="error">
                          {errors.pricings[index].amount}
                        </span>
                      )}
                    </div>

                    <div
                      style={{
                        width: "5%",
                        marginTop: index === 0 ? 15 : -10,
                        marginLeft: 20,
                      }}
                    >
                      <FormLabel></FormLabel>
                      {index === 0 ? (
                        <span
                          style={{
                            padding: "1px 10px",
                            background: "var(--theme-primary-color)",
                            color: "#fff",
                            cursor: "pointer",
                            borderRadius: 6,
                          }}
                          onClick={() => onAddPriceType(setFieldValue)}
                        >
                          +
                        </span>
                      ) : (
                        <span
                          style={{
                            padding: "1px 10px",
                            background: "var(--theme-primary-color)",
                            color: "#fff",
                            cursor: "pointer",
                            borderRadius: 6,
                          }}
                          onClick={() =>
                            onRemovePriceType(eachPricing, setFieldValue)
                          }
                        >
                          -
                        </span>
                      )}
                    </div>
                  </FormGroup>
                ))}
                {/* <FormGroup className="login-input-group">
                  <FormLabel>Amount*</FormLabel>
                  <Input
                    type="number"
                    name="amount"
                    onChange={handleChange}
                    value={values.amount}
                    onBlur={handleBlur}
                    placeholder="Amount"
                    className="customer-name-input"
                  />
                </FormGroup> */}
                {validate.displayError(touched, errors, "amount")}
                <FormGroup className="login-input-group">
                  <FormLabel>Description*</FormLabel>
                  <Input
                    as="textarea"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    onBlur={handleBlur}
                    placeholder="description"
                    className="customer-name-input"
                    style={{ minHeight: 80 }}
                  />
                </FormGroup>
                {validate.displayError(touched, errors, "description")}
                <div className="bottom-btns">
                  <PrimaryButton
                    title="Submit"
                    style={{
                      borderColor: "var(--theme-primary-color)",
                      background: "var(--theme-primary-color)",
                      borderRadius: "5px",
                      padding: "15px 50px",
                      margin: "20px 5px",
                    }}
                    loading={quotationLoading}
                    onClick={handleSubmit}
                  />
                  <PrimaryButton
                    title="Cancel"
                    style={{
                      borderColor: "#e5e5e5",
                      background: "#e5e5e5",
                      borderRadius: "5px",
                      padding: "15px 50px",
                      color: "#000",
                      margin: "20px 5px",
                    }}
                    onClick={() => onQuotationModal("")}
                  />
                </div>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal show={updateStatusModal} onHide={() => onStatusModal("")}>
        <ModalHeader closeButton>
          <ModalTitle>Update Deliver Status</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Do you Update the Status of the order to be delivered?</p>
          <div className="bottom-btns">
            <PrimaryButton
              title="Delivered"
              style={{
                borderColor: "var(--theme-primary-color)",
                background: "var(--theme-primary-color)",
                borderRadius: "5px",
                padding: "15px 50px",
                margin: "20px 5px",
              }}
              loading={quotationLoading}
              onClick={deliveredPartyOrderQuotation}
            />
            <PrimaryButton
              title="Cancel"
              style={{
                borderColor: "#e5e5e5",
                background: "#e5e5e5",
                borderRadius: "5px",
                padding: "15px 50px",
                color: "#000",
                margin: "20px 5px",
              }}
              onClick={() => onStatusModal("")}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
