import { createAction } from "redux-actions";
import * as Storage from "../services/LocalStorage";

const addItem = "ADD_ITEM";
const removeItem = "REMOVE_ITEM";
const clearItems = "CLEAR_ITEMS";
const quantityDecrease = "QUANTITY_DECREASE";
const getItems = "GET_ITEMS";
const cartChange = "CART_CHANGE";

// actions
export const AddItemAction = createAction(addItem);
export const RemoveItemAction = createAction(removeItem);
export const ClearItemsAction = createAction(clearItems);
export const QuantityDecreaseAction = createAction(quantityDecrease);
export const GetItemsAction = createAction(getItems);
export const CartChangeAction = createAction(cartChange);

const key = "cart_options";

const initialState = {
  items: [],
  total: 0,
  subTotal: 0,
  restaurantId: null,
  cartChange: null,
};

const CartChangeReducer = (state, action) => {
  return {
    ...state,
    cartChange: action.payload,
  };
};

const cartReducers = (state = initialState, action) => {
  switch (action.type) {
    case addItem:
      return AddItemReducer(state, action);
    case removeItem:
      return RemoveItemReducer(state, action);
    case clearItems:
      return ClearItemsReducer(state, action);
    case quantityDecrease:
      return QuantityDecreaseReducer(state, action);
    case getItems:
      return GetItemsReducer(state, action);
    case cartChange:
      return CartChangeReducer(state, action);
    default:
      return state;
  }
};
//reducers
const AddItemReducer = (state, action) => {
  let items = [];
  const item = action.payload.item;
  let existingItems = state.items;
  const restaurantId = action.payload.restaurantId;
  if (restaurantId !== state.restaurantId) {
    existingItems = [];
  }
  const isCartEmpty = existingItems.length === 0;

  if (isCartEmpty) {
    item.quantity = 1;
    items.push(item);
    Storage.set(
      key,
      JSON.stringify({
        items,
        total: item.offer_price,
        subTotal: item.base_price,
        restaurantId,
      })
    );
    return {
      items,
      total: state.total + item.offer_price,
      subTotal: state.subTotal + item.base_price,
      restaurantId,
    };
  } else {
    let itemExisted = false,
      items = existingItems.map((eachItem) => {
        if (eachItem._id === item._id) {
          itemExisted = true;
          return {
            ...eachItem,
            quantity: eachItem.quantity + 1,
          };
        } else return eachItem;
      });
    if (itemExisted) {
      Storage.set(
        key,
        JSON.stringify({
          items,
          total: state.total + item.offer_price,
          subTotal: state.subTotal + item.base_price,
          restaurantId,
        })
      );
      return {
        items,
        total: state.total + item.offer_price,
        subTotal: state.subTotal + item.base_price,
        restaurantId,
      };
    } else {
      item.quantity = 1;
      items.push(item);
      Storage.set(
        key,
        JSON.stringify({
          items,
          total: state.total + item.offer_price,
          subTotal: state.subTotal + item.base_price,
          restaurantId,
        })
      );
      return {
        items,
        total: state.total + item.offer_price,
        subTotal: state.subTotal + item.base_price,
        restaurantId,
      };
    }
  }
};

const RemoveItemReducer = (state, action) => {
  const itemId = action.payload;
  const deleteItem = state.items.filter((item) => item._id === itemId)[0];
  let items = state.items.filter((item) => item._id !== itemId);
  Storage.set(
    key,
    JSON.stringify({
      items,
      total: state.total - deleteItem.offer_price * deleteItem.quantity,
      subTotal: state.subTotal - deleteItem.base_price * deleteItem.quantity,
      restaurantId: state.restaurantId,
    })
  );
  return {
    ...state,
    items,
    total: state.total - deleteItem.offer_price * deleteItem.quantity,
    subTotal: state.subTotal - deleteItem.base_price * deleteItem.quantity,
  };
};

const QuantityDecreaseReducer = (state, action) => {
  const itemId = action.payload;
  let quantity = 1;
  const updateItem = state.items.filter((item) => item._id === itemId)[0];
  let items = state.items.map((item) => {
    if (item._id === itemId) {
      quantity = item.quantity - 1;
      return {
        ...item,
        quantity: item.quantity - 1,
      };
    } else {
      return item;
    }
  });
  if (quantity < 1) {
    items = state.items.filter((item) => item._id !== itemId);
  }
  Storage.set(
    key,
    JSON.stringify({
      items,
      total: state.total - updateItem.offer_price,
      subTotal: state.subTotal - updateItem.base_price,
      restaurantId: state.restaurantId,
    })
  );
  return {
    ...state,
    items,
    total: state.total - updateItem.offer_price,
    subTotal: state.subTotal - updateItem.base_price,
  };
};

const ClearItemsReducer = () => {
  Storage.set(
    key,
    JSON.stringify({
      items: [],
      total: 0,
      subTotal: 0,
      restaurantId: null,
    })
  );
  return {
    items: [],
    total: 0,
    subTotal: 0,
    restaurantId: null,
  };
};

const GetItemsReducer = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

export default cartReducers;
