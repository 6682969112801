import React from "react";

export default function ButtonWithIcon(props) {
  const buttonStyle = {
    color: "#fff",
    background: "#ffd163",
    borderColor: "#ffd163",
    display: "flex",
  };

  return (
    <button
      {...props}
      className={`${props.className ? props.className : ""}`}
      style={{ ...buttonStyle, ...props.style }}
    >
      {props.iconStart && (
        <span style={{ marginRight: "5px" }}>
          <img src={props.icon} alt={props.title} />
        </span>
      )}
      <span>{props.title}</span>
      {props.iconEnd && (
        <span style={{ marginLeft: "5px" }}>
          <img src={props.icon} alt={props.title}  />
        </span>
      )}
    </button>
  );
}
