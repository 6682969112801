import React from "react";
import Loader from "react-loaders";
import "loaders.css/src/animations/semi-circle-spin.scss";
import './DataLoader.scss'

export default function DataLoader(props) {
  return (
    <Loader
      type="semi-circle-spin"
      active
      {...props}
      className={`data-loader ${props?.className || ""}`}
    />
  );
}
