import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.scss";
import "./Common.scss";

import storeFactory from "./store";
import { Provider } from "react-redux";
import storeProvider from "./store/StoreProvider";
import Main from "./Main";
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App(props) {
  const [doneFetchingData, setDoneFetchingData] = useState(false);
  const [config, setConifg] = useState({});

  useEffect(() => {
    axios
      .get("config/appConfig.json")
      .then(function (res) {
        var appconfig = res.data;
        setConifg(appconfig);
        setDoneFetchingData(true);
      })
      .catch(function (res) {
        console.log(res);
        console.log("An error occurred config in appjs");
      });
  }, []);

  storeProvider.init(storeFactory);
  const store = storeProvider.getStore();
  const saveState = () =>
    localStorage.setItem(config.reduxkey, JSON.stringify(store.getState()));
  store.subscribe(saveState);
  return doneFetchingData ? (
    <div className="App">
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router basename={config.rootPath}>
          <Main {...props} />
        </Router>
        <ToastContainer />
      </Provider>
    </div>
  ) : (
    ""
  );
}

export default App;
