import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FormGroup, FormLabel, Form } from "react-bootstrap";
import ReactSelect from "react-select";

//custom Imports
import {
  ButtonWithIcon,
  PrimaryButton,
} from "../../../assets/elements/buttons";
import validate from "../../../validations";
import PageLoader from "../../../assets/elements/loaders/PageLoader";
import Input from "../../../assets/elements/formElements/adminInput/AdminInput";
import CommonServices from "../../../services/CommonServices";

//npm
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import "./Delivery.scss";
import UploadInput from "../../../assets/elements/formElements/uploadInput/UploadInput";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";

//icons
import Edit from "../../../assets/img/svg/edit.svg";
import Delete from "../../../assets/img/svg/delete.svg";
import DoubleLeft from "../../../assets/img/svg/double_next.svg";
import DoubleRight from "../../../assets/img/svg/double_prev.svg";
import { useSelector } from "react-redux";
import Head from "../../../assets/elements/Head";

export default function Delivery() {
  const [deliveries, setDeliveries] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState("");
  const navigate = useNavigate();
  const [deliveryCount, setDeliveryCount] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [statusUpdate, setStatusUpdate] = useState(0);
  const [statusLoading, setStatusLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [files, setFiles] = useState({});
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const config = useSelector((state) => state.config.config);
  const [selectedMainStatus, setSelectedMainStatus] = useState({
    label: "Pending",
    value: "PENDING",
  });
  const userId = useSelector((state) => state.auth.userId);
 
 

  let debounceTimer = useRef(null);

  // const deliveryOptions = [
  //   { label: "Approved", value: "APPROVED" },
  //   { label: "Rejected", value: "REJECTED" },
  //   { label: "Blocked", value: "BLOCKED" },
  // ];
  const statusMainOptions = [
    { label: "Pending", value: "PENDING" },
    { label: "Approved", value: "APPROVED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Blocked", value: "BLOCKED" },
  ];

  useEffect(() => {
    config.source === "admin" &&
      getAllDeliveryProfiles(1, "refresh", "", selectedMainStatus);
    config.source === "vendor" &&
      getRestaurantsDeliveryAgents(1, "refresh", "", selectedMainStatus);
    // eslint-disable-next-line
  }, []);

  const sortFormat = (column, colIndex, { sortElement, filterElement }) => {
    return (
      <div>
        {filterElement}
        {column.text}
        {sortElement}
      </div>
    );
  };

  const onSelectChange = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
  };

  const onStatusChange = (selectedMainStatus) => {
    setSelectedMainStatus(selectedMainStatus);
    config.source === "admin" &&
      getAllDeliveryProfiles(1, "refresh", searchText, selectedMainStatus);
    config.source === "vendor" &&
      getRestaurantsDeliveryAgents(
        1,
        "refresh",
        searchText,
        selectedMainStatus
      );
  };

  const onSearch = (e) => {
    const searchText = e.target.value;
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      getRestaurantsDeliveryAgents(
        1,
        "refresh",
        searchText,
        selectedMainStatus
      );
    }, 1000);

    setSearchText(searchText);
  };

  const handleDeliveryProfilePicture = (e, name) => {
    let finalValue = e;
    finalValue["field"] = name;
    setFiles({ ...files, [name]: finalValue });
  };

  const statusFormatter = (cell, row) => {
    if (row.status) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              textAlign: "center",
              color: "rgb(16, 0, 120)",
              width: " 88px",
              height: "25px",
              paddingTop: 5,
              background: row.status === "ACTIVE" ? "#CEFFBD" : "#ffcebd",
              borderRadius: "15px",
              marginBottom: 0,
              marginTop: 5,
            }}
          >
            {row.status}
          </span>
        </div>
      );
    }
  };

  const actionsBtns = (cell, row) => {
    if (row.status) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent:
              row.status === "INACTIVE" ? "center" : "space-evenly",
          }}
        >
          <ButtonWithIcon
            title="Delete"
            icon={Delete}
            iconEnd={true}
            style={{
              borderColor: "#C90000",
              background: "#C90000",
              borderRadius: "5px",
            }}
            className="action-btn"
            onClick={() => handleDeleteModal(row, "delete")}
          />
        </div>
      );
    }
  };

  const createdDate = (cell, row) => {
    if (row.createdAt) {
      return (
        <span
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: " 14px",
            lineHeight: "21px",
            textAlign: "center",
            color: "#000000",
            opacity: 0.8,
          }}
        >
          {moment(row.createdAt).format("DD-MM-YYYY")}
        </span>
      );
    }
  };
  const nameFormatter = (cell, row) => {
    if (row.name || row.first_name) {
      return (
        <span
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: " 14px",
            lineHeight: "21px",
            textAlign: "center",
            color: "#000000",
            opacity: 0.8,
          }}
        >
          <span
            onClick={() => navigate(`/delivery-agent/${row._id}`)}
            style={{
              cursor: "pointer",
              color: "var(--theme-primary-color)",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {row?.name || row?.first_name}
          </span>
        </span>
      );
    }
  };

  const columns = [
    {
      dataField: "sNo",
      text: "Sr. No. ",
      sort: true,
      headerFormatter: sortFormat,
      headerClasses: "id-col",
    },
    {
      dataField: "name",
      text: "Delivery Agent Name",
      sort: true,
      headerFormatter: sortFormat,
      formatter: nameFormatter,
      headerClasses: "customer-col",
    },

    {
      dataField: "phone",
      text: "Mobile Number ",
      sort: true,
      headerFormatter: sortFormat,
    },
    {
      dataField: "email",
      text: "Email",
      headerFormatter: sortFormat,
    },
    {
      dataField: "createdAt",
      text: "Added Date",
      sort: true,
      formatter: createdDate,
      headerFormatter: sortFormat,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
      headerFormatter: sortFormat,
    },

    ...(selectedMainStatus.value !== "INACTIVE"
      ? [
          {
            dataField: "",
            text: "Actions",
            formatter: actionsBtns,
            headerClasses: "actions-col",
          },
        ]
      : []),
  ];
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const handleModal = () => {
    setAddModal(!addModal);
  };
  const handleDeleteModal = (selectedDelivery, statusType) => {
    if (selectedDelivery) {
      setSelectedDelivery(selectedDelivery);
    } else {
      setSelectedDelivery("");
    }

    if (statusType === "delete") {
      setSelectedStatus({ label: "INACTIVE", value: "INACTIVE" });
    } else {
      setSelectedStatus("");
    }
    setStatusUpdate(statusType);
    setDeleteModal(!deleteModal);
  };

  const pageButtonRenderer = ({ page, active, disabled, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "var(--theme-primary-color)";
      activeStyle.color = "#fff";
      activeStyle.borderColor = "var(--theme-primary-color)";
      activeStyle.padding = "15px 23px";
      activeStyle.borderRadius = "30px";
    } else {
      activeStyle.backgroundColor = "#fff";
      activeStyle.color = "#000";
      activeStyle.padding = "15px 23px";
    }
    let buttonStyle = (
      <div
        onClick={handleClick}
        style={{
          cursor: "pointer",
          backgroundColor: "#FFD2B9",
          borderRadius: "20px",
          padding: "10px 18px",
          color: "var(--theme-primary-color)",
          margin: "-10px 10px 0",
          opacity: disabled ? 0.4 : 1,
        }}
      >
        {page === "Previous" && (
          <img src={DoubleRight} style={{ marginRight: "5px" }} alt="right" />
        )}
        <span>{page}</span>
        {page === "Next" && (
          <img src={DoubleLeft} style={{ marginLeft: "5px" }} alt="left" />
        )}
      </div>
    );
    return (
      <li className="page-item" disable>
        {typeof page === "string" ? (
          buttonStyle
        ) : (
          <a href="/#" onClick={handleClick} style={activeStyle}>
            {page}
          </a>
        )}
      </li>
    );
  };

  const options = {
    pageButtonRenderer,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    prePageText: "Previous",
    nextPageText: "Next",
    showTotal: true,
    custom: true,
  };

  const uploadFile = (values) => {
    setDeliveryLoading(true);
    let images = [];
    if (!isEmpty(files)) {
      files.profile_picture && images.push(files.profile_picture);
    }
    axios
      .all([CommonServices.fileUpload(images)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.status) {
            let fileData = resData.data;
            let files = {};
            fileData.map((eachFile) => {
              files[eachFile.fileLabel] = eachFile.filename;
              return true;
            });
            addRestaurantDeliveryAgent(values, files);
          } else {
          }
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in file upload");
      });
  };

  const getAllDeliveryProfiles = (page, reload, term, selectedMainStatus) => {
    reload === "reload" && setPageLoading(true);
    const details = {
      status: selectedMainStatus.value,
      resource: "delivery",
      page,
      limit: 10,
      search_string: term,
    };
    axios
      .all([CommonServices.getAllDeliveryProfiles(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let deliveries = resData.data ? resData.data : {};
            let total = deliveries.total ? deliveries.total : 0;
            setDeliveryCount(total);
            deliveries = deliveries.data ? deliveries.data : [];
            deliveries = deliveries.map((eachRes, index) => {
              return {
                ...eachRes,
                sNo: (page - 1) * 10 + index + 1,
              };
            });
            setDeliveries(deliveries);
            setPageLoading(false);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Restaurants");
      });
  };
  const getRestaurantsDeliveryAgents = (
    page,
    reload,
    term,
    selectedMainStatus
  ) => {
    reload === "reload" && setPageLoading(true);
    const details = {
      status: selectedMainStatus.value,
      resource: "delivery",
      page,
      limit: 10,
      search_string: term, 
      ...(config.source === "vendor" ? {restaurant_id: userId} : {})
    };
    axios
      .all([CommonServices.getRestaurantsDeliveryAgents(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let deliveries = resData.data ? resData.data : {};
            let total = deliveries.total ? deliveries.total : 0;
            setDeliveryCount(total);
            deliveries = deliveries.data ? deliveries.data : [];
            deliveries = deliveries.map((eachRes, index) => {
              return {
                ...eachRes,
                sNo: (page - 1) * 10 + index + 1,
              };
            });
            setDeliveries(deliveries);
            setPageLoading(false);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Restaurants");
      });
  };

  const RemotePagination = ({
    data,
    onTableChange,
    page,
    sizePerPage,
    totalSize,
  }) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          ...options,
          page,
          sizePerPage,
          totalSize,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <div className="table-container-main">
              <BootstrapTable
                remote
                data={data}
                columns={columns}
                keyField="deliveries"
                defaultSorted={defaultSorted}
                bordered={false}
                tabIndexCell
                onTableChange={onTableChange}
                filter={filterFactory()}
                {...paginationTableProps}
              />
            </div>
            <div className="table-pagination-main">
              <PaginationTotalStandalone {...paginationProps} />
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );

  const handleTableChange = (type, { page }) => {
    console.log(page);
    config.source === "admin" &&
      getAllDeliveryProfiles(page, "refresh", "", selectedMainStatus);
    config.source === "vendor" &&
      getRestaurantsDeliveryAgents(page, "refresh", "", selectedMainStatus);
    setPage(page);
  };

  const deleteDeliveryProfile = (values) => {
    setStatusLoading(true);
    const details = {
      status: selectedStatus && selectedStatus.value,
      resource: "delivery",
      delivery_id: selectedDelivery._id,
    };
    axios
      .all([CommonServices.deleteDeliveryProfile(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            config.source === "admin" &&
              getAllDeliveryProfiles(page, "refresh", "", selectedMainStatus);
            config.source === "vendor" &&
              getRestaurantsDeliveryAgents(
                page,
                "refresh",
                "",
                selectedMainStatus
              );
            statusUpdate === "delete"
              ? toast.error("Delivery Agent deleted Successfully")
              : toast.warning(
                  `Delivery Agent ${selectedStatus.label} Successfully`
                );
            handleDeleteModal("", false);
            setStatusLoading(false);
          } else {
            setStatusLoading(false);
            toast.error(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating delivery profile status");
      });
  };

  //   const updateDeliveryProfile = (values, files) => {
  //     setDeliveryLoading(true);
  //     const details = {
  //         name: values.deliveryBoyName,
  //         phone: values.deliveryPhone,
  //         email: values.deliveryEmail,
  //         profile_picture: files.profilePicture,
  //         resource: "delivery",
  //     };
  //     axios
  //       .all([CommonServices.updateDeliveryProfile(details)])
  //       .then(function (res) {
  //         if (res[0]) {
  //           let resData = res[0];
  //           let message = resData.message;
  //           if (resData.success) {
  //             toast.success("Delivery Profile Updated Succesfully");
  //             setDeliveryLoading(false);
  //             handleModal("");
  //             getAllDeliveryProfiles(1, "refresh", "", selectedMainStatus);
  //           } else {
  //             toast.error(message);
  //             setDeliveryLoading(false);
  //           }
  //         } else {
  //         }
  //       })
  //       .catch(function (res) {
  //         console.log(res);
  //         if (res.message)
  //           console.log("An error occurred in updating delivery profile");
  //       });
  //   };

  const addDeliveryProfile = (values, files) => {
    setDeliveryLoading(true);
    const details = {
      name: values.deliveryBoyName,
      phone: values.deliveryPhone,
      email: values.deliveryEmail,
      profile_picture: files.profilePicture,
      password: values.password,
      resource: "delivery",
    };
    axios
      .all([CommonServices.addDeliveryProfile(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Delivery Profile Added Succesfully");
            setDeliveryLoading(false);
            handleModal();
            getAllDeliveryProfiles(1, "refresh", "", selectedMainStatus);
          } else {
            toast.error(message);
            setDeliveryLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding delivery profile");
      });
  };
  const addRestaurantDeliveryAgent = (values, files) => {
    setDeliveryLoading(true);
    const details = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      email: values.email,
      profile_picture: files.profile_picture,
      password: values.password,
      resource: "delivery",
      DOB: values.dob,
      ...(config.source === "vendor" ? { restaurant: userId } : {}),
    };
    axios
      .all([CommonServices.addRestaurantDeliveryAgent(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            toast.success("Delivery Profile Added Succesfully");
            setDeliveryLoading(false);
            handleModal();
            getRestaurantsDeliveryAgents(1, "refresh", "", selectedMainStatus);
          } else {
            toast.error(message);
            setDeliveryLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding delivery profile");
      });
  };

  return (
    <div className="delivery-main">
      <Head title="Delivery" />
      <div className="delivery-header">
        <p className="delivery-header-text">Delivery Agents</p>

        <PrimaryButton
          title="Add Agent"
          style={{
            background: "var(--theme-primary-color)",
            borderRadius: "10px",
            borderColor: "var(--theme-primary-color)",
            padding: "10px 24px",
          }}
          onClick={handleModal}
        />
      </div>
      <div class="search-restaurant-main">
        <ReactSelect
          name="selectedStatus"
          onChange={onStatusChange}
          value={selectedMainStatus}
          placeholder="Select Status"
          className="login-input"
          options={statusMainOptions}
        />
        <Form className="landing-slidder-input">
          <Input
            type="text"
            class="form-control"
            value={searchText}
            placeholder="Search for Agent..."
            onChange={onSearch}
          />
        </Form>
      </div>
      {pageLoading ? (
        <PageLoader style={{ marginTop: 200 }} />
      ) : (
        <RemotePagination
          data={deliveries}
          page={page}
          sizePerPage={10}
          totalSize={deliveryCount}
          onTableChange={handleTableChange}
        />
      )}

      <Modal
        show={addModal}
        onHide={handleModal}
        backdrop="static"
        className="delivery-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add-Delivery Agent Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="contact-from">
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                confirm_password: "",
                profile_picture: "",
                phone: "",
                dob: new Date(),
              }}
              validationSchema={validate.hostDeliveryProfileForm}
              onSubmit={(values) => {
                uploadFile(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <>
                  <div className="delivery-form-main">
                    <Form onSubmit={handleSubmit}>
                      <div>
                        <FormGroup className="form-group-margin">
                          <FormLabel>Fisrt Name*</FormLabel>
                          <Input
                            type="text"
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            placeholder="Enter First Name"
                            className="customer-name-input"
                          />
                          {validate.displayError(touched, errors, "first_name")}
                        </FormGroup>
                        <FormGroup className="form-group-margin">
                          <FormLabel>Last Name*</FormLabel>
                          <Input
                            type="text"
                            name="last_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                            placeholder="Enter Last Name"
                            className="customer-name-input"
                          />
                          {validate.displayError(touched, errors, "last_name")}
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                          <FormLabel>Date of Birth*</FormLabel>
                          <DatePicker
                            selected={values.dob}
                            onChange={(date) => setFieldValue("dob", date)}
                            className="order-date-picker"
                            dateFormat="MMMM d, yyyy"
                            value={values.dob}
                          />
                          {validate.displayError(touched, errors, "dob")}
                        </FormGroup>
                        <FormGroup className="form-group-margin">
                          <FormLabel className="password-label">
                            <span>Email Id*</span>{" "}
                          </FormLabel>
                          <Input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Enter Email Address"
                            className="customer-name-input"
                          />
                          {validate.displayError(touched, errors, "email")}
                        </FormGroup>
                        <FormGroup className="form-group-margin">
                          <FormLabel className="password-label">
                            <span>Phone Number*</span>{" "}
                          </FormLabel>
                          <Input
                            type="tel"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            placeholder="Enter Mobile Number"
                            className="customer-name-input"
                          />
                          {validate.displayError(touched, errors, "phone")}
                        </FormGroup>
                      </div>
                      <div className="form-control-flex-div">
                        <FormGroup className="form-group-margin">
                          <FormLabel className="password-label">
                            <span>Password*</span>{" "}
                          </FormLabel>
                          <Input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Enter Password"
                            className="customer-name-input"
                          />
                          {validate.displayError(touched, errors, "password")}
                        </FormGroup>

                        <FormGroup className="form-group-margin">
                          <FormLabel className="password-label">
                            <span>Confirm Password*</span>{" "}
                          </FormLabel>
                          <Input
                            type="password"
                            name="confirm_password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirm_password}
                            placeholder="Enter Confirm Password"
                            className="customer-name-input"
                          />
                          {validate.displayError(
                            touched,
                            errors,
                            "confirm_password"
                          )}
                        </FormGroup>

                        <FormGroup className="form-group-margin">
                          <FormLabel className="password-label">
                            <span>Profile Picture*</span>{" "}
                          </FormLabel>
                          <UploadInput
                            className="upload-input"
                            name="profile_picture"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("profile_picture", e);
                              handleDeliveryProfilePicture(
                                e,
                                "profile_picture"
                              );
                            }}
                            imagePreview={true}
                            accept="image/jpg,image/jpeg,image/png,image/svg"
                          />
                          {validate.displayError(
                            touched,
                            errors,
                            "profile_picture"
                          )}
                        </FormGroup>
                        <span
                          style={{
                            fontSize: 10,
                            color: "#ff0000",
                            margin: "20px 0px 0px",
                            display: "block",
                          }}
                        >
                          * only jpg, jpeg, svg, png formatted images supported
                        </span>
                        {typeof values.profile_picture === "string" &&
                          selectedDelivery && (
                            <div
                              style={{
                                width: "100%",
                                height: 100,
                                marginTop: "20px",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  config.fileBasicPath +
                                  selectedDelivery.profile_picture
                                }
                                alt=""
                              />
                            </div>
                          )}
                      </div>
                    </Form>
                  </div>
                  <div className="bottom-btns">
                    <PrimaryButton
                      title="Submit"
                      style={{
                        borderColor: "var(--theme-primary-color)",
                        background: "var(--theme-primary-color)",
                        borderRadius: "5px",
                        padding: "15px 50px",
                        margin: "20px 5px",
                      }}
                      loading={deliveryLoading}
                      onClick={handleSubmit}
                    />
                    <PrimaryButton
                      title="Cancel"
                      style={{
                        borderColor: "#e5e5e5",
                        background: "#e5e5e5",
                        borderRadius: "5px",
                        padding: "15px 50px",
                        color: "#000",
                        margin: "20px 5px",
                      }}
                      onClick={handleModal}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal
        show={deleteModal}
        onHide={() => handleDeleteModal("", false)}
        backdrop="static"
        className="delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {statusUpdate === true
              ? "Update Status"
              : "Delete Delivery Profile"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="contact-from">
            <Formik
              initialValues={{
                selectedStatus: "",
                rejectionReason: "",
              }}
              validationSchema={
                selectedStatus &&
                selectedStatus &&
                selectedStatus.value === "REJECTED"
                  ? validate.statusUpdatebyRejection
                  : ""
              }
              onSubmit={(values) => {
                deleteDeliveryProfile(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                handleSubmit,
                handleChange,
              }) => (
                <>
                  <div className="host-details">
                    <p className="details-text">
                      Do You want{" "}
                      {statusUpdate === true
                        ? "Update the status of "
                        : "Delete this "}
                      Delivery Profile {selectedDelivery.name}?
                    </p>
                  </div>

                  {statusUpdate === true && (
                    <Form>
                      <FormGroup className="login-input-group">
                        <FormLabel className="password-label">
                          <span>Select Status</span>{" "}
                        </FormLabel>
                        <ReactSelect
                          name="selectedStatus"
                          onChange={(selectedStatus) => {
                            setFieldValue("selectedStatus", selectedStatus);
                            onSelectChange(selectedStatus);
                          }}
                          onBlur={handleBlur}
                          value={selectedStatus}
                          placeholder="Select Status"
                          className="login-input"
                          options={deliveryOptions}
                        />
                        {validate.displayError(
                          touched,
                          errors,
                          "selectedStatus"
                        )}
                      </FormGroup>
                    </Form>
                  )}
                  {selectedStatus && selectedStatus.value === "REJECTED" && (
                    <FormGroup
                      className="login-input-group"
                      style={{ marginTop: 10 }}
                    >
                      <FormLabel>Rejection Reason</FormLabel>
                      <Input
                        as="textarea"
                        name="rejectionReason"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.rejectionReason}
                        placeholder="Rejection Reason"
                        className="customer-name-input"
                        style={{ height: 80 }}
                      />
                      {validate.displayError(
                        touched,
                        errors,
                        "rejectionReason"
                      )}
                    </FormGroup>
                  )}
                  <div className="bottom-btns">
                    <PrimaryButton
                      title={statusUpdate === true ? "Update" : "Delete"}
                      style={{
                        borderColor: "var(--theme-primary-color)",
                        background: "var(--theme-primary-color)",
                        borderRadius: "5px",
                        padding: "15px 50px",
                        margin: "20px 5px",
                      }}
                      loading={statusLoading}
                      onClick={handleSubmit}
                    />
                    <PrimaryButton
                      title="Cancel"
                      style={{
                        borderColor: "#e5e5e5",
                        background: "#e5e5e5",
                        borderRadius: "5px",
                        padding: "15px 50px",
                        color: "#000",
                        margin: "20px 5px",
                      }}
                      onClick={() => handleDeleteModal("", false)}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
