import React, { useEffect, useState, useRef } from "react";
import "./SmallHeader.scss";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";

//Elements
import OutlinedButton from "../../../assets/elements/buttons/OutlineButton";
import PrimaryButton from "../../../assets/elements/buttons/PrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginModalOpenAction } from "../../../redux/LoginModal";
import * as Storage from "../../../services/LocalStorage";

import Logo from "../../../assets/img/svg/mommas.svg";
import axios from "axios";
import CustomerServices from "../../../services/CustomerServices";
import AdminServices from "../../../services/AdminServices";
import NotificationProfile from "../../../assets/img/svg/004-leaf.svg";
import SimpleBar from "simplebar-react";
import moment from "moment";
import { NavLink as RouterLink } from "react-router-dom";
import { CartChangeAction } from "../../../redux/Cart";
import formatters from "../../../assets/numberFormatter";

export default function SmallHeader() {
  const [headerTransparent, setHeaderTransparent] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLanding = pathname === "/";
  const cartChange = useSelector((state) => state.cartOptions.cartChange);
  const { config } = useSelector((state) => state.config);
  const { profileImage, emailId, name } = useSelector((state) => state.auth);

  let intervalId = useRef(null);
  useEffect(() => {
    if (emailId) {
      getCart();
    }
  }, [cartChange, emailId]);

  useEffect(() => {
    window.addEventListener("scroll", scrollFunc);
    if (emailId) {
      intervalId.current = setInterval(() => {
        getNotifications();
      }, 10000);
    }
    return () => {
      clearInterval(intervalId.current);
    };
  }, [emailId]);

  //functions
  const scrollFunc = () => {
    let scrollPosition = window.scrollY;
    setHeaderTransparent(scrollPosition < 50);
  };
  const onLoginModalOpen = () => {
    dispatch(LoginModalOpenAction());
  };

  const onLogout = () => {
    Storage.remove(config.sessionKey);
    window.location.reload();
  };

  const onAddItem = (item) => {
    if (emailId) {
      addCart(item);
    } else {
      dispatch(LoginModalOpenAction());
    }
  };

  const onRemoveItem = (item) => {
    deleteCart(item);
  };

  const onDecreaseCount = (item) => {
    decreaseCartCount(item);
  };

  const onNotificationEvent = (each) => {
    if (each.type === "customer") {
      navigate("/profile");
    }
    if (each.type === "order") {
      navigate(`/order-details/${each.order}`);
    }
    if (each.type === "party_order") {
      navigate(`/party-order-details/${each.party_order}`);
    }
    updateNotifications(each._id);
  };

  const onCheckout = (element) => {
    navigate("/checkout");
  };

  //Apis

  const addCart = (item) => {
    const details = {
      resource: "cart",
      id: item._id,
      restaurant: item.restaurant,
    };
    axios
      .all([CustomerServices.addCart(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            dispatch(CartChangeAction(Math.random()));
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in Adding  Items to cart");
      });
  };

  const getCart = () => {
    const details = {
      resource: "cart",
    };
    axios
      .all([CustomerServices.getCart(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let items = resData.data ? resData.data : {};
            items = items.data ? items.data : {};
            let menu = items.menu ? items.menu : [];
            menu = menu.map((eachMenu) => {
              return {
                ...eachMenu,
                ...eachMenu.id,
                menu_id: eachMenu._id,
              };
            });
            items.menu = menu;
            setCartItems(items);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Getting cart");
      });
  };

  const deleteCart = (item) => {
    const details = {
      resource: "cart",
      id: item._id,
      restaurant: item.restaurant,
    };
    axios
      .all([CustomerServices.deleteCart(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            dispatch(CartChangeAction(Math.random()));
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Getting cart");
      });
  };

  const decreaseCartCount = (item) => {
    const details = {
      resource: "cart",
      id: item._id,
      restaurant: item.restaurant,
    };
    axios
      .all([CustomerServices.decreaseCartCount(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getCart();
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Getting cart");
      });
  };

  const clearCart = (item) => {
    const details = {
      resource: "cart",
    };
    axios
      .all([CustomerServices.clearCart(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            dispatch(CartChangeAction(Math.random()));
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Clearing cart");
      });
  };

  const getNotifications = () => {
    const details = {
      resource: "notification",
      status: "OPEN",
    };
    axios
      .all([CustomerServices.getNotifications(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let notificationsData = resData.data ? resData.data : {};
            notificationsData = notificationsData.data
              ? notificationsData.data
              : [];
            let openNotifications = [];
            notificationsData.map((each) => {
              if (each.status === "OPEN") openNotifications.push(each);
              return openNotifications;
            });
            setNotifications(openNotifications);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("Error occured at fetching customer notifications");
      });
  };

  const updateNotifications = (notificationId) => {
    const details = {
      resource: "notification",
      notification_id: notificationId,
      status: "CLOSE",
    };
    axios
      .all([AdminServices.updateNotifications(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getNotifications();
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("Error occured at fetching admin notifications");
      });
  };

  return (
    <div
      className="small-header-main"
      style={{
        background: headerTransparent && isLanding ? "transparent" : "#fff",
      }}
    >
      <div className="row small-header-order">
        {/* <div className="col-12 col-md-4">
          <Input placeholder="Search Restaurant" />
        </div> */}
        <div className="col-12 col-md-4" style={{ display: "flex" }}>
          <img src={Logo} alt="Logo" className="bell-logo-main" />
          <h3 className="header-logo" onClick={() => navigate("/")}>
            Momma's <span style={{ color: "#ff6602" }}>Kitchen</span>
            <p
              style={{
                textAlign: "left",
                marginLeft: "10px",
                marginBottom: "0",
                fontWeight: 500,
                color:"var(--customer-theme-color)"
              }}
            >
             Relish; don't just eat
            </p>
          </h3>
        </div>
        <div className="col-12 col-md-4">
          <div className="header-buttons">
            {emailId ? (
              <>
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle className="img-toggle-btn profile-toggle">
                    <img
                      src={
                        profileImage
                          ? config.fileBasicPath + profileImage
                          : "https://via.placeholder.com/80"
                      }
                      alt="profile"
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        border: "2px solid #ffc510",
                        objectFit: "cover",
                      }}
                    />
                    <span
                      style={{ color: "#ffc510", margin: 6, fontWeight: 600 }}
                    >
                      {name}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate("/profile")}>
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/orders")}>
                      Orders
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/party-orders")}>
                      Party Orders
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/addresses")}>
                      Addresses
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/favourites")}>
                      Favourites
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="img-toggle-btn">
                    <div className="header-cart">
                      <span className="cart-count-span">
                        {cartItems.menu ? cartItems.menu.length : 0}
                      </span>
                      <span className="span-icon">
                        <i class="fa-solid fa-cart-shopping"></i>
                      </span>
                      <span
                        style={{ color: "#ffc510", margin: 6, fontWeight: 600 }}
                      >
                        Cart
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="header-cart-items-menu sidebar">
                    <div className="cart-detail-box">
                      <div className="card">
                        <div
                          className="card-header padding-15 fw-700"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span className="span-icon">
                              <i class="fa-solid fa-cart-shopping"></i>
                            </span>
                            <span
                              style={{
                                color: "#ffc510",
                                margin: 6,
                                fontWeight: 600,
                              }}
                            >
                              Your Cart
                            </span>
                          </div>
                          <div>
                            Total:
                            <span>
                              {formatters.poundCurrency(
                                cartItems?.total_price || 0
                              )}
                            </span>
                          </div>
                        </div>

                        <div
                          className="card-body no-padding"
                          id="scrollstyle-4"
                        >
                          {cartItems &&
                          cartItems.menu &&
                          cartItems.menu.length > 0 ? (
                            cartItems.menu.map((eachItem) => (
                              <div className="cat-product-box">
                                <div className="row" style={{ padding: 15 }}>
                                  <div className="cat-name col-md-6 p-0">
                                    <p className="text-light-green fw-700">
                                      {eachItem.item_name}
                                    </p>{" "}
                                  </div>
                                  <span className="plus-minus-btn col-md-3 p-0">
                                    <span
                                      onClick={() => onDecreaseCount(eachItem)}
                                      style={{ marginRight: 5 }}
                                    >
                                      <i class="fa-solid fa-minus"></i>
                                    </span>
                                    <span className="text-dark-white">
                                      {eachItem.quantity}
                                    </span>{" "}
                                    <span
                                      onClick={() => onAddItem(eachItem)}
                                      style={{ marginLeft: 5 }}
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </span>
                                  </span>

                                  <div
                                    className="delete-btn col-md-1 p-0"
                                    onClick={() => onRemoveItem(eachItem)}
                                  >
                                    <span className="text-dark-white">
                                      {" "}
                                      <i className="far fa-trash-alt"></i>
                                    </span>
                                  </div>

                                  <div className="price col-md-2 p-0">
                                    {" "}
                                    <span className="text-dark-white fw-500">
                                      {formatters.poundCurrency(
                                        eachItem.offer_price * eachItem.quantity
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 600,
                                marginTop: 50,
                              }}
                            >
                              No Items added to cart
                            </p>
                          )}
                        </div>
                        {cartItems &&
                          cartItems.menu &&
                          cartItems.menu.length > 0 && (
                            <div className="card-footer padding-15">
                              <span
                                onClick={onCheckout}
                                className="btn-first green-btn text-custom-white full-width fw-500"
                              >
                                Proceed to Checkout
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle className="img-toggle-btn">
                    <div className="header-notifications">
                      <div className="header-notifications-count">
                        <span className="cart-count-span">
                          {notifications ? notifications.length : 0}
                        </span>
                        <span className="span-icon">
                          <i class="fa-solid fa-bell"></i>
                        </span>
                      </div>

                      <span
                        style={{ color: "#ffc510", margin: 6, fontWeight: 600 }}
                      >
                        Notifications
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <SimpleBar
                      style={{
                        maxHeight: 400,
                        width: 300,
                      }}
                      className="header-cart-items-menu"
                    >
                      <div className="notification-header-div">
                        <p>Notifications</p>
                      </div>

                      <div className="notification-display-main">
                        {notifications &&
                          notifications.length > 0 &&
                          notifications.map((eachResNotification) => (
                            <div
                              className="notification-display-flex"
                              onClick={() =>
                                onNotificationEvent(eachResNotification)
                              }
                            >
                              <div style={{ width: "20%", marginTop: 5 }}>
                                <div class="notification-profile-div">
                                  <img
                                    alt="Notification Profile"
                                    src={NotificationProfile}
                                  />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <span class="profile-type-text">
                                    {eachResNotification.type &&
                                      eachResNotification.type}
                                  </span>
                                  <span class="profile-message-text">
                                    {" "}
                                    {eachResNotification.message &&
                                      eachResNotification.message}
                                  </span>
                                </div>
                                <div className="updated-at-text">
                                  <span class="clock-watch">
                                    <i class="fa-solid fa-clock"></i>
                                  </span>
                                  <span className="clock-watch-text">
                                    Updated At{" "}
                                    {moment(
                                      eachResNotification.updatedAt &&
                                        eachResNotification.updatedAt
                                    ).format("DD/MM/YYYY HH:MM")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </SimpleBar>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <OutlinedButton
                  title="Login"
                  style={{ width: "48%", color: "var(--customer-theme-color)" }}
                  onClick={onLoginModalOpen}
                />
                <PrimaryButton
                  title="Signup"
                  style={{
                    width: "48%",
                    background: "var(--customer-theme-color)",
                    borderColor: "var(--customer-theme-color)",
                    marginLeft: 20,
                  }}
                  onClick={() => navigate("/registration")}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
