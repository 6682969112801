import storeProvider from "../store/StoreProvider";
import * as fetchServices from "./FetchServices";
import apiService from "./ApiServices";

const VendorServices = {
  vendorSignUp: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.vendorSignUp;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  vendorSignIn: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.vendorSignIn;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  vendorEmailVerify: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.vendorEmailVerify;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  vendorResendOtp: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.vendorResendOtp;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  vendorForgetPassword: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.vendorForgetPassword;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  vendorVerifyForgetPassword: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.vendorVerifyForgetPassword;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },

  getVendorDetails: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getVendorDetails;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  updateVendorDetails: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateVendorDetails +
      "?resource=" +
      details.resource +
      "&restaurant_id=" +
      details.restaurant_id;
    let type = "PUT";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  //categories Apis
  addCategory: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCategory + "?resource=" + details.resource;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getCategoriesByStatus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCategoriesByStatus;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  updateCategory: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateCategory +
      "?resource=" +
      details.resource +
      "&category_id=" +
      details.category_id;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  deleteCategory: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deleteCategory +
      "?resource=" +
      details.resource +
      "&category_id=" +
      details.category_id;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //Menu Apis
  addMenu: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addMenu + "?resource=" + details.resource;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  updateMenu: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateMenu +
      "?resource=" +
      details.resource +
      "&menu_id=" +
      details.menu_id;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  deleteMenu: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deleteMenu +
      "?resource=" +
      details.resource +
      "&menu_id=" +
      details.menu_id;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //Sub category
  addSubCategory: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addSubCategory + "?resource=" + details.resource;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  updateSubCategory: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateSubCategory +
      "?resource=" +
      details.resource +
      "&subcategory_id=" +
      details.subcategory_id;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  deleteSubCategory: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deleteSubCategory +
      "?resource=" +
      details.resource +
      "&subcategory_id=" +
      details.subcategory_id;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  getSubCategoriesByStatus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getSubCategoriesByStatus;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  updateOfferPrice: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateOfferPrice +
      "?resource=" +
      details.resource +
      "&item=" +
      details.item +
      "&restaurant=" +
      details.restaurant;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  vendorGetOrdersByStatus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.vendorGetOrdersByStatus;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  addQuotation: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addQuotation + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getQuotationsOnStatus: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getQuotationsOnStatus;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  deliveredPartyOrderQuotation: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deliveredPartyOrderQuotation +
      "?resource=" +
      details.resource +
      "&partyOrderQuotation_id=" +
      details.partyOrderQuotation_id +
      "&status=" +
      details.status;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  addRestaurantBankInfo: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.addRestaurantBankInfo + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getAllBankDetails: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getAllBankDetails;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  deleteBankDetails: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.deleteBankDetails +
      "?resource=" +
      details.resource +
      "&bank_id=" +
      details.bank_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  updateBankDetails: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateBankDetails +
      "?resource=" +
      details.resource +
      "&bank_id=" +
      details.bank_id;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getAllRestaurantNotifications: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getAllRestaurantNotifications;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getSaleCountByRestaurant: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getSaleCountByRestaurant;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  addSettlementRequest: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.addSettlementRequest + "?resource=" + details.resource;
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
};

export default VendorServices;
