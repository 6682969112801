import React from "react";
import "./Promotions.scss";

import validate from "../../../validations";
import { FormGroup, FormLabel } from "react-bootstrap";
import UploadInput from "../../../assets/elements/formElements/uploadInput/UploadInput";

export default function Promotions(props) {
  return (
    <div className="promotions-main">
      <FormGroup className="login-input-group">
        <FormLabel className="password-label">
          <span>Promotion Image*</span>{" "}
        </FormLabel>
        <UploadInput
          name="promotionImage"
          onBlur={props.handleBlur}
          imagePreview={true}
          accept="image/jpg,image/jpeg,image/svg,image/png"
          className="promotions-upload-input"
          onChange={(e) => {
            props.setFieldValue("promotionImage", e);
            props.handlePromotionFileChange(e, "promotionImage");
          }}
        />
        {validate.displayError(props.touched, props.errors, "promotionImage")}
      </FormGroup>
      <span style={{ fontSize: 10, color: "#ff0000", margin: '10px 0px 0px', display:'block' }}>
       * only jpg, jpeg, svg, png formatted images supported
      </span>
      {typeof props.values.promotionImage === "string" &&
        props.selectedPromotion && (
          <div
            style={{
              width: "100%",
              height: 100,
              marginTop: "20px",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={props.fileBasicPath + props.selectedPromotion}
              alt=""
            />
          </div>
        )}
      <span
        style={{
          color: "var(--customer-theme-color)",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 10,
        }}
      >
        Note: Approximately image width and height should be 414px X 300px
      </span>
    </div>
  );
}
