import { createAction } from "redux-actions";

const Authenticate = "AUTHENTICATE";

// actions
export const AuthenticateAction = createAction(Authenticate);

//reducers
export const AuthenticateReducer = (state, action) => {
  console.log(action);
  return {
    ...state,
    ...action.payload,
  };
};

const initialState = {
  token: null,
  phone: null,
  emailId: null,
  name: "",
  role: "",
  profileImage: "",
};

const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case Authenticate:
      return AuthenticateReducer(state, action);

    default:
      return state;
  }
};
export default authReducers;
