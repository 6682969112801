import React from "react";
import Footer from "../../footer/Footer";
import "./CustomerBase.scss";
import SmallHeader from "../../header/smallHeader/SmallHeader";
import { useLocation } from "react-router-dom";

export default function CustomerBase(props) {
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  return (
    <div className="customer-base-main">
      <SmallHeader/>
      <div
        className="content-main"
        style={{marginTop: isHome? 0 : "35px"}}
      >
        {props.children}
      </div>
      <Footer />
    </div>
  );
}
