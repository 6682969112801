import React from "react";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";

import sideLogo from "../../assets/img/svg/mommas.svg";
import * as Storage from "../../services/LocalStorage";
import "./Sidebar.scss";

export default function SideBar() {
  const { config } = useSelector((state) => state.config);
  console.log(config);
  const navigate = useNavigate();
  const onLogout = () => {
    navigate("/");
    Storage.clear();
    window.location.reload();
  };
  return (
    <div className="sidebar-main">
      <SimpleBar className="menu-simple-bar" style={{ height: "100%" }}>
        <div className="mc-sidebar">
          <div
            className="mcs-head"
            style={{ position: "sticky", top: 0, background: "#fff0da" }}
          >
            <img src={sideLogo} alt="" />
            <div className="sidebar-top-div">
              <h6 className="sidebar-heading">Momma's Kitchen</h6>
              <p className="sidebar-text">Don't Just Eat Relish</p>
            </div>
          </div>
          <div className="mcs-main">
            <div>
              <RouterLink className="mcsm-each" to={"/dashboard"}>
                <i class="fa-solid fa-list" style={{ fontSize: "24px" }}></i>
              </RouterLink>
              <p className="icon-text">Dashboard</p>
            </div>
            <div>
              <RouterLink
                className="mcsm-each"
                to={`/finance`}
              >
                <i
                  class="fa-sharp fa-solid fa-coins"
                  style={{ fontSize: "24px" }}
                ></i>
              </RouterLink>
              <p className="icon-text">Finance</p>
            </div>
            <div>
              <RouterLink className="mcsm-each" to={"/orders"}>
                <i
                  class="fa-brands fa-jedi-order"
                  style={{ fontSize: "24px" }}
                ></i>
              </RouterLink>
              <p className="icon-text">Orders</p>
            </div>
            <div>
              <RouterLink className="mcsm-each" to={"/party-orders"}>
                <i
                  class="fa-brands fa-first-order"
                  style={{ fontSize: "24px" }}
                ></i>
              </RouterLink>
              <p className="icon-text">Party Orders</p>
            </div>
            {config.source === "vendor" && (
              <div>
                <RouterLink className="mcsm-each" to={"/menu"}>
                  <i class="fa-solid fa-bars" style={{ fontSize: "24px" }}></i>
                </RouterLink>
                <p className="icon-text">Menu</p>
              </div>
            )}
            {config.source === "admin" && (
              <div>
                <RouterLink className="mcsm-each" to={"/customers"}>
                  <i class="fa-solid fa-user" style={{ fontSize: "24px" }}></i>
                </RouterLink>
                <p className="icon-text">Customers</p>
              </div>
            )}
            {config.source === "admin" && (
              <div>
                <RouterLink className="mcsm-each" to={"/hosts"}>
                  <i class="fa-solid fa-user" style={{ fontSize: "24px" }}></i>
                </RouterLink>
                <p className="icon-text">Hosts</p>
              </div>
            )}
            {config.source === "admin" && (
              <>
                <div>
                  <RouterLink className="mcsm-each" to={"/cuisine"}>
                    <i
                      class="fa-solid fa-bowl-food"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </RouterLink>
                  <p className="icon-text">Cuisine</p>
                </div>
                <div>
                  <RouterLink className="mcsm-each" to={"/bank-list"}>
                    <i
                      class="fa-solid fa-building-columns"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </RouterLink>
                  <p className="icon-text">Bank List</p>
                </div>
              </>
            )}

            <div>
              <RouterLink className="mcsm-each" to={"/tags"}>
                <i class="fa-solid fa-tags" style={{ fontSize: "24px" }}></i>
              </RouterLink>
              <p className="icon-text">Tags</p>
            </div>

              <div>
                <RouterLink className="mcsm-each" to={"/delivery"}>
                  <i class="fa-solid fa-truck" style={{ fontSize: "24px" }}></i>
                </RouterLink>
                <p className="icon-text">Delivery</p>
              </div>
            {config.source === "admin" && (
              <div>
                <RouterLink className="mcsm-each" to={"/social-links"}>
                  <i class="fa-solid fa-icons" style={{ fontSize: "24px" }}></i>
                </RouterLink>
                <p className="icon-text">Social Links</p>
              </div>
            )}
            {config.source === "vendor" && (
              <div>
                <RouterLink className="mcsm-each" to={"/profile"}>
                  <i
                    class="fa-solid fa-address-book"
                    style={{ fontSize: "24px" }}
                  ></i>
                </RouterLink>
                <p className="icon-text">Profile</p>
              </div>
            )}
            {config.source === "vendor" && (
              <div>
                <RouterLink className="mcsm-each" to={"/banking-details"}>
                  <i class="fa-solid fa-building-columns"></i>
                </RouterLink>
                <p className="icon-text">Bank Info</p>
              </div>
            )}
            <div>
              <RouterLink className="mcsm-each" to={"/assigned_orders"}>
                <i class="fa-solid fa-person" style={{ fontSize: "24px" }}></i>
              </RouterLink>
              <p
                className="icon-text"
                style={{ wordBreak: "break-word", width: "65px" }}
              >
                Assigned Orders
              </p>
            </div>
          </div>

          <div className="sidebar-logout">
            <span className="logout-icon">
              <i class="fa-solid fa-power-off"></i>
            </span>
            <span className="icon-text" onClick={onLogout}>
              {" "}
              Logout
            </span>
          </div>
        </div>
      </SimpleBar>
    </div>
  );
}
