import { lazy, Suspense } from "react";
import PageLoader from "../../../assets/elements/loaders/PageLoader";

const LazyComponent = lazy(() => import("./Restaurant"));

export default function Restaurant(props) {
  return (
    <Suspense
      fallback={
        <PageLoader
          style={{
            margin: "30vh 50vw",
            width: "60px",
            height: "60px",
            color: "#1a21bc",
          }}
        />
      }
    >
      <LazyComponent {...props} />
    </Suspense>
  );
}
