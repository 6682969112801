import React from "react";
import "./AdminBase.scss";
import SideBar from "../../sidebar/SideBar";
import Header from "../../header/adminHeader/AdminHeader";

export default function AdminBase(props) {
  return (
    <div className="admin-base-main">
      <SideBar />
      <div className="content-container">
      <Header />
      <div className="main-sec"></div>
      <div className="content-main">{props.children}</div>
      </div>
    </div>
  );
}
