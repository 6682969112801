import React from "react";
import "./DeliveryCard.scss";

export default function DeliveryCard(props) {
  return (
    <div
      {...props}
      className={`deliverycard-main-div ${props?.className || ""}`}
    >
      <div className="delivery-relative-div">
        <img src={props.image} alt="" />
      </div>
      <div className="deliverycard-bottom-div">
        <h1 className="deliverycard-heading">{props.heading}</h1>
        <p className="deliverycard-para">{props.para}</p>
      </div>
    </div>
  );
}
