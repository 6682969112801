import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import * as Storage from "../../services/LocalStorage";
import { AuthenticateAction } from "../../redux/Auth";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";
import { Form, Modal } from "react-bootstrap";

import { FormGroup, FormLabel } from "react-bootstrap";
import { toast } from "react-toastify";

import Input from "../../assets/elements/formElements/Input";

//Components
import Base from "../../layout/base/customerBase/CustomerBase";
import Registration from "../customer/registration";
import Restaurant from "../customer/restaurant";
import Checkout from "../customer/checkout";
import OrderDetails from "../customer/orderDetails";
import ResultPage from "../customer/resultPage";
import LandingPage from "../customer/landingPage";
import SearchPage from "../customer/searchPage";
import RestaurantPage from "../customer/restaurantPage";
import Orders from "../customer/orders";
import About from "../customer/about";
import Help from "../customer/help";
import Home from "../customer/home";
import FilterResults from "../customer/filters";
import DummyComponent from "../DummyComponent";
import validate from "../../validations";
import {
  LoginModalCloseAction,
  URLRedirectAction,
} from "../../redux/LoginModal";
import { PrimaryButton } from "../../assets/elements/buttons";
import Favourites from "../../components/customer/favourites";

import axios from "axios";
import CustomerServices from "../../services/CustomerServices";
import Verification from "../common/verification";
import ForgetPassword from "../common/forgetPassword/ForgetPassword";
import { isEmpty } from "lodash";
import { SearchOptionsAction } from "../../redux/Search";
import { GetItemsAction } from "../../redux/Cart";
import Addresses from "../customer/addresses";
import PartyOrders from "../customer/partyOrders";
import PartyOrderDetails from "../customer/partyOrders/details";
import OrderInvoice from "../common/orderInvoice";
import CustomerProfile from "../../components/customer/customerProfile/customerProfile";
import OrderTracking from "../customer/trackOrder";
import TermsandConditions from "../customer/terms";
import showicon from "../../assets/img/eye-slash.svg";
import hideicon from "../../assets/img/eye-open.svg";

export default function ApplicationsCustomerRoutes() {
  const [loginLoading, setLoginLoading] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loginToggle, urlLink } = useSelector((state) => state.loginModal);
  const { config } = useSelector((state) => state.config);
  const emailId = useSelector((state) => state.auth.emailId);
  const nonHeaderPages = [
    "registration",
    "dummy",
    "email-verify",
    "forget-password",
  ];

  useEffect(() => {
    checkUserSession();
    searchOptions();
    cartOptions();
    // eslint-disable-next-line
  }, []);

  const checkUserSession = () => {
    let session = Storage.get(config.sessionKey);
    let authData = session ? JSON.parse(session) : {};
    let response = Storage.checkUserSession(authData);
    if (response.status) {
      let authData = response.data;
      dispatch(AuthenticateAction(authData));
    } else {
    }
  };
  const searchOptions = () => {
    let session = Storage.get("search_options");
    let options = session ? JSON.parse(session) : {};
    console.log(options);
    if (!isEmpty(options)) {
      dispatch(SearchOptionsAction(options));
    } else {
    }
  };

  const cartOptions = () => {
    let session = Storage.get("cart_options");
    let cartOptions = session ? JSON.parse(session) : {};
    if (!isEmpty(cartOptions)) {
      dispatch(GetItemsAction(cartOptions));
    } else {
    }
  };

  const customerLogin = (values) => {
    setLoginLoading(true);
    const details = {
      email: values.email,
      password: values.password,
    };

    axios
      .all([CustomerServices.customerLogin(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let authData = resData.data ? resData.data : {};
            authData = authData.data ? authData.data : {};
            let data = {
              token: authData.access_token,
              emailId: authData.email,
              phone: authData.phone,
              role: authData.role,
              name: authData.name,
              userId: authData._id,
              profileImage: authData.profile_image,
            };
            let key = config.sessionKey;
            Storage.set(key, JSON.stringify(data));
            dispatch(AuthenticateAction(data));
            if (!authData.email_verified) {
              navigate(`/email-verify/${values.email}/resend`);
            }
            if (urlLink) {
              navigate(urlLink);
              dispatch(URLRedirectAction(""));
            }
            onLoginModalClose();
            toast.success("Logged In  Succesfully");
            setLoginLoading(false);
          } else {
            toast.error(message);
            setLoginLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Customer` Login");
      });
  };

  const onLoginModalClose = () => {
    dispatch(LoginModalCloseAction());
  };
  console.log();

  return (
    <div>
      {!emailId && nonHeaderPages.indexOf(pathname.split("/")[1]) > -1 ? (
        <Routes>
          <Route path="/registration" element={<Registration />} />;
          <Route path="/dummy" element={<DummyComponent />} />
          <Route
            path="/email-verify/:emailId/:type"
            element={<Verification />}
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Routes>
      ) : emailId ? (
        <Base>
          <Routes>
            <Route path="/" element={<LandingPage />} />;
            <Route path="/search-results" element={<Home />} />;
            <Route path="/restaurant/:restaurantId" element={<Restaurant />} />;
            <Route path="/checkout" element={<Checkout />} />;
            <Route path="/order-details/:orderId" element={<OrderDetails />} />;
            <Route
              path="/order-tracking/:orderId"
              element={<OrderTracking />}
            />
            ;
            <Route
              path="/order-invoice-details/:orderId"
              element={<OrderInvoice />}
            />
            ;
            <Route path="/orders" element={<Orders />} />;
            <Route path="/party-orders" element={<PartyOrders />} />;
            <Route
              path="/party-order-details/:orderId"
              element={<PartyOrderDetails />}
            />
            ;
            <Route
              path="/party-order-details/:orderId/:quotation"
              element={<PartyOrderDetails />}
            />
            ;
            <Route path="/result-page" element={<ResultPage />} />;
            <Route path="/search-page" element={<SearchPage />} />;
            <Route path="/restaurant-page" element={<RestaurantPage />} />;
            <Route path="/filter-results" element={<FilterResults />} />;
            <Route path="/about" element={<About />} />;
            <Route path="/help" element={<Help />} />;
            <Route path="/favourites" element={<Favourites />} />;
            <Route path="/addresses" element={<Addresses />} />;
            <Route path="/profile" element={<CustomerProfile />} />;
            <Route
              path="/terms-and-conditions"
              element={<TermsandConditions />}
            />
            ;
          </Routes>
        </Base>
      ) : (
        <Base>
          <Routes>
            <Route path="/" element={<LandingPage />} />;
            <Route path="/search-results" element={<Home />} />;
            <Route path="/restaurant/:restaurantId" element={<Restaurant />} />;
            <Route path="/checkout" element={<Checkout />} />;
            <Route path="/result-page" element={<ResultPage />} />;
            <Route path="/search-page" element={<SearchPage />} />;
            <Route path="/restaurant-page" element={<RestaurantPage />} />;
            <Route path="/filter-results" element={<FilterResults />} />
            <Route path="/about" element={<About />} />;
            <Route path="/help" element={<Help />} />;
            <Route
              path="/terms-and-conditions"
              element={<TermsandConditions />}
            />
            ;
          </Routes>{" "}
        </Base>
      )}

      <Modal
        show={loginToggle}
        onHide={onLoginModalClose}
        backdrop="static"
        className="login-modal"
      >
        <Modal.Header
          style={{ background: "var(--customer-theme-color)" }}
          closeButton
        >
          <Modal.Title>Login here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="login-from">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validate.loginSchema}
              onSubmit={(values) => {
                customerLogin(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <FormGroup className="login-input-group">
                      <FormLabel className="password-label">
                        <span>Email*</span>{" "}
                      </FormLabel>
                      <Input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="Enter Email Address"
                        className="login-input"
                      />
                      {validate.displayError(touched, errors, "email")}
                    </FormGroup>
                    <FormGroup style={{ marginTop: "20px" }}>
                      <FormLabel>Password*</FormLabel>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          type={passwordHidden ? "password" : "text"}
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Enter Password"
                          className="customer-name-input"
                        />
                        <img
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "10px",
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                          }}
                          onClick={() => setPasswordHidden(!passwordHidden)}
                          src={passwordHidden ? showicon : hideicon}
                          alt="show"
                        />
                      </div>

                      {validate.displayError(touched, errors, "password")}
                    </FormGroup>
                    <div style={{ textAlign: "center", margin: 20 }}>
                      <span
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          onLoginModalClose();
                          navigate("/registration");
                        }}
                      >
                        Don't have an account? |{" "}
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          color: "var(--customer-theme-color)",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          onLoginModalClose();
                          navigate("/forget-password");
                        }}
                      >
                        Forgot password?
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <PrimaryButton
                        title="Login"
                        loading={loginLoading}
                        style={{
                          borderColor: "var(--theme-primary-color)",
                          background: "var(--theme-primary-color)",
                          borderRadius: "5px",
                          padding: "15px 50px",
                          margin: "10px 5px 0",
                        }}
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
