import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import axios from "axios";
import CommonServices from "../../../services/CommonServices";
import { StatsCard } from "../../../assets/elements/cards";

import sales from "../../../assets/img/svg/sales.png";
import settelment from "../../../assets/img/svg/test.png";
import pendingSettelment from "../../../assets/img/svg/clipboard.png";

import { useNavigate, useParams } from "react-router-dom";
import "./Finance.scss";
import PageLoader from "../../../assets/elements/loaders/PageLoader";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {
  ButtonWithIcon,
  PrimaryButton,
} from "../../../assets/elements/buttons";
import Edit from "../../../assets/img/svg/edit.svg";
import DoubleLeft from "../../../assets/img/svg/double_next.svg";
import DoubleRight from "../../../assets/img/svg/double_prev.svg";
import { DateRangePicker } from "react-date-range";
import ReactSelect from "react-select";
import formatters from "../../../assets/numberFormatter";
import { useSelector } from "react-redux";
import { Modal, FormGroup, FormLabel } from "react-bootstrap";
import { Formik } from "formik";

import UploadInput from "../../../assets/elements/formElements/uploadInput/UploadInput";
import DatePicker from "react-datepicker";
import validate from "../../../validations";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import VendorServices from "../../../services/Vendorservices";

export default function Finance() {
  const [inputValue, setInputValue] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [selectedMainStatus, setSelectedMainStatus] = useState({
    label: "Not Settled",
    value: "UNSETTLED",
  });
  const [counts, setCounts] = useState({});
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedFinance, setSelectedFinance] = useState("");
  const [statusUpdate, setStatusUpdate] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
  });
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [settleModal, setSettleModal] = useState(false);
  const [settleRequestModal, setSettleRequestModal] = useState(false);
  const [settleRequestOrdersModal, setSettleRequestOrdersModal] =
    useState(false);
  const [settleLoading, setSettleLoading] = useState(false);
  const [receipt, setReceipt] = useState({});
  const [raisedOrders, setRaisedOrders] = useState([]);
  const [selectedRaised, setSelectedRaised] = useState({});

  const { source } = useSelector((state) => state.config.config);
  const { userId } = useSelector((state) => state.auth);
  const { restaurantId, status, fromDate, toDate } = useParams();

  useEffect(() => {
    let settleStatus = { label: "Not Settled", value: "UNSETTLED" };
    let date = dateRange;
    if (status) {
      if (status === "All") {
        settleStatus = { label: "All", value: "All" };
      } else if (status === "RAISED") {
        settleStatus = { label: "Raised", value: "RAISED" };
      } else if (status === "SETTLED") {
        settleStatus = { label: "Settled", value: "SETTLED" };
      } else {
        settleStatus = { label: "Not Settled", value: "UNSETTLED" };
      }
    }
    if (fromDate && toDate) {
      console.log(fromDate, toDate);
      let temp = {
        endDate: new Date(toDate),
        startDate: new Date(fromDate),
      };
      date = temp;
      console.log(date);
      setDateRange(date);
    }

    setSelectedMainStatus(settleStatus);

    if (source === "admin") {
      let restId = "";
      if (restaurantId && restaurantId !== "All") {
        restId = restaurantId;
      } else {
        setSelectedRestaurant("");
      }
      getRaisedSettlementRequests(restId);
      getRestaurants("reload", "", "", restId);
      getAllStatsCount(restId, dateRange);
      getOrdersBySettleStatus(restId, date, page, settleStatus);
    } else {
      setSelectedRestaurant({ label: "", value: userId });
      getAllStatsCount(userId, date);
      getOrdersBySettleStatus(userId, date, page, settleStatus);
      getRaisedSettlementRequests(userId);
    }
  }, [restaurantId, status, fromDate]);

  const onSettleModal = () => {
    setSettleModal(!settleModal);
  };

  const onSettleRequestModal = () => {
    setSettleRequestModal(!settleRequestModal);
  };
  const onSettleRequestOrders = (selectedRaised) => {
    if (selectedRaised) {
      let orders = selectedRaised.order;
      orders = orders.map((_, index) => {
        return { ..._, sNo: index + 1 };
      });
      selectedRaised.order = orders;

      setSelectedRaised(selectedRaised);
      setSettleRequestOrdersModal(!settleRequestOrdersModal);
      console.log(selectedRaised);
    } else {
      setSelectedRaised({ order: [] });
      setSettleRequestOrdersModal(!settleRequestOrdersModal);
    }
  };

  const onAllSelected = () => {
    let selected = [];
    if (selectedOrders.length < orders.length) {
      orders.map((_) => {
        selected.push(_._id);
      });
    } else {
      selected = [];
    }
    setSelectedOrders(selected);
  };

  const onOrderSelect = (id) => {
    let newOrders = selectedOrders ? selectedOrders : [];
    let selected = [];
    if (newOrders.indexOf(id) > -1) {
      newOrders.splice(newOrders.indexOf(id), 1);
    } else {
      newOrders.push(id);
    }
    selected = [...newOrders];
    setSelectedOrders(selected);
  };

  const handleFileChange = (e, name) => {
    let finalValue = e;
    finalValue["field"] = name;
    setReceipt(finalValue);
  };

  const onRestaurantChange = (selected) => {
    setSelectedRestaurant(selected);
    getAllStatsCount(selected._id, dateRange);
    if (source === "admin") {
      navigate(
        `/finance/${selected._id}/${selectedMainStatus.value}/${moment(
          dateRange.startDate
        ).format("YYYY-MM-DD")}/${moment(dateRange.endDate).format(
          "YYYY-MM-DD"
        )}`
      );
    } else {
      navigate(
        `/finance/${selectedMainStatus.value}/${moment(
          dateRange.startDate
        ).format("YYYY-MM-DD")}/${moment(dateRange.endDate).format(
          "YYYY-MM-DD"
        )}`
      );
    }
  };

  const onStatusChange = (selectedMainStatus) => {
    setSelectedMainStatus(selectedMainStatus);

    let restId = selectedRestaurant?._id || "All";
    if (source === "admin") {
      navigate(
        `/finance/${restId}/${selectedMainStatus.value}/${moment(
          dateRange.startDate
        ).format("YYYY-MM-DD")}/${moment(dateRange.endDate).format(
          "YYYY-MM-DD"
        )}`
      );
    } else
      navigate(
        `/finance/${selectedMainStatus.value}/${moment(
          dateRange.startDate
        ).format("YYYY-MM-DD")}/${moment(dateRange.endDate).format(
          "YYYY-MM-DD"
        )}`
      );
  };
  const onRestaurantsOptions = useCallback(
    debounce((inputText, callback) => {
      getRestaurants("refresh", inputText, callback, "");
    }, 1000),
    []
  );
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue({ inputValue });
    return inputValue;
  };

  const onDateChange = (ranges) => {
    let date = {
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    };
    setDateRange(date);
    getAllStatsCount(selectedRestaurant._id, date);

    let restId = selectedRestaurant?._id || "All";
    if (source === "admin") {
      navigate(
        `/finance/${restId}/${selectedMainStatus.value}/${moment(
          date.startDate
        ).format("YYYY-MM-DD")}/${moment(date.endDate).format("YYYY-MM-DD")}`
      );
    } else
      navigate(
        `/finance/${selectedMainStatus.value}/${moment(date.startDate).format(
          "YYYY-MM-DD"
        )}/${moment(date.endDate).format("YYYY-MM-DD")}`
      );
  };

  const handleDeleteModal = (selectedFinance, statusType) => {
    if (selectedFinance) {
      setSelectedFinance(selectedFinance);
    }
    setStatusUpdate(statusType);
    setUpdateModal(!updateModal);
  };

  const statusMainOptions = [
    { label: "All", value: "All" },
    { label: "Settled", value: "SETTLED" },
    { label: "Raised", value: "RAISED" },
    { label: "Not Settled", value: "UNSETTLED" },
  ];

  const sortFormat = (column, colIndex, { sortElement, filterElement }) => {
    return (
      <div>
        {filterElement}
        {column.text}
        {sortElement}
      </div>
    );
  };

  const statusFormatter = (cell, row) => {
    if (row.status) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              color: "rgb(16, 0, 120)",
              width: " 88px",
              height: "25px",
              background: row.status === "SETTLED" ? "#CEFFBD" : "#ffcebd",
              borderRadius: "15px",
              textAlign: "center",
              marginBottom: 0,
              marginTop: 5,
            }}
          >
            {row.status}
          </p>
          <ButtonWithIcon
            title="Edit"
            icon={Edit}
            iconEnd={true}
            style={{
              borderColor: "#4973E0",
              background: "#4973E0",
              borderRadius: "5px",
              marginLeft: 20,
            }}
            className="action-btn"
            onClick={() => handleDeleteModal(row, true)}
          />
        </div>
      );
    }
  };

  const RemotePagination = ({ data, page, sizePerPage, totalSize }) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          ...options,
          page,
          sizePerPage,
          totalSize,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <div className="table-container-main">
              <BootstrapTable
                remote
                data={data}
                columns={columns}
                keyField="customers"
                bordered={false}
                tabIndexCell
                onTableChange={handleTableChange}
                orders={orders}
                {...paginationTableProps}
              />
            </div>
            <div className="table-pagination-main">
              <PaginationTotalStandalone {...paginationProps} />
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );
  const OrdersTable = ({ data }) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          ...options,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <div className="table-container-main">
              <BootstrapTable
                remote
                data={data}
                columns={columns}
                keyField="customers"
                bordered={false}
                tabIndexCell
                orders={selectedRaised.order}
                {...paginationTableProps}
              />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );

  const RaisedTable = ({ data, page, sizePerPage, totalSize }) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          ...options,
          page,
          sizePerPage,
          totalSize,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <div className="table-container-main">
              <BootstrapTable
                remote
                data={data}
                columns={raiseColumns}
                keyField="customers"
                bordered={false}
                tabIndexCell
                orders={raisedOrders}
                {...paginationTableProps}
              />
            </div>
            <div className="table-pagination-main">
              <PaginationTotalStandalone {...paginationProps} />
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );

  const handleTableChange = (type, { page }) => {
    getOrdersBySettleStatus(
      selectedRestaurant,
      dateRange,
      page,
      selectedMainStatus
    );
    setPage(page);
  };

  const createdDate = (cell, row) => {
    if (row.price) {
      return (
        <span
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: " 14px",
            lineHeight: "21px",
            textAlign: "center",
            color: "#000000",
            opacity: 0.8,
          }}
        >
          {formatters.poundCurrency(row.price)}
        </span>
      );
    }
  };
  const orderId = (cell, row) => {
    if (row.sequence_no) {
      return (
        <span
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: " 14px",
            lineHeight: "21px",
            textAlign: "center",
            color: "var(--theme-primary-color)",
            opacity: 0.8,
            textTransform: "uppercase",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/order-invoice-details/${row._id}`)}
        >
          #{row.sequence_no}
        </span>
      );
    }
  };
  const allCheck = (cell, row) => {
    return (
      <div>
        <input
          type="checkbox"
          onChange={onAllSelected}
          checked={selectedOrders.length === orders.length}
          className="order-check"
        />
      </div>
    );
  };
  const singleCheck = (cell, row) => {
    return (
      <div>
        <input
          type="checkbox"
          onChange={() => onOrderSelect(row._id)}
          checked={selectedOrders.indexOf(row._id) > -1 ? true : false}
          className="order-check"
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "sNo",
      text: "Sr. No. ",
      headerClasses: "id-col",
    },
    ...(selectedMainStatus?.value === "UNSETTLED"
      ? [
          {
            dataField: "id",
            text: "Check All",
            headerFormatter: allCheck,
            formatter: singleCheck,
            headerClasses: "check-col",
          },
        ]
      : []),
    {
      dataField: "sequence_no",
      text: "ORDER ID",
      formatter: orderId,
      headerClasses: "customer-col",
    },
    {
      dataField: "price",
      text: "Amount",
      formatter: createdDate,
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   formatter: statusFormatter,
    //   headerFormatter: sortFormat,
    //   headerClasses: "status-col",
    // },
  ];

  const restaurantFormatter = (cell, row) => {
    if (row.restaurant) {
      let restaurant = row.restaurant;
      return <span>{restaurant?.store_name}</span>;
    }
  };
  const raiseIdFormatter = (cell, row) => {
    if (row.sequence_no) {
      return (
        <span style={{ textTransform: "uppercase" }}>#{row?.sequence_no}</span>
      );
    }
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="settle-btn">
        <PrimaryButton
          title="View/Settle"
          onClick={() => onSettleRequestOrders(row)}
          style={{
            borderColor: "var(--theme-primary-color)",
            background: "var(--theme-primary-color)",
          }}
        />
      </div>
    );
  };

  const raiseColumns = [
    {
      dataField: "sNo",
      text: "Sr. No. ",
      headerClasses: "id-col",
    },

    {
      dataField: "sequence_no",
      text: "Raise ID",
      formatter: raiseIdFormatter,
      headerClasses: "customer-col",
    },
    {
      dataField: "restaurant",
      text: "Restaurant",
      formatter: restaurantFormatter,
    },
    {
      dataField: "settled_status",
      text: "Actions",
      formatter: actionFormatter,
      headerClasses: "status-col",
    },
  ];

  const pageButtonRenderer = ({ page, active, disabled, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "var(--theme-primary-color)";
      activeStyle.color = "#fff";
      activeStyle.borderColor = "var(--theme-primary-color)";
      activeStyle.padding = "15px 23px";
      activeStyle.borderRadius = "30px";
    } else {
      activeStyle.backgroundColor = "#fff";
      activeStyle.color = "#000";
      activeStyle.padding = "15px 23px";
    }
    let buttonStyle = (
      <div
        onClick={handleClick}
        style={{
          cursor: "pointer",
          backgroundColor: "#FFD2B9",
          borderRadius: "20px",
          padding: "10px 18px",
          color: "var(--theme-primary-color)",
          margin: "-10px 10px 0",
          opacity: disabled ? 0.4 : 1,
        }}
      >
        {page === "Previous" && (
          <img src={DoubleRight} style={{ marginRight: "5px" }} alt="right" />
        )}
        <span>{page}</span>
        {page === "Next" && (
          <img src={DoubleLeft} style={{ marginLeft: "5px" }} alt="left" />
        )}
      </div>
    );

    return (
      <li className="page-item" disable>
        {typeof page === "string" ? (
          buttonStyle
        ) : (
          <span onClick={handleClick} style={activeStyle}>
            {page}
          </span>
        )}
      </li>
    );
  };

  const options = {
    pageButtonRenderer,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    prePageText: "Previous",
    nextPageText: "Next",
    showTotal: true,
    custom: true,
    // totalSize: customerCount,
    page: page,
    sizePerPage: 10,
  };

  const getRestaurants = (reload, term, callback, restId) => {
    reload === "reload" && setPageLoading(true);
    let restaurants = [];
    const details = {
      status: "APPROVED",
      resource: "restaurant",
      search_string: term,
    };
    axios
      .all([CommonServices.getRestaurantsBySearch(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            restaurants = resData.data ? resData.data : {};
            restaurants = restaurants.data ? restaurants.data : [];
            restaurants = restaurants.map((eachRes, index) => {
              if (eachRes._id === restId)
                setSelectedRestaurant({
                  ...eachRes,
                  label: eachRes.store_name,
                  value: eachRes._id,
                });
              return {
                ...eachRes,
                label: eachRes.store_name,
                value: eachRes._id,
              };
            });
            if (callback) callback(restaurants);
            if (restaurants.length > 0 && reload === "reload") {
              setDefaultOptions(restaurants);
            }
            setPageLoading(false);
            setRestaurants(restaurants);
          } else {
            setPageLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Restaurants");
      });
  };
  const getOrdersBySettleStatus = (restId, date, pageNo, status) => {
    setPageLoading(true);
    const details = {
      resource: "stats",
      restaurant: restId === "All" ? "" : restId,
      page: pageNo,
      limit: 10,
      from_date: date?.startDate,
      to_date: date?.endDate,
      settled_status: status?.value === "All" ? "" : status.value,
    };
    axios
      .all([CommonServices.getOrdersBySettleStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let orders = resData.data ? resData.data : {};
            let total = orders?.total || 0;
            orders = orders.data ? orders.data : [];
            orders = orders.map((_, index) => {
              return {
                ..._,
                sNo: (pageNo - 1) * 10 + index + 1,
              };
            });
            setPageLoading(false);
            setTotalOrders(total);
            setOrders(orders);
          } else {
            setPageLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Restaurants");
      });
  };

  const getAllStatsCount = (restId, date) => {
    let from_date = new Date();
    from_date.setDate(1);
    from_date.setMonth(from_date.getMonth() - 6);
    const details = {
      from_date: date?.startDate,
      to_date: date?.endDate,
      resource: "stats",
      restaurant: restId,
    };
    axios
      .all([CommonServices.getUnsettledStats(details)])
      .then(function (res) {
        if (res[0]) {
          let SalesResData = res[0];
          let stats = { UNSETTLED: 0, SETTLED: 0, total: 0 };
          if (SalesResData.success) {
            let unsettledStats = SalesResData.data ? SalesResData.data : {};
            unsettledStats = unsettledStats.data ? unsettledStats.data : [];
            if (unsettledStats.length > 0) {
              unsettledStats.map((eachSale) => {
                stats[eachSale._id] = eachSale.totalSale;
              });
            }
            stats.total = stats.SETTLED + stats.UNSETTLED;
            setCounts({ ...stats });
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in fetching finance order stats");
      });
  };
  const settleOrders = (values, files) => {
    let from_date = new Date();
    from_date.setDate(1);
    from_date.setMonth(from_date.getMonth() - 6);
    const details = {
      order_id: JSON.stringify(selectedOrders),
      settled_date: moment(values.settled_date).format("YYYY-MM-DD"),
      resource: "stats",
      settled_receipt: files.receipt,
    };
    axios
      .all([CommonServices.settleOrders(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];

          if (resData.success) {
            setSettleLoading(false);
            onSettleModal();
            getOrdersBySettleStatus(
              selectedRestaurant._id,
              dateRange,
              1,
              selectedMainStatus
            );
            setPage(1);
            setSelectedOrders([]);
          } else {
            setSettleLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in Order settle Status");
      });
  };
  const settleRaisedOrders = (values, files) => {
    let from_date = new Date();
    from_date.setDate(1);
    from_date.setMonth(from_date.getMonth() - 6);
    const details = {
      settledOrder_id: selectedRaised._id,
      settled_date: moment(values.settled_date).format("YYYY-MM-DD"),
      resource: "stats",
      settled_receipt: files.receipt,
    };
    axios
      .all([CommonServices.settleRaisedOrders(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            setSettleLoading(false);
            onSettleRequestOrders("");
            if (source === "admin")
              getRaisedSettlementRequests(selectedRestaurant.value);
            if (source === "vendor")
              getOrdersBySettleStatus(
                selectedRestaurant._id,
                dateRange,
                1,
                selectedMainStatus
              );
          } else {
            setSettleLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in Order settle Status");
      });
  };

  const addSettlementRequest = () => {
    const details = {
      order: JSON.stringify(selectedOrders),
      resource: "settled_order",
    };
    axios
      .all([VendorServices.addSettlementRequest(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            setSettleLoading(false);
            onSettleRequestModal();
            getOrdersBySettleStatus(
              selectedRestaurant._id,
              dateRange,
              1,
              selectedMainStatus
            );
            setPage(1);
            setSelectedOrders([]);
          } else {
            setSettleLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in Order Settlement Request");
      });
  };
  const getRaisedSettlementRequests = (restId) => {
    const details = {
      ...(restId ? { restaurant: restId } : {}),
      resource: "settled_order",
      settled_status: "RAISED",
    };
    axios
      .all([CommonServices.getRaisedSettlementRequests(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let requests = resData?.data || {};
            requests = requests?.data || [];
            requests = requests.map((_, index) => {
              return {
                ..._,
                sNo: index + 1,
              };
            });
            setRaisedOrders(requests);
            setSettleLoading(false);
          } else {
            setSettleLoading(false);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res) console.log("Error occured in Order Settlement Request");
      });
  };

  const uploadFile = (values, type) => {
    setSettleLoading(true);
    let images = [];
    !isEmpty(receipt) && images.push(receipt);
    axios
      .all([CommonServices.fileUpload(images)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.status) {
            let fileData = resData.data;
            let files = {};
            fileData.map((eachFile) => {
              files[eachFile.fileLabel] = eachFile.filename;
              return true;
            });
            if (type === "settle_request") {
              settleRaisedOrders(values, files);
            } else {
              settleOrders(values, files);
            }
          } else {
          }
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in file upload");
      });
  };

  return (
    <div className="finance-main">
      <div className="select-main">
        {source === "admin" && (
          <AsyncSelect
            className="orders-resto-react-select"
            placeholder="Select Restaurant"
            onChange={onRestaurantChange}
            value={selectedRestaurant}
            loadOptions={onRestaurantsOptions}
            defaultOptions={defaultOptions}
            onInputChange={handleInputChange}
            cacheOptions
          />
        )}

        <DateRangePicker
          ranges={[dateRange]}
          className="date-ranger"
          onChange={onDateChange}
          showSelectionPreview={true}
        />
      </div>
      <div className="dashboard-stats-cards">
        <StatsCard
          className="each-card"
          count={counts?.total}
          icon={sales}
          type="Total Amount"
          statText="Total Amount of Orders Placed."
        />
        {/* <StatsCard
          className="each-card"
          count={counts.raised}
          icon={settelment}
          type="Raised Amount"
          statText="Total Amount of Raised Orders."
        /> */}
        <StatsCard
          className="each-card"
          count={counts.SETTLED}
          icon={settelment}
          type="Settled Amount"
          statText="Total Amount of Settled Orders."
        />
        <StatsCard
          className="each-card"
          count={counts.UNSETTLED}
          icon={pendingSettelment}
          type="Pending Amount"
          statText="Total Amount of Pending Orders."
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="finance-header">
          <p className="finance-header-text">List Of Transactions</p>
        </div>

        <div style={{ width: "30%", display: "flex", gap: "10px" }}>
          {selectedOrders.length > 0 && source === "admin" && (
            <div className="settle-btn">
              <PrimaryButton
                title="Settle"
                onClick={onSettleModal}
                style={{
                  borderColor: "var(--theme-primary-color)",
                  background: "var(--theme-primary-color)",
                }}
              />
            </div>
          )}
          {selectedOrders.length > 0 && source === "vendor" && (
            <div className="settle-btn">
              <PrimaryButton
                title="Raise settlement request"
                onClick={onSettleRequestModal}
                style={{
                  borderColor: "var(--theme-primary-color)",
                  background: "var(--theme-primary-color)",
                }}
              />
            </div>
          )}
          <div
            style={{
              width: selectedOrders.length > 0 ? "71%" : "95%",
              float: "right",
            }}
          >
            <ReactSelect
              className="orders-resto-react-select"
              placeholder="Select Status"
              onChange={onStatusChange}
              value={selectedMainStatus}
              options={statusMainOptions}
            />
          </div>
        </div>
      </div>
      <div>
        {pageLoading ? (
          <PageLoader style={{ marginTop: 200 }} />
        ) : source === "admin" && selectedMainStatus.value === "RAISED" ? (
          <RaisedTable
            data={raisedOrders}
            page={page}
            sizePerPage={10}
            totalSize={totalOrders}
          />
        ) : (
          <RemotePagination
            data={orders}
            page={page}
            sizePerPage={10}
            totalSize={totalOrders}
            onTableChange={handleTableChange}
          />
        )}
      </div>
      <Modal
        show={settleModal}
        onHide={() => onSettleModal("")}
        backdrop="static"
        className="settle-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {statusUpdate === true ? "Update Status" : "Delete Customer"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="contact-from">
            <Formik
              initialValues={{
                receipt: "",
                settled_date: new Date(),
              }}
              onSubmit={(values) => {
                uploadFile(values);
              }}
              validationSchema={validate.settleStatusSchema}
            >
              {({
                handleSubmit,
                setFieldValue,
                handleBlur,
                errors,
                touched,
                values,
              }) => (
                <>
                  <div className="host-details">
                    <p className="details-text">
                      Do You want to Settle all the selected Orders ?
                    </p>
                  </div>

                  <FormGroup className="form-group-margin">
                    <FormLabel className="password-label">
                      <span>Receipt*</span>{" "}
                    </FormLabel>
                    <UploadInput
                      className="settle-input"
                      name="receipt"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("receipt", e);
                        handleFileChange(e, "receipt");
                      }}
                      imagePreview={false}
                    />
                    {validate.displayError(touched, errors, "receipt")}
                  </FormGroup>
                  <FormGroup
                    className="login-input-group"
                    style={{ marginTop: "20px" }}
                  >
                    <FormLabel>Settled Date*</FormLabel>
                    <DatePicker
                      selected={values.settled_date}
                      onChange={(date) => setFieldValue("settled_date", date)}
                      className="order-date-picker"
                      dateFormat="MMMM d, yyyy"
                    />
                    {validate.displayError(touched, errors, "settled_date")}
                  </FormGroup>

                  <div className="bottom-btns">
                    <PrimaryButton
                      title={"Settle"}
                      style={{
                        borderColor: "var(--theme-primary-color)",
                        background: "var(--theme-primary-color)",
                        borderRadius: "5px",

                        margin: "20px 5px",
                      }}
                      loading={settleLoading}
                      onClick={handleSubmit}
                    />
                    <PrimaryButton
                      title="Cancel"
                      style={{
                        borderColor: "#e5e5e5",
                        background: "#e5e5e5",
                        borderRadius: "5px",
                        color: "#000",
                        margin: "20px 5px",
                      }}
                      onClick={() => onSettleModal("")}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={settleRequestOrdersModal}
        onHide={() => onSettleRequestOrders("")}
        backdrop="static"
        className="settle-request-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Settle Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="host-details">
            <p className="details-text">
              Do You want to Settle all the selected Orders ?
            </p>
          </div>
          <OrdersTable data={selectedRaised.order} />
          <div className="contact-from">
            <Formik
              initialValues={{
                receipt: "",
                settled_date: new Date(),
              }}
              onSubmit={(values) => {
                uploadFile(values, "settle_request");
              }}
              validationSchema={validate.settleStatusSchema}
            >
              {({
                handleSubmit,
                setFieldValue,
                handleBlur,
                errors,
                touched,
                values,
              }) => (
                <>
                  <FormGroup className="form-group-margin">
                    <FormLabel className="password-label">
                      <span>Receipt*</span>{" "}
                    </FormLabel>
                    <UploadInput
                      className="settle-input"
                      name="receipt"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("receipt", e);
                        handleFileChange(e, "receipt");
                      }}
                      imagePreview={false}
                    />
                    {validate.displayError(touched, errors, "receipt")}
                  </FormGroup>
                  <FormGroup
                    className="login-input-group"
                    style={{ marginTop: "20px" }}
                  >
                    <FormLabel>Settled Date*</FormLabel>
                    <DatePicker
                      selected={values.settled_date}
                      onChange={(date) => setFieldValue("settled_date", date)}
                      className="order-date-picker"
                      dateFormat="MMMM d, yyyy"
                    />
                    {validate.displayError(touched, errors, "settled_date")}
                  </FormGroup>

                  <div className="bottom-btns">
                    <PrimaryButton
                      title={"Settle"}
                      style={{
                        borderColor: "var(--theme-primary-color)",
                        background: "var(--theme-primary-color)",
                        borderRadius: "5px",

                        margin: "20px 5px",
                      }}
                      loading={settleLoading}
                      onClick={handleSubmit}
                    />
                    <PrimaryButton
                      title="Cancel"
                      style={{
                        borderColor: "#e5e5e5",
                        background: "#e5e5e5",
                        borderRadius: "5px",
                        color: "#000",
                        margin: "20px 5px",
                      }}
                      onClick={() => onSettleRequestOrders("")}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={settleRequestModal}
        onHide={() => onSettleRequestModal("")}
        backdrop="static"
        className="settle-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Raise Settlement Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contact-from">
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                addSettlementRequest();
              }}
            >
              {({ handleSubmit }) => (
                <>
                  <div className="host-details">
                    <p className="details-text">
                      Do You want to Raise Request for following selected Orders
                      ?
                    </p>
                  </div>

                  <div className="bottom-btns">
                    <PrimaryButton
                      title={"Settlement Req"}
                      style={{
                        borderColor: "var(--theme-primary-color)",
                        background: "var(--theme-primary-color)",
                        borderRadius: "5px",

                        margin: "20px 5px",
                      }}
                      loading={settleLoading}
                      onClick={handleSubmit}
                    />
                    <PrimaryButton
                      title="Cancel"
                      style={{
                        borderColor: "#e5e5e5",
                        background: "#e5e5e5",
                        borderRadius: "5px",
                        color: "#000",
                        margin: "20px 5px",
                      }}
                      onClick={() => onSettleRequestModal("")}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
