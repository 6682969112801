import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import * as Storage from "../../services/LocalStorage";
import { AuthenticateAction } from "../../redux/Auth";

import Dashboard from "../admin/dashboard";
import AdminBase from "../../layout/base/adminBase/AdminBase";
import Login from "../admin/login";
import Orders from "../admin/orders/Orders";
import Menu from "../admin/menu";

import DummyComponent from "../DummyComponent";
import SignUp from "../admin/signUp";
import Verification from "../common/verification";
import Profile from "../common/profile";
import ForgetPassword from "../common/forgetPassword/ForgetPassword";
import PartyOrders from "../admin/partyOrders";
import Delivery from "../admin/delivery";
import Banks from "../admin/bank";
import Tags from "../admin/tags";
import OrderInvoice from "../common/orderInvoice";
import Finance from "../admin/finance/Finance";
import DeliveryProfile from "../common/deliveryProfile";


export default function ApplicationsAdminRoutes(props) {
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.config);
  const { pathname } = useLocation();
  const nonHeaderPages = [
    "/",
    "login",
    "dummy",
    "signup",
    "email-verify",
    "forget-password",
  ];
  const emailId = useSelector((state) => state.auth.emailId);

  useEffect(() => {
    checkUserSession();
    // eslint-disable-next-line
  }, []);

  const checkUserSession = () => {
    let session = Storage.get(config.sessionKey);
    let authData = session ? JSON.parse(session) : {};
    let response = Storage.checkUserSession(authData);
    if (response.status) {
      let authData = response.data;

      dispatch(AuthenticateAction(authData));
    } else {
    }
  };
  return !emailId && nonHeaderPages.indexOf(pathname.split("/")[1]) > -1 ? (
    <Routes>
      <Route path="/login" element={<Login />} />;
      <Route path="/dummy" element={<DummyComponent />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/email-verify/:emailId/:type" element={<Verification />} />
      {/* redirect */}
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/forget-password" element={<ForgetPassword />} />;
    </Routes>
  ) : emailId ? (
    <AdminBase>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />;
        <Route path="/orders" element={<Orders />} />;
        <Route path="/menu" element={<Menu />} />;
        <Route path="/menu/:restaurantId" element={<Menu />} />;
        <Route path="/signup" element={<SignUp />} />;
        <Route path="/profile" element={<Profile />} />;
        <Route path="/delivery-agent/:deliveryId" element={<DeliveryProfile />} />;
        <Route path="/party-orders" element={<PartyOrders />} />;
        <Route path="/delivery" element={<Delivery />} />;
        <Route path="/Tags" element={<Tags />} />;
        <Route path="/banking-details" element={<Banks />} />;
        <Route
          path="/order-invoice-details/:orderId"
          element={<OrderInvoice />}
        />
        <Route path="/finance/:status/:fromDate/:toDate" element={<Finance />} />;
        <Route path="/finance" element={<Finance />} />;
        {/* redirect */}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </AdminBase>
  ) : (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
