import React, { useEffect, useState } from "react";
import "./Footer.scss";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  NavLink as RouteLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import visaCard from "../../assets/img/visa.png";
import amazonCard from "../../assets/img/amazon.png";
import rupayCard from "../../assets/img/rupay.png";
import paypalCard from "../../assets/img/paytm.png";
import Logo from "../../assets/img/svg/mommas.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  LoginModalOpenAction,
  URLRedirectAction,
} from "../../redux/LoginModal";
import CustomerServices from "../../services/CustomerServices";
import AppStore from '../../assets/img/appstore.png'
import Gplay from '../../assets/img/gplay.png'

export default function Footer() {
  const [socials, setSocials] = useState([]);

  const emailId = useSelector((state) => state.auth.emailId);
  const hostUrl = useSelector((state) => state.config.config.hostUrl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    getSocials();
  }, []);

  const onOptionClick = (url) => {
    if (emailId) {
      navigate(url);
    } else {
      dispatch(LoginModalOpenAction());
      dispatch(URLRedirectAction(url));
    }
  };

  const getSocials = () => {
    const details = {};
    axios
      .all([CustomerServices.getSocials(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let socials = resData.data ? resData.data : {};
            socials = socials.data ? socials.data : [];
            setSocials(socials);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Cuisines");
      });
  };
  return (
    <div className="footer-main">
      <div className="footer-top footer-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
              <div className="icon-box">
                {" "}
                <span className="text-light-green">
                  <i className="flaticon-credit-card-1"></i>
                </span>
                <span className="text-custom-white">
                  100% Payment
                  <br />
                  Secured
                </span>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
              <div className="icon-box">
                {" "}
                <span className="text-light-green">
                  <i className="flaticon-wallet-1"></i>
                </span>
                <span className="text-custom-white">
                  Support lots
                  <br /> of Payments
                </span>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
              <div className="icon-box">
                {" "}
                <span className="text-light-green">
                  <i className="flaticon-help"></i>
                </span>
                <span className="text-custom-white">
                  24 hours / 7 days
                  <br />
                  Support
                </span>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-6">
              <div className="icon-box">
                {" "}
                <span className="text-light-green">
                  <i className="flaticon-truck"></i>
                </span>
                <span className="text-custom-white">
                  Free Delivery
                  <br />
                  with $50
                </span>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-6">
              <div className="icon-box">
                {" "}
                <span className="text-light-green">
                  <i className="flaticon-guarantee"></i>
                </span>
                <span className="text-custom-white">
                  Best Price
                  <br />
                  Guaranteed
                </span>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-6">
              <div className="icon-box">
                {" "}
                <span className="text-light-green">
                  <i className="flaticon-app-file-symbol"></i>
                </span>
                <span className="text-custom-white">
                  Mobile Apps
                  <br />
                  Ready
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="section-padding bg-light-theme pt-0 u-line footer-bg">
        {/* <div className="u-line instagram-slider swiper-container">
          <Swiper
            slidesPerView={6}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="hm-list hm-instagram "
          >
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <a href="/#">
                <img
                  src="https://via.placeholder.com/250x200"
                  alt="instagram"
                />
              </a>
            </SwiperSlide>
          </Swiper>
        </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
              <div className="footer-logo" style={{ display: "flex" }}>
                <img src={Logo} alt="Logo" style={{ width: 60, height: 60 }} />
                <h3 className="header-logo">
                  Momma's <span style={{ color: "#ff6602" }}>Kitchen</span>
                </h3>
              </div>
              <p className="footer-logo-text">
                Anywhere, anytime, enjoy your Meal
              </p>
            </div>
            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
              <div className="footer-links">
                <h6 className="text-custom-white">Get to Know Us</h6>
                <ul className="footer-ul-div">
                  <li>
                    <RouteLink to={"/about"} className="text-white fw-600">
                      About Us
                    </RouteLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
              <div className="footer-links">
                <h6 className="text-custom-white">Let Us Help You</h6>
                <ul className="footer-ul-div">
                  <li>
                    <span
                      onClick={() => onOptionClick("/profile")}
                      className={`text-white fw-600 ${
                        pathname === "/profile" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      Account Details
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => onOptionClick("/orders")}
                      className={`text-white fw-600 ${
                        pathname === "/orders" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      Order History
                    </span>
                  </li>
                  <li>
                    <RouteLink
                      to={"/search-results"}
                      className="text-white fw-600"
                    >
                      Find restaurant
                    </RouteLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
              <div className="footer-links">
                <h6 className="text-custom-white">Doing Business</h6>
                <ul className="footer-ul-div">
                  <li>
                    <a
                      href={hostUrl}
                      className="text-white fw-600"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Be a Partner restaurant
                    </a>
                  </li>
                  {!emailId && (
                    <li>
                      <RouteLink
                        to={"/registration"}
                        className="text-white fw-600"
                      >
                        Create an Account
                      </RouteLink>
                    </li>
                  )}
                  <li>
                    <RouteLink to={"/help"} className="text-white fw-600">
                      Help
                    </RouteLink>
                  </li>
                  <li>
                    <RouteLink to={"/terms-and-conditions"} className="text-white fw-600">
                      Terms & Conditions
                    </RouteLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
              <div className="footer-links">
                <h6 className="text-custom-white">Download Apps</h6>
                <div className="appimg">
                  <a href="/#">
                    <img
                      src={AppStore}
                      className="img-fluid"
                      alt="app logo"
                    />
                  </a>
                </div>
                <div className="appimg">
                  <a href="/#">
                    <img
                      src={Gplay}
                      className="img-fluid"
                      alt="app logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl col-lg-4 col-md-4 col-sm-6">
              <div className="footer-contact">
                <h6 className="text-custom-white">Newsletter</h6>
                <form className="subscribe_form">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control form-control-submit"
                      name="email"
                      placeholder="Enter your email"
                    />
                    <span>
                      <button
                        className="btn btn-second btn-submit news-letter-btn"
                        type="button"
                      >
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ft-social-media">
                <h6 className="text-center text-white">Follow us</h6>
                <ul>
                  {socials &&
                    socials.length > 0 &&
                    socials.map((eachSocial) => (
                      <li>
                        {" "}
                        <a href={eachSocial.url} target="_blank">
                          <i className={eachSocial.icon}></i>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright bg-black">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="payment-logo mb-md-20">
                {" "}
                <span className="text-white fs-14 mr-3">We are accept</span>
                <div className="payment-icon">
                  <div className="credit-card-accept">
                    <img src={visaCard} alt="Pic not loaded yet" />
                  </div>
                  <div className="credit-card-accept">
                    <img src={amazonCard} alt="Pic not loaded yet" />
                  </div>
                  <div className="credit-card-accept">
                    <img src={rupayCard} alt="Pic not loaded yet" />
                  </div>
                  <div className="credit-card-accept">
                    <img src={paypalCard} alt="Pic not loaded yet" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center medewithlove align-self-center">
              <a
                href="http://www.Slidesigma.com"
                className="text-custom-white footertext-custom-white"
              >
                Made with <i className="fas fa-heart"></i> DEzen
              </a>
            </div>
            <div className="col-lg-4">
              <div className="copyright-text">
                {" "}
                <span className="text-white">
                  ©{" "}
                  <a href="/#" className="text-white">
                    DEzen
                  </a>{" "}
                  - 2022 | All Right Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
