import React, { useEffect, useRef, useState } from "react";
import "./AdminHeader.scss";
import * as Storage from "../../../services/LocalStorage";
import { useSelector } from "react-redux";
import axios from "axios";
import AdminServices from "../../../services/AdminServices";
import VendorServices from "../../../services/Vendorservices";
import SimpleBar from "simplebar-react";
import NotificationProfile from "../../../assets/img/svg/004-leaf.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const onLogout = () => {
    Storage.clear();
    window.location.reload();
  };
  const userName = useSelector((state) => state.auth.name);
  const profileImage = useSelector((state) => state.auth.profileImage);
  const config = useSelector((state) => state.config.config);
  const [notifications, setNotifications] = useState([]);
  let intervalId = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (config.source === "admin") {
      intervalId.current = setInterval(() => {
        getAllNotifications();
      }, 10000);
    }
    if (config.source === "vendor") {
      intervalId.current = setInterval(() => {
        getAllRestaurantNotifications();
      }, 10000);
    }
    return () => {
      clearInterval(intervalId.current);
    };
  }, [config]);

  const onNotificationEvent = (each) => {
    if (each.type === "restaurant") {
      if (config.source === "admin") navigate(`/host-info/${each.restaurant}`);
      if (config.source === "vendor") navigate("/profile");
    }
    if (each.type === "order") {
      navigate("/orders");
    }
    if (each.type === "party_order") {
      navigate("/party-orders");
    }
    if (each.type === "customer") {
      navigate("/customers");
    }
    if (each.type === "cuisine") {
      navigate("/cuisine");
    }
    if (each.type === "menu") {
      navigate("/menu");
    }
    if (each.type === "delivery") {
      navigate("delivery");
    }
    updateNotifications(each._id);
  };

  const getAllNotifications = (reload) => {
    const details = {
      resource: "notification",
      status: "OPEN",
    };
    axios
      .all([AdminServices.getAllNotifications(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let notificationData;
            notificationData = resData.data ? resData.data : {};
            notificationData = notificationData.data
              ? notificationData.data
              : [];
            let openNotifications = [];
            notificationData.map((each) => {
              if (each.status === "OPEN") openNotifications.push(each);
              return each;
            });
            setNotifications(openNotifications);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("Error occured at fetching admin notifications");
      });
  };

  const updateNotifications = (notificationId) => {
    const details = {
      resource: "notification",
      notification_id: notificationId,
      status: "CLOSE",
    };
    axios
      .all([AdminServices.updateNotifications(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            if (config.source === "admin") getAllNotifications();
            if (config.source === "vendor") getAllRestaurantNotifications();
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("Error occured at fetching admin notifications");
      });
  };

  const getAllRestaurantNotifications = () => {
    const details = {
      resource: "notification",
      status: "OPEN",
    };
    axios
      .all([VendorServices.getAllRestaurantNotifications(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let notificationData;
            notificationData = resData.data ? resData.data : {};
            notificationData = notificationData.data
              ? notificationData.data
              : {};
            let openNotifications = [];
            notificationData.map((each) => {
              if (each.status === "OPEN") openNotifications.push(each);
              return each;
            });
            setNotifications(openNotifications);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("Error occured at fetching restaurant notifications");
      });
  };
  return (
    <div class="admin-header">
      <header class="full-width">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 mainNavCol">
              <div class="main-search mainNavCol">
                <form class="main-search search-form full-width">
                  <div class="row">
                    {/* <div class="col-lg-6 col-md-7">
                      <div class="search-box padding-10">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search for User, Restaurents, Foods"
                        />
                      </div>
                    </div> */}
                    <div class="col-lg-12 col-md-6">
                      <div class="right-side fw-700 mainNavCol">
                        <div class="mobile-search">
                          <a
                            href="/#"
                            data-toggle="modal"
                            data-target="#search-box"
                          >
                            {" "}
                            <i class="fas fa-search"></i>
                          </a>
                        </div>

                        <div class="user-details ">
                          <a href="/#" class="text-light-white fw-500">
                            <img
                              src={
                                profileImage
                                  ? config.fileBasicPath + profileImage
                                  : "https://via.placeholder.com/30"
                              }
                              class="rounded-circle"
                              alt="userimg"
                            />{" "}
                            <span>Hi, {userName}</span>
                          </a>
                        </div>
                        <div class="cart-btn notification-btn">
                          <a href="/#" class="text-light-green fw-700">
                            <i class="fas fa-bell"></i>
                            <span class="user-alert-notification">
                              {notifications.length}
                            </span>
                          </a>
                          <div class="notification-dropdown">
                            <SimpleBar
                              style={{
                                maxHeight: 400,
                              }}
                            >
                              <div className="notification-header-div">
                                <p>Notifications</p>
                              </div>

                              <div className="notification-display-main">
                                {notifications && notifications.length > 0 ? (
                                  notifications.map((eachResNotification) => (
                                    <div
                                      className="notification-display-flex"
                                      onClick={() =>
                                        onNotificationEvent(eachResNotification)
                                      }
                                    >
                                      <div
                                        style={{ width: "20%", marginTop: 5 }}
                                      >
                                        <div class="notification-profile-div">
                                          <img
                                            alt="Notification Profile"
                                            src={NotificationProfile}
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div>
                                          <span class="profile-type-text">
                                            {eachResNotification.type &&
                                              eachResNotification.type}
                                          </span>
                                          <span class="profile-message-text">
                                            {" "}
                                            {eachResNotification.message &&
                                              eachResNotification.message}
                                          </span>
                                        </div>
                                        <div className="updated-at-text">
                                          <span class="clock-watch">
                                            <i class="fa-solid fa-clock"></i>
                                          </span>
                                          <span className="clock-watch-text">
                                            Updated At{" "}
                                            {moment(
                                              eachResNotification.updatedAt &&
                                                eachResNotification.updatedAt
                                            ).format("DD/MM/YYYY HH:MM")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p
                                    style={{
                                      marginTop: 20,
                                      textAlign: "center",
                                    }}
                                  >
                                    No Notifications!
                                  </p>
                                )}
                              </div>
                            </SimpleBar>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
