var store = undefined;

const storeProvider = {
  init(configureStore) {
    store = configureStore({});
  },

  getStore() {
    console.log(store)
    return store;
  },

  getCurrentState() {
    return store.getState();
  },

  getApi() {
    var currentState = this.getCurrentState();
    return currentState.config.config.api;
  },

  getCustomerType() {
    var currentState = this.getCurrentState();
    return currentState.auth.customerType;
  },

  getSessionKey() {
    var currentState = this.getCurrentState();
    return currentState.config.config.sessionKey;
  },

  getUserInfo() {
    var currentState = this.getCurrentState();
    var data = {
      emailId: currentState.auth.emailId,
      phone: currentState.auth.phone,
      token: currentState.auth.token,
      name: currentState.auth.name,
      role: currentState.auth.role,
      userId:currentState.auth.userId
    };

    return data;
  },
};

export default storeProvider;
