import { createAction } from "redux-actions";

const UpdateConfig = "UPDATE_CONFIG";

// actions
export const ConfigAction = createAction(UpdateConfig);

//reducers

export const ConfigActionReducer = (state, action) => {
  return {
    ...state,
    config: action.payload,
  };
};

const initialState = {
  config: {},
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case UpdateConfig:
      return ConfigActionReducer(state, action);

    default:
      return state;
  }
};
export default configReducer;
