import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { GoogleMapsAPI } from "./apiKey";

Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

function AssignDeliveryMap(props) {
  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const [mapPosition, setMapPosition] = useState({
    lat: 20.5937,
    lng: 78.9629,
  });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleMapsAPI,
  });
  useEffect(() => {
    if (props.coordinates) {
      setMapPosition(props.coordinates);
    }
  }, []);

  return isLoaded ? (
    <div className="map-container">
      <div
        constainer
        direction={"column"}
        style={{ display: "flex", height: "100%", width: "100%" }}
        spacing={2}
      >
        <GoogleMap
          mapContainerStyle={{
            height: props.height ? props.height : "450px",
            width: "100%",
          }}
          options={options}
          zoom={props?.zoom || 5}
          center={{
            lat: Number.isFinite(mapPosition.lat) ? mapPosition.lat : 20.5937,
            lng: Number.isFinite(mapPosition.lng) ? mapPosition.lng : 78.9629,
          }}
        >
          {console.log(props)}
          {props.coordinates &&
            props.coordinates.length > 0 &&
            props.coordinates.map((coordinate, index) => (
              <>
                <Marker
                  key={index}
                  draggable={!props.display}
                  position={{
                    lat: coordinate.latitude,
                    lng: coordinate.longitude,
                  }}
                >
                  <InfoWindow
                    position={{
                      lat: coordinate.latitude ,
                      lng: coordinate.longitude,
                    }}
                   
                  >
                    <p>{coordinate.name}</p>
                  </InfoWindow>
                </Marker>
              </>
            ))}
        </GoogleMap>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default AssignDeliveryMap;
