import storeProvider from "../store/StoreProvider";
import * as fetchServices from "./FetchServices";
import apiService from "./ApiServices";

const CustomerServices = {
  customerLogin: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerLogin;

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerSignup: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerSignup;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerEmailVerify: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerEmailVerify;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerResendOtp: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerResendOtp;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerForgetPassword: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerForgetPassword;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerVerifyForgetPassword: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerVerifyForgetPassword;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerGetCuisines: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerGetCuisines;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  customerGetRestaurants: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerGetRestaurants;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  customerCreateOrderPaymentIntent: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerCreateOrderPaymentIntent +
      "?resource=" +
      details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerAddOrder: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.customerAddOrder + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerGetOrder: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerGetOrder;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  customerUpdateOrder: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerUpdateOrder;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerGetOrdersByStatus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerGetOrdersByStatus;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  customerAddAddress: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.customerAddAddress + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  customerGetAddresses: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerGetAddresses;
    let type = "GET";
    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  customerDeleteAddress: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerDeleteAddress +
      "?resource=" +
      details.resource +
      "&status=" +
      details.status +
      "&address_id=" +
      details.address_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerUpdateAddress: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerUpdateAddress +
      "?resource=" +
      details.resource +
      "&address_id=" +
      details.address_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  addCart: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCart + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getCart: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCart;
    let type = "GET";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  deleteCart: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.deleteCart;
    let type = "DELETE";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  decreaseCartCount: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.decreaseCartCount + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  clearCart: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.clearCart;
    let type = "DELETE";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  updateCartAddress: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.updateCartAddress + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  updateCartDetails: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.updateCartDetails + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  addPartyOrder: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addPartyOrder + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  updatePartyOrderStatus: function (details) {
    const api = storeProvider.getApi();
    let type = "PUT";
    let API =
      api +
      apiService.updatePartyOrderStatus +
      "?resource=" +
      details.resource +
      "&partyOrder_id=" +
      details.partyOrder_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  updatePartyOrder: function (details) {
    const api = storeProvider.getApi();
    let type = "PUT";
    let API =
      api +
      apiService.updatePartyOrder +
      "?resource=" +
      details.resource +
      "&partyOrder_id=" +
      details.partyOrder_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getPartyOrder: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getPartyOrder;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getNotifications: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getNotifications;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getFavourites: function (details) {
    const api = storeProvider.getApi();
    let type = "GET";
    let API = api + apiService.getFavourites;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  addFavourite: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addFavourite + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  updateFavouriteStatus: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.updateFavouriteStatus +
      "?resource=" +
      details.resource +
      "&favourite_id=" +
      details.favourite_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerAddRatings: function (details) {
    const api = storeProvider.getApi();
    let API =
      api + apiService.customerAddRatings + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  customerUpdateRatings: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerUpdateRatings +
      "?resource=" +
      details.resource +
      "&rating_id=" +
      details.rating_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerDeliveryAddRating: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerDeliveryAddRating +
      "?resource=" +
      details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerDeliveryUpdateRating: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerDeliveryUpdateRating +
      "?resource=" +
      details.resource +
      "&rating_id=" +
      details.rating_id;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  customerGetProfile: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerGetProfile;
    let type = "GET";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  customerUpdateProfile: function (details) {
    const api = storeProvider.getApi();
    let API =
      api +
      apiService.customerUpdateProfile +
      "?resource=" +
      details.resource +
      "&customer_id=" +
      details.customer_id;
    let type = "PUT";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },
  getRatingsByRestaurant: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getRatingsByRestaurant;
    let type = "GET";
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true, type).then((res) => {
      return res;
    });
  },

  getCategoriesandMenus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCategoriesandMenus;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false, type).then((res) => {
      return res;
    });
  },
  getSocials: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getSocials;
    let type = "GET";

    let payload = {
      ...details,
    };

    return fetchServices.axiosService(API, payload, false, type).then((res) => {
      return res;
    });
  },

  reOrderItems: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.reOrderItems + "?resource=" + details.resource;
    let payload = {
      ...details,
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
};
export default CustomerServices;
