import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import autheReducers from "../redux/Auth";
import cartReducers from "../redux/Cart";
import configReducer from "../redux/Config";
import loginReducers from "../redux/LoginModal";
import searchReducer from "../redux/Search";

const rootReducer = combineReducers({
  auth: autheReducers,
  loginModal: loginReducers,
  config: configReducer,
  searchOptions: searchReducer,
  cartOptions: cartReducers,
});

const store = () =>
  configureStore({
    reducer: rootReducer,
  });

export default store;
